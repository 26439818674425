import React, { lazy, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router'
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'

import AuthGuard from './components/guards/AuthGuard'
// Guards
import GuestGuard from './components/guards/GuestGuard'
import LoadingScreen from './components/LoadingScreen'
import useAuth from './hooks/useAuth'
// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
import AuthLayout from './layouts/Auth'
// Auth components
import SignIn from './pages/auth/SignIn'
import SignOut from './pages/auth/SignOut'

function SignOutNavigate() {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    navigate(`/auth/sign-out?continue=${location.pathname || ''}`, {
      replace: true
    })
  })
  return null
}

export function ApplicationRoutes() {
  // const application = useApplicationDataQuery()
  // if (application.isLoading) {
  //   return <Loader />
  // }
  // if (application.isError) {
  //   return (
  //     <Alert severity="error">{handleResponseError(application.error)}</Alert>
  //   )
  // }

  const appointmentsRoutes = (role: 'admin' | 'doctor') => ({
    path: 'appointments',
    element: <DoctorAppointmentsPage />,
    children: [
      {
        path: ':id',
        children: [
          {
            // path: 'register-visit',
            index: true,
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/doctor/VisitsPage/VisitTab/index'))
              )
            )
          },
          {
            path: 'visits',
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/shared/PatientPage/VisitsTab'))
              )
            )
          },
          {
            path: 'treatment-plans',
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/doctor/VisitsPage/TreatmentTab'))
              )
            ),
            children: [{ index: true }, { path: ':innerTab' }]
          },
          {
            path: 'dental-formula',
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/doctor/VisitsPage/DentalFormula'))
              )
            ),
            children: [{ index: true }, { path: ':innerTab' }]
          },
          {
            path: 'notifications',
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/doctor/VisitsPage/NotificationsTab'))
              )
            )
          }
        ]
      }
    ]
  })

  const patientRoutes = (role: 'admin' | 'reception' | 'doctor') => ({
    path: 'patients',
    children: [
      {
        index: true,
        element: <PatientsListPage />
      },
      {
        path: ':id',
        element: <PatientPage />,
        children: [
          {
            index: true,
            // path: 'info',
            element: React.createElement(
              Loadable(lazy(() => import('./pages/shared/PatientPage/InfoTab')))
            )
          },
          {
            path: 'visits',
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/shared/PatientPage/VisitsTab'))
              )
            )
          },
          {
            path: 'payments',
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/shared/PatientPage/PaymentsTab'))
              )
            )
          },
          {
            path: 'insurance',
            element: React.createElement(
              Loadable(
                lazy(
                  () =>
                    import('./pages/shared/PatientPage/InsurancePoliciesTab')
                )
              )
            )
          },
          {
            path: 'treatment-plans',
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/doctor/VisitsPage/TreatmentTab'))
              )
            ),
            children: [{ index: true }, { path: ':innerTab' }]
          },
          {
            path: 'dental-formula',
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/doctor/VisitsPage/DentalFormula'))
              )
            ),
            children: [{ index: true }, { path: ':innerTab' }]
          },
          {
            path: 'notifications',
            element: React.createElement(
              Loadable(
                lazy(() => import('./pages/doctor/VisitsPage/NotificationsTab'))
              )
            )
            // Component: React.lazy(
            //   () => import('../../doctor/VisitsPage/NotificationsTab')
            // )
          },
          {
            path: '*',
            element: (
              <NavigateWithParams to={({ id }) => `/${role}/patients/${id}`} />
            )
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to={`/${role}/patients`} />
      }
    ]
  })

  return useRoutes([
    {
      path: '/auth',
      element: (
        <GuestGuard>
          <Helmet title="Authorization" />
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        </GuestGuard>
      ),
      children: [
        {
          path: 'sign-in',
          element: <SignIn />
        },
        {
          path: 'sign-out',
          element: <SignOut />
        },
        {
          path: '*',
          element: <SignOutNavigate />
        }
      ]
    },
    {
      path: 'reception',
      element: (
        <AuthGuard role="reception">
          <Helmet title="Reception" />
          <ReceptionLayout>
            <Outlet />
          </ReceptionLayout>
        </AuthGuard>
      ),
      children: [
        {
          path: 'day',
          element: <ReceptionDayPage />
        },
        {
          path: 'insurance/partners',
          element: <InsurancePartnersPage />
        },
        {
          path: 'insurance/reports',
          element: <InsuranceMonthlyReportPage />
        },
        patientRoutes('reception'),
        {
          path: '*',
          element: <Navigate to="/reception/day" replace />
        }
      ]
    },
    {
      path: 'doctor',
      element: (
        <AuthGuard role="doctor">
          <Helmet title="Doctor" />
          <DoctorLayout>
            <Outlet />
          </DoctorLayout>
        </AuthGuard>
      ),
      children: [
        appointmentsRoutes('doctor'),
        patientRoutes('doctor'),
        {
          path: '*',
          element: <Navigate to="/doctor/appointments" replace />
        }
      ]
    },
    {
      path: 'admin',
      element: (
        <AuthGuard role="admin">
          <Helmet title="Admin" />
          <AdminSelectedDepartmentProvider>
            <AdminLayout>
              <Outlet />
            </AdminLayout>
          </AdminSelectedDepartmentProvider>
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard/analytics',
          element: <AnalyticsPage />
        },
        {
          path: 'dashboard',
          element: <AdminDashboard />
        },
        {
          path: 'day',
          element: <ReceptionDayPage />
        },
        {
          path: 'insurance/partners',
          element: <InsurancePartnersPage />
        },
        {
          path: 'insurance/reports',
          element: <InsuranceMonthlyReportPage />
        },
        appointmentsRoutes('admin'),
        patientRoutes('admin'),
        {
          path: 'fiscal',
          children: [
            {
              path: 'payment-methods',
              element: <FiscalSettingsPaymentMethodsPage />
            },
            {
              path: 'subjects',
              element: <FiscalSubjectsListPage />
            },
            {
              path: 'configurations',
              element: <FiscalConfigurationsPage />
            },
            {
              path: 'rules',
              element: <FiscalRulesPage />
            },
            {
              path: 'subjects/:userId',
              element: <FiscalSubjectInfoPage />
            },
            {
              path: 'subjects/:userId/objects/:objectId',
              element: <FiscalObjectInfoPage />
            },
            {
              path: 'subjects/:userId/objects/:objectId/registrar/:fiscalId/:localId',
              element: <FiscalCashierPage />
            },
            {
              path: '*',
              element: <Navigate to="/admin/fiscal/configurations" />
            }
          ]
        },
        {
          path: 'settings',
          children: [
            {
              path: 'prices',
              element: <PricesSettingsPage />
            },
            {
              path: 'application',
              element: <ApplicationSettingsPage />
            },
            {
              path: 'departments',
              element: <DepartmentsSettingsPage />
            },
            {
              path: 'users',
              element: <UsersSettingsPage />
            },
            {
              path: '*',
              element: <Navigate to="/admin/settings/application" />
            }
          ]
        },
        {
          path: '*',
          element: <Navigate to="/admin/day" replace />
        }
      ]
    },
    {
      path: '*',
      element: <SignOutNavigate />
    }
  ])
  // return (
  //   <React.Suspense fallback={<Loader />}>
  //     <Switch>
  //       {/*<Route path={Layouts.Doctor} component={DoctorLayout} />*/}
  //       {/*<Route path={Layouts.Admin} component={AdminLayout} />*/}
  //       <Route path="/auth">
  //         <GuestGuard>
  //           <Helmet title="Authorization" />
  //           <AuthLayout>
  //             <Switch>
  //               <Route path="/auth/sign-in" component={SignIn} />
  //               <Route path="/auth/sign-out" component={SignOut} />
  //               <Navigate to="/auth/sign-out" />
  //             </Switch>
  //           </AuthLayout>
  //         </GuestGuard>
  //       </Route>
  //       <Route path="/reception">
  //         <AuthGuard role="reception">
  //           <Helmet title="Reception" />
  //           <ReceptionLayout>
  //             <Switch>
  //               <Route
  //                 exact
  //                 path="/reception/day"
  //                 component={ReceptionDayPage}
  //               />
  //               <Route
  //                 exact
  //                 path="/reception/insurance/partners"
  //                 component={InsurancePartnersPage}
  //               />
  //               <Route
  //                 exact
  //                 path="/reception/insurance/reports"
  //                 component={InsuranceMonthlyReportPage}
  //               />
  //               <Route
  //                 path="/reception/patients/:id/:tab?/:innerTab?"
  //                 component={PatientPage}
  //               />
  //               <Navigate to="/reception/day" />
  //             </Switch>
  //           </ReceptionLayout>
  //         </AuthGuard>
  //       </Route>
  //       <Route path="/doctor">
  //         <AuthGuard role="doctor">
  //           <Helmet title="Doctor" />
  //           <DoctorLayout>
  //             <Switch>
  //               <Route
  //                 exact
  //                 path="/doctor/appointments/:id?/:tab?/:innerTab?"
  //                 component={DoctorAppointmentsPage}
  //               />
  //               <Route
  //                 path="/doctor/patients/:id?/:tab?/:innerTab?"
  //                 component={PatientPage}
  //               />
  //               <Navigate to="/doctor/appointments" />
  //             </Switch>
  //           </DoctorLayout>
  //         </AuthGuard>
  //       </Route>
  //       <Route path="/admin">
  //         <AuthGuard role="admin">
  //           <Helmet title="Admin" />
  //           <AdminSelectedDepartmentProvider>
  //             <AdminLayout>
  //               <Switch>
  //                 <Route
  //                   exact
  //                   path="/admin/dashboard"
  //                   component={AdminDashboard}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/dashboard/analytics"
  //                   component={AnalyticsPage}
  //                 />
  //                 <Route exact path="/admin/day" component={ReceptionDayPage} />
  //                 <Route
  //                   exact
  //                   path="/admin/insurance/partners"
  //                   component={InsurancePartnersPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/insurance/reports"
  //                   component={InsuranceMonthlyReportPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/appointments/:id?/:tab?/:innerTab?"
  //                   component={DoctorAppointmentsPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/patients/:id/:tab?/:innerTab?"
  //                   component={PatientPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/fiscal/configurations"
  //                   component={FiscalConfigurationsPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/fiscal/rules"
  //                   component={FiscalRulesPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/fiscal/payment-methods"
  //                   component={FiscalSettingsPaymentMethodsPage}
  //                 />
  //                 <Route
  // path="/admin/fiscal/subjects/:userId/objects/:objectId/registrar/:fiscalId/:localId"
  //                   component={FiscalCashierPage}
  //                 />
  //                 <Route
  //                   path="/admin/fiscal/subjects/:userId/objects/:objectId"
  //                   component={FiscalObjectInfoPage}
  //                 />
  //                 <Route
  //                   path="/admin/fiscal/subjects/:userId"
  //                   component={FiscalSubjectInfoPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/fiscal/subjects"
  //                   component={FiscalSubjectsListPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/settings/prices"
  //                   component={PricesSettingsPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/settings/application"
  //                   component={ApplicationSettingsPage}
  //                 />
  //                 <Route
  //                   exact
  //                   path="/admin/settings/departments"
  //                   component={DepartmentsSettingsPage}
  //                 />
  //                 <Route
  //                   path="/admin/settings/users"
  //                   component={UsersSettingsPage}
  //                 />
  //                 <Navigate to="/admin/day" />
  //               </Switch>
  //             </AdminLayout>
  //           </AdminSelectedDepartmentProvider>
  //         </AuthGuard>
  //       </Route>
  //       <Navigate to="/auth/sign-out" />
  //     </Switch>
  //   </React.Suspense>
  // )
}

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth()
  const isDashboard = !pathname.includes('/auth') && isAuthenticated

  return (
    <React.Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </React.Suspense>
  )
}

const AdminLayout = Loadable(lazy(() => import('./layouts/Admin')))
const ReceptionLayout = Loadable(lazy(() => import('./layouts/Reception')))
const DoctorLayout = Loadable(lazy(() => import('./layouts/Doctor')))

// Admin pages
const AdminSelectedDepartmentProvider = Loadable(
  lazy(() => import('./contexts/AdminSelectedDepartment'))
)
const AdminDashboard = Loadable(lazy(() => import('./pages/admin/Dashboard')))
const AnalyticsPage = Loadable(
  lazy(() => import('./pages/admin/Dashboard/old'))
)
const PricesSettingsPage = Loadable(
  lazy(() => import('./pages/admin/Settings/PricesSettings'))
)
const ApplicationSettingsPage = Loadable(
  lazy(() => import('./pages/admin/Settings/ApplicationSettings'))
)
const DepartmentsSettingsPage = Loadable(
  lazy(() => import('./pages/admin/Settings/DepartmentsSettings'))
)
const UsersSettingsPage = Loadable(
  lazy(() => import('./pages/admin/Settings/UsersSettings'))
)
const FiscalConfigurationsPage = Loadable(
  lazy(() => import('./pages/shared/Fiscal/ConfigurationsPage'))
)
const FiscalRulesPage = Loadable(
  lazy(() => import('./pages/shared/Fiscal/RulesPage'))
)
const FiscalSettingsPaymentMethodsPage = Loadable(
  lazy(() => import('./pages/shared/Fiscal/PaymentMethods'))
)
const FiscalSubjectsListPage = Loadable(
  lazy(() => import('./pages/shared/Fiscal/SubjectsListPage'))
)
const FiscalSubjectInfoPage = Loadable(
  lazy(() => import('./pages/shared/Fiscal/SubjectInfoPage'))
)
const FiscalObjectInfoPage = Loadable(
  lazy(() => import('./pages/shared/Fiscal/ObjectInfoPage'))
)
const FiscalCashierPage = Loadable(
  lazy(() => import('./pages/shared/Fiscal/FiscalCashierPage'))
)

// Reception pages
const ReceptionDayPage = Loadable(
  lazy(() => import('./pages/shared/DayViewPage'))
)

// Doctors pages
const DoctorAppointmentsPage = Loadable(
  lazy(() => import('./pages/doctor/VisitsPage'))
)

// Shared pages
const PatientPage = Loadable(lazy(() => import('./pages/shared/PatientPage')))
const PatientsListPage = Loadable(
  lazy(() => import('./pages/shared/PatientsList'))
)
const InsurancePartnersPage = Loadable(
  lazy(() => import('./pages/shared/Insurance/PartnersListPage'))
)
const InsuranceMonthlyReportPage = Loadable(
  lazy(() => import('./pages/shared/Insurance/Reports/Monthly'))
)

function NavigateWithParams<T extends Record<string, string> = any>(props: {
  to: (params: T) => string
}) {
  const params: any = useParams<T>()
  return <Navigate to={props.to(params)} />
}
