import _ from 'lodash'
import * as React from 'react'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { Navigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import LoadingScreen from '../LoadingScreen'

interface GuestGuardType {
  children: React.ReactNode
}

const defaultPathForRole = {
  admin: '/admin/day',
  reception: '/reception/day',
  doctor: '/doctor/appointments'
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const location = useLocation()
  const query = useMemo(() => new URLSearchParams(location.search), [location])
  const { isAuthenticated, isInitialized, role } = useAuth()

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (isAuthenticated) {
    const continuePath = query.get('continue')
    const targetPath = _.get(defaultPathForRole, role.name) || ''
    return (
      <Navigate
        to={
          continuePath && continuePath.startsWith(targetPath)
            ? continuePath
            : targetPath
        }
      />
    )
  }

  return <>{children}</>
}

export default GuestGuard
