import '../../../vendor/jvectormap.css'

import { Theme } from '@mui/material'
import { VectorMap } from '@react-jvectormap/core'
import europeMill from '@react-jvectormap/europe/dist/europeMill.json'
import React, { useEffect, useMemo, useState } from 'react'
import { ThemeProps, withTheme } from 'styled-components/macro'

import { useGeoData } from '../../../hooks/data/use-geo-query'
import europeBasePoints from './europeBasePoints'

function EuropeMap(props: { theme: ThemeProps<Theme> & { palette: any } }) {
  const [isShow, setIsShowMap] = useState(true)

  const {
    isSuccess: isGeoDataLoaded,
    isLoading: isGeoDataLoading,
    data: geoData
  } = useGeoData()

  const markers = useMemo(
    () => [
      ...europeBasePoints,
      ...(isGeoDataLoaded &&
      geoData &&
      geoData.location &&
      geoData.location.latitude &&
      geoData.location.longitude &&
      geoData.city &&
      geoData.city.names &&
      geoData.city.names.en
        ? [
            {
              latLng: [geoData.location.latitude, geoData.location.longitude],
              name: geoData.city?.names?.en || 'In your city!' // TODO: localization
            }
          ]
        : [])
    ],
    [isGeoDataLoaded, geoData]
  )

  const options = useMemo(
    () => ({
      map: europeMill,
      regionStyle: (code: string) => {
        if (['RU', 'BY'].includes(code)) {
          return {
            initial: { display: 'none' }
          }
        }
        return {
          initial: {
            fill:
              props.theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.3)'
                : '#e3eaef'
          }
        }
      },
      backgroundColor: 'transparent',
      containerStyle: {
        width: '100%',
        height: '100%'
      },
      zoomOnScroll: false,
      zoomMin: 1,
      zoomMax: 1,
      zoomStep: 0,

      // backgroundColor: 'transparent',
      // containerStyle: {
      //   width: '100%',
      //   height: '100%'
      // },
      markerStyle: {
        initial: {
          fill: props.theme.palette.primary.light,
          stroke: props.theme.palette.primary.light,
          'fill-opacity': 1,
          style: 'animation: simpleSonarWavesKeyframes 2s ease-in-out infinite;'
        }
      },
      markers: markers
    }),
    [markers, props.theme]
  )

  useEffect(() => {
    setIsShowMap(false)
    setTimeout(() => {
      setIsShowMap(true)
    })
  }, [options])

  return isShow ? <VectorMap {...options} /> : null
}

export default withTheme(EuropeMap)
