import { createContext, ReactNode, useEffect, useReducer } from 'react'

import { ActionMap } from '../types/context'

const SELECT = 'SELECT'

type DateActionTypes = {
  [SELECT]: {
    date: Date
  }
}

interface DateState {
  date: Date
}

interface DateContextType {
  date: Date
  selectDate: (date: Date) => void
}

const initialState: DateState = {
  date: new Date()
}

const DateReducer = (
  state: DateState,
  action: ActionMap<DateActionTypes>[keyof ActionMap<DateActionTypes>]
) => {
  switch (action.type) {
    case SELECT:
      window.localStorage.setItem(
        'lastSelectedDate',
        action.payload.date.getTime().toString()
      )
      return { date: action.payload.date }
    default:
      return state
  }
}

const DateContext = createContext<DateContextType | null>(null)

function DateProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(DateReducer, initialState)

  useEffect(() => {
    const initialize = async () => {
      try {
        const lastSelectedDate = window.localStorage.getItem('lastSelectedDate')
        if (lastSelectedDate) {
          const date = new Date(parseInt(lastSelectedDate))
          if (isNaN(date.getTime())) {
            return
          }
          dispatch({
            type: SELECT,
            payload: { date }
          })
        }
      } catch (err) {
        console.error(err)
      }
    }

    initialize()
  }, [])

  const selectDate = (date: Date) => {
    dispatch({
      type: SELECT,
      payload: { date }
    })
  }

  return (
    <DateContext.Provider
      value={{
        ...state,
        selectDate
      }}
    >
      {children}
    </DateContext.Provider>
  )
}

export { DateContext, DateProvider }
