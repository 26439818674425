import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { Navigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

function SignUp() {
  const location = useLocation()
  const query = useMemo(() => new URLSearchParams(location.search), [location])

  const auth = useAuth()
  auth.signOut()

  return (
    <Navigate
      to={`/auth/sign-in?continue=${query.get('continue') || ''}&from=sign-out`}
    />
  )
}

export default SignUp
