import { useQueryClient } from 'react-query'
import { InvalidateQueryFilters } from 'react-query/types/core/types'

export default function useInvalidateQuery(
  rootKey?: string | InvalidateQueryFilters
) {
  const queryClient = useQueryClient()
  return (key?: string | InvalidateQueryFilters) => {
    const destKey = key || rootKey
    if (!destKey) return

    if (typeof destKey === 'string') {
      return queryClient.invalidateQueries(destKey)
    } else {
      return queryClient.invalidateQueries(destKey)
    }
  }
}
