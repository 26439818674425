import {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  default as axios
} from 'axios'
import merge from 'deepmerge'
import * as qs from 'qs'

import { config } from '../config'
import {
  APIRoutes,
  BaseAPIResponse,
  createRoute,
  createRouteWithPath,
  IModelsValues,
  PathParam
} from './generated/models'
import { CompleteModelType, IBaseModelId } from './generated/models.types'

// type ModelId = number
type JSONType = Record<string, any>
// type QueryRequest<Model extends JSONType> = (
//   config?: AxiosRequestConfig
// ) => Promise<Model>
// export type QueryResponse<Model extends JSONType> = {
//   data: Model
//   status: number
//   ok: boolean
// }

interface RequestConfig extends RequestInit {
  query?: JSONType
}

export async function queryRequest<Model extends JSONType>(
  path: string,
  baseConfig: RequestConfig = {},
  destConfig: RequestConfig = {}
): Promise<Model> {
  const cfg = merge(baseConfig, destConfig)
  const query = cfg.query
    ? qs.stringify(cfg.query, {
        encodeValuesOnly: true // prettify url
      })
    : ''

  const accessToken = localStorage.getItem('accessToken')
  const response = await fetch(
    `${config.backendUrl}${path}?${query}`,
    merge(cfg, {
      headers: {
        ...(accessToken
          ? {
              Authorization: `Bearer ${accessToken}`
            }
          : {})
      }
    })
  )
  // const status = response.status
  const data = await response.json()
  return data // { data, status, ok: status >= 200 && status < 300 }
}

// function requestOf<Model extends JSONType>(
// 	path: string,
// 	baseConfig?: AxiosRequestConfig
// ): QueryRequest<Model> {
// 	return (config?: AxiosRequestConfig) => {
// 		return request(path, merge(baseConfig || {}, config || {}))
// 	}
// }

// interface RouteOfType<M extends Models> {
//   findOne(id: ModelId, cfg?: RequestConfig): Promise<CompleteModelType<M>>
//   find(query: JSONType, cfg?: RequestConfig): Promise<CompleteModelType<M>[]>
//   create(
//     data: Omit<
//       SimpleModelType<M> | CompleteModelType<M>,
//       'id' | 'created_at' | 'updated_at' | 'deleted_at'
//     >,
//     cfg?: RequestConfig
//   ): Promise<CompleteModelType<M>>
//   update(
//     id: ModelId,
//     data: Partial<SimpleModelType<M> | CompleteModelType<M>>,
//     cfg?: RequestConfig
//   ): Promise<CompleteModelType<M>>
//   delete(id: ModelId, cfg?: RequestConfig): Promise<CompleteModelType<M>>
//   custom<T>(path: string, cfg?: RequestConfig): Promise<T>
// }
//
// function createRouteOf<M extends Models>(base: M): RouteOfType<M> {
//   const deps: DepsRealtimeConfig[] = ModelDependencies[base] || []
//   const process = (item: any, type: 'set' | 'invalidate' = 'set') =>
//     Promise.all(
//       deps.map(async (it) => {
//         let dep = item[it.property]
//         if (!Array.isArray(dep)) {
//           dep = [dep]
//         } else {
//           // queryClient.setQueryData([it.model, { [it.parent]: item.id }], dep)
//           queryClient.setQueryData(
//             [it.model, { query: { [it.parent]: item.id } }],
//             dep
//           )
//         }
//         for (const d of dep) {
//           if (!d) continue
//           if (type === 'set') {
//             // console.log(`setQueryData([${it.model}, ${d.id}])`)
//             // queryClient.setQueryData([it.model, d.id], d)
//             queryClient.setQueryData([it.model, d.id], d)
//           } else {
//             // console.log(`invalidateQueries([${it.model}, ${d.id}])`)
//             await queryClient.invalidateQueries([it.model, d.id])
//           }
//         }
//       })
//     )
//   return {
//     async find(
//       query: JSONType,
//       cfg?: RequestConfig
//     ): Promise<CompleteModelType<M>[]> {
//       const results = await queryRequest<CompleteModelType<M>[]>(
//         `/${base}`,
//         { method: 'GET', query },
//         cfg
//       )
//       await Promise.all(
//         results.map(async (item) => {
//           await process(item)
//         })
//       )
//       return results
//     },
//     async findOne(
//       id: ModelId,
//       cfg?: RequestConfig
//     ): Promise<CompleteModelType<M>> {
//       const item = await queryRequest<CompleteModelType<M>>(
//         `/${base}/${id}`,
//         { method: 'GET' },
//         cfg
//       )
//       await process(item)
//       return item
//     },
//     async create(
//       data: CompleteModelType<M>,
//       cfg?: RequestConfig
//     ): Promise<CompleteModelType<M>> {
//       const item = await queryRequest<CompleteModelType<M>>(
//         `/${base}`,
//         {
//           method: 'POST',
//           body: JSON.stringify(data),
//           headers: { 'content-type': 'application/json' }
//         },
//         cfg
//       )
//       await process(item)
//       return item
//     },
//     async update(
//       id: ModelId,
//       data: CompleteModelType<M>,
//       cfg?: RequestConfig
//     ): Promise<CompleteModelType<M>> {
//       const item = await queryRequest<CompleteModelType<M>>(
//         `/${base}/${id}`,
//         {
//           method: 'PUT',
//           body: JSON.stringify(data),
//           headers: { 'content-type': 'application/json' }
//         },
//         cfg
//       )
//       await process(item)
//       return item
//     },
//     async delete(
//       id: ModelId,
//       cfg?: RequestConfig
//     ): Promise<CompleteModelType<M>> {
//       const item = await queryRequest<CompleteModelType<M>>(
//         `/${base}/${id}`,
//         { method: 'DELETE' },
//         cfg
//       )
//       await process(item, 'invalidate')
//       return item
//     },
//     custom<T>(path: string, cfg?: RequestConfig): Promise<T> {
//       return queryRequest<T>(`/${base}${path}`, {}, cfg)
//     }
//   }
// }
//

interface RouteOfType<M extends IModelsValues> {
  find(
    config?: AxiosRequestConfig
  ): Promise<BaseAPIResponse<CompleteModelType<M>[]>>
  findOne(
    params: Record<'id', PathParam>,
    config?: AxiosRequestConfig
  ): Promise<BaseAPIResponse<CompleteModelType<M>>>
  create(
    config?: AxiosRequestConfig
  ): Promise<BaseAPIResponse<CompleteModelType<M>>>
  update(
    params: Record<'id', PathParam>,
    config?: AxiosRequestConfig
  ): Promise<BaseAPIResponse<CompleteModelType<M>>>
  delete(
    params: Record<'id', PathParam>,
    config?: AxiosRequestConfig
  ): Promise<BaseAPIResponse<CompleteModelType<M>>>
}

export function routeOf<M extends IModelsValues>(model: M): RouteOfType<M> {
  // @ts-ignore
  return (APIRoutes[model] as any) || fallbackRouteOf(model)
}

function fallbackRouteOf<M extends IModelsValues>(model: M): RouteOfType<M> {
  return {
    find: createRoute<BaseAPIResponse<CompleteModelType<M>>>([
      'GET',
      `/${model}s`,
      model
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<M>>
    >(['GET', 'action'], (p) => `/${model}s/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<M>>>([
      'POST',
      `/${model}s`,
      model
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<M>>
    >(['PUT', model], (p) => `/${model}s/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<M>>
    >(['DELETE', model], (p) => `/${model}s/${p.id}`)
  }
}

interface IErrorResponse<Details = undefined> {
  error: {
    name: string
    message: string
    details: Details
  }
}

export function isErrorResponse<Details>(
  data: any
): data is IErrorResponse<Details> {
  return data && data.error && typeof data.error === 'object'
}

export function handleResponseError<Details = string>(
  err: any,
  opts?: {
    onRequestError?: (
      err: AxiosError & { response: AxiosResponse<IErrorResponse<Details>> }
    ) => Promise<string | undefined | null | void>
  }
): string {
  try {
    if (err && axios.isAxiosError(err)) {
      const responseData = err.response?.data
      if (
        err.response &&
        responseData &&
        isErrorResponse<{
          policyId: IBaseModelId
          from: number
          to: number
        }>(responseData)
      ) {
        if (opts && opts.onRequestError) {
          const result = opts.onRequestError(err as any)
          if (result && typeof result === 'string') {
            return result
          }
        }
        return responseData.error.message
      } else {
        return err.message
      }
    }
  } catch (err) {
    console.error(err)
  }
  return 'request.errors.unknown'
}
