import _ from 'lodash'

export class StringUtils {
  static capitalizeAll(
    str: string | null | undefined
  ): string | null | undefined {
    if (!str) {
      return str
    }
    let result = str
      .split(' ')
      .map((str) => {
        return str.charAt(0).toUpperCase() + str.slice(1)
      })
      .join(' ')
    while (result.includes('  ')) {
      result = result.replaceAll('  ', ' ')
    }
    return result
  }

  static format(baseString: string, obj?: Record<string, any> | any[]): string {
    let str = `${baseString}`
    if (obj) {
      for (let key in obj) {
        str = str.replace(
          new RegExp('\\{' + key + '\\}', 'gi'),
          _.get(obj, key)
        )
      }
    }

    return str
  }
}
