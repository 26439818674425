import getSymbolFromCurrency from 'currency-symbol-map'
import _ from 'lodash'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react'

import { Models } from '../api/generated/models'
import { StrapiMediaModel } from '../api/generated/models.additional'
import {
  CompleteApplicationModel,
  PriceCurrencyModel
} from '../api/generated/models.types'
import { config } from '../config'
import {
  useApplicationDataQuery,
  useApplicationDataWithMapQuery,
  useInvalidateApplicationDataQuery
} from '../hooks/data/use-application-query'
import useTheme from '../hooks/useTheme'
import useTranslation from '../hooks/useTranslation'
import { formatNumberWithSpaces, UNBREAKABLE_SPACE } from '../utils/numbers'
import socket from '../utils/socket.io'

const overrideCurrencySymbols: Record<string, string | undefined> = {
  UAH: 'грн.'
}

const overrideCurrencies: Record<string, string | undefined> = {
  UAH: `{#}${UNBREAKABLE_SPACE}грн.`,
  // eslint-disable-next-line
  USD: '${#}',
  EUR: '€{#}'
}

export enum ApplicationFeatures {
  Timetable = 'timetable',
  InsurancePolicies = 'insurance-policies',
  DentalFormula = 'dental-formula',
  TreatmentPlans = 'treatment-plans',
  Analytics = 'analytics',
  UAFiscal = 'ua-fiscal'
}

export function formatCurrency(
  value: number,
  currency: string,
  withoutCurrency?: boolean
): string {
  const fmtValue = formatNumberWithSpaces(value, 2)
  if (withoutCurrency) return fmtValue

  const override = overrideCurrencies[currency]
  if (override) {
    return override.replace('{#}', fmtValue)
  }

  const symbol = getSymbolFromCurrency(currency)
  return `${fmtValue} ${symbol || currency}`
}

export class ApplicationHolder {
  private currencyRates: PriceCurrencyModel[] = []

  constructor(public readonly app?: CompleteApplicationModel) {}

  public get isLoaded() {
    return !!this.app
  }

  isAvailable(feature: ApplicationFeatures): boolean {
    return true
  }

  convertPrice(value: number, currency?: string) {
    if (!currency || currency === this.currency) {
      return value
    }

    const rate = this.currencyRates.find(
      (it) => it.from === currency && it.to === this.currency
    )
    return Math.round((rate?.rate || 1) * value * 100) / 100
  }

  price(value: number, withoutCurrency?: boolean) {
    return formatCurrency(value, this.currency, withoutCurrency)
  }

  get currency() {
    return this.app?.defaultCurrency?.toUpperCase() || 'EUR'
  }

  get currencySymbol() {
    return (
      overrideCurrencySymbols[this.currency] ||
      getSymbolFromCurrency(this.currency) ||
      this.currency
    )
  }

  get locale() {
    if (!this.app) return 'en'
    return this.app.defaultLanguage?.toLowerCase() || 'en'
  }

  get subscription() {
    const activeSubscriptions = this.app?.subscriptions?.filter(
      (it) => !it.canceled_at || it.canceled_at > Math.floor(Date.now() / 1000)
    )
    if (!activeSubscriptions || activeSubscriptions.length === 0) {
      return null
    }

    return activeSubscriptions[0]
  }

  get subscriptionBadge() {
    if (!this.app) {
      return ''
    }

    const activeSubscription = this.subscription
    return this.app.id === 1
      ? 'PREMIUM'
      : !activeSubscription
      ? 'PRIVATE'
      : activeSubscription.plan
  }

  get smallLogo(): StrapiMediaModel | undefined {
    return _.get(this.app, 'smallLogo.0')
  }

  get normalLogo(): StrapiMediaModel | undefined {
    return _.get(this.app, 'normalLogo.0')
  }

  get bigLogo(): StrapiMediaModel | undefined {
    return _.get(this.app, 'bigLogo.0')
  }

  get smallLogoUrl(): string {
    return (
      this.smallLogo?.url ||
      `${config.backendUrl}/application/logo/small?redirect=1&key=${config.backendApiKey}`
    )
  }

  get normalLogoUrl(): string {
    return (
      this.normalLogo?.url ||
      `${config.backendUrl}/application/logo/normal?redirect=1&key=${config.backendApiKey}`
    )
  }

  get bigLogoUrl(): string {
    return (
      this.bigLogo?.url ||
      `${config.backendUrl}/application/logo/big?redirect=1&key=${config.backendApiKey}`
    )
  }

  get smallLogoPNG(): string {
    return `${config.backendUrl}/application/logo/small.png?redirect=1&key=${config.backendApiKey}`
  }

  get normalLogoPNG(): string {
    return `${config.backendUrl}/application/logo/normal.png?redirect=1&key=${config.backendApiKey}`
  }

  get bigLogoPNG(): string {
    return 'https://firebasestorage.googleapis.com/v0/b/profident-dnipro-ukraine.appspot.com/o/apps%2F1%2Flogo-big.png?alt=media&token=b9de9e96-c69b-4326-870e-abe28ccb29e9';
    // return `${config.backendUrl}/application/logo/big.png?redirect=1&key=${config.backendApiKey}`
  }

  setCurrencies(currencies: PriceCurrencyModel[]) {
    this.currencyRates = currencies
  }
}

const initialState: ApplicationHolder = new ApplicationHolder()
const ApplicationContext = createContext(initialState)

function ApplicationProvider({ children }: { children: ReactNode }) {
  const { theme, setTheme } = useTheme()
  const { i18n } = useTranslation()
  const isInitialized = useRef(false)

  const applicationData = useApplicationDataQuery()
  const invalidateApplicationData = useInvalidateApplicationDataQuery()

  const application = useMemo(
    () => new ApplicationHolder(applicationData.data),
    [applicationData.data]
  )

  socket.useEvent('entry', (eventName, [{ event, model }]) => {
    if (model === Models.application) {
      invalidateApplicationData()
    }
  })

  useEffect(() => {
    if (!theme && application.app?.defaultThemeName) {
      setTheme(application.app?.defaultThemeName)
    }
  }, [application, setTheme, theme])

  useEffect(() => {
    if (!applicationData.isSuccess || isInitialized.current) {
      return
    }

    const lsKey = 'APPLICATION_LATEST_LANGUAGE'
    const lsValue = window.localStorage.getItem(lsKey)
    const isApplicationLanguageChanged =
      lsValue !== null && lsValue !== applicationData.data.defaultLanguage

    if (isApplicationLanguageChanged || !i18n.isSelectedCustom) {
      window.localStorage.setItem(lsKey, applicationData.data.defaultLanguage)
      i18n.changeLanguage(applicationData.data.defaultLanguage)
      isInitialized.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationData.data, applicationData.isSuccess])

  return (
    <ApplicationContext.Provider value={application}>
      {children}
    </ApplicationContext.Provider>
  )
}

function useContextApplication(): ApplicationHolder {
  const value = useContext(ApplicationContext)
  if (!value)
    throw new Error(
      'ApplicationContext must be placed within ApplicationProvider'
    )
  return value
}

function useApplication(): ApplicationHolder {
  const { theme, setTheme } = useTheme()
  const { i18n } = useTranslation()

  const application = useApplicationDataWithMapQuery(
    'holder',
    async (applicationData) => {
      return new ApplicationHolder(applicationData)
    }
  )
  const invalidateApplicationData = useInvalidateApplicationDataQuery()

  socket.useEvent('entry', (eventName, [{ event, model }]) => {
    if (model === Models.application) {
      invalidateApplicationData()
    }
  })

  useEffect(() => {
    if (!theme && application.data?.app?.defaultThemeName) {
      setTheme(application.data?.app?.defaultThemeName)
    }
  }, [application, setTheme, theme])

  useEffect(() => {
    if (!application.isSuccess) {
      return
    }
    const defaultAppLanguage = application.data.app?.defaultLanguage || 'en'

    const lsKey = 'APPLICATION_LATEST_LANGUAGE'
    const lsValue = window.localStorage.getItem(lsKey)
    const isApplicationLanguageChanged =
      lsValue !== null && lsValue !== defaultAppLanguage

    if (isApplicationLanguageChanged || !i18n.isSelectedCustom) {
      window.localStorage.setItem(lsKey, defaultAppLanguage)
      i18n.changeLanguage(defaultAppLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return application.data || new ApplicationHolder()
}

export { ApplicationProvider, useApplication }
