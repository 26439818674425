import { useQuery, UseQueryOptions } from 'react-query'
import { QueryFunctionContext } from 'react-query/types/core/types'

import { config } from '../../config'

export const KEY = ['v1', 'personal', 'loginData']

export type UseLoginDataQuery = {
  defaultDepartment: number
  displayName: string
  username: string
}[]

function queryFn(context: QueryFunctionContext): Promise<UseLoginDataQuery> {
  return fetch(`${config.backendUrl}/v1/personal/loginData`, {
    headers: { 'X-Key': config.backendApiKey! }
    // signal: context.signal
  }).then((res) => res.json())
}

export const usePersonalLoginDataQuery = (
  queryOptions?: UseQueryOptions<UseLoginDataQuery, Error>
) => useQuery<UseLoginDataQuery, Error>(KEY, queryFn, queryOptions)
