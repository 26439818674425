import './wydr'
import 'react-app-polyfill/stable'
import './services/firebase/FirebaseService'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

import App from './App'
import reportWebVitals from './utils/reportWebVitals'

Sentry.init({
  dsn: 'https://4d0a8e7f1889419192994ddc5009fc03@o1331033.ingest.sentry.io/6594342',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5
})

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
// ReactDOM.render(<App />, container)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
