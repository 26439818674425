import { createContext, ReactNode, useEffect, useMemo, useState } from 'react'

interface I18NextContextState {
  language: string
  changeLanguage: (lng?: string) => void
  isSelectedCustom: boolean
}

const LOCAL_STORAGE_KEY = 'initialLanguage'

const initialState: I18NextContextState = {
  language: window.localStorage.getItem(LOCAL_STORAGE_KEY) || 'en',
  isSelectedCustom: false,
  changeLanguage: () => {}
}
const initialConfiguration = {
  fallbackLng: 'en'
}
const I18NextContext = createContext(initialState)

function I18NextContextProvider({ children }: { children: ReactNode }) {
  const [language, setLanguage] = useState<string | undefined>(
    initialState.language
  )
  const value = useMemo(
    () => ({
      language: language || initialConfiguration.fallbackLng,
      isSelectedCustom: !!window.localStorage.getItem(LOCAL_STORAGE_KEY),
      changeLanguage: setLanguage
    }),
    [language]
  )

  // socket.useEvent('entry', ({}) => {
  //   window.localStorage.removeItem(LOCAL_STORAGE_KEY)
  // })

  useEffect(() => {
    if (language) {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, language)
    } else {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY)
    }
  }, [language])

  return (
    <I18NextContext.Provider value={value}>{children}</I18NextContext.Provider>
  )
}

export { I18NextContext, I18NextContextProvider }
