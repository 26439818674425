import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Autocomplete,
  CircularProgress,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { DepartmentModel } from '../../../api/generated/models.types'
import { handleResponseError } from '../../../api/routes'
import Loader from '../../../components/Loader'
import MultiTabs from '../../../components/mui/MultiTabs'
import {
  UseLoginDataQuery,
  usePersonalLoginDataQuery
} from '../../../hooks/data/use-login-data-query'
import { useLoginDepartmentsDataQuery } from '../../../hooks/data/use-login-departments-query'
import useAuth from '../../../hooks/useAuth'
import useTheme from '../../../hooks/useTheme'

const NewLoginSelectedDepKey = 'newLogin@selectedDep'

export default function LoginPage(props: { defaultSelectedTab?: 0 | 1 }) {
  const [selectedTab, setSelectedTab] = useState(1)
  const { theme, setTheme } = useTheme()
  const isDataEntered = useRef(false)

  useEffect(() => {
    let prevTheme = theme
    setTheme('DEFAULT')
    return () => {
      setTheme(prevTheme)
    }
  }, [setTheme, theme])

  const {
    isSuccess: isLoginDataSuccess,
    isLoading: isPersonalLoginDataLoading,
    data: loginData
  } = usePersonalLoginDataQuery()
  const {
    isSuccess: isDepartmentsDataSuccess,
    isLoading: isDepartmentsDataLoading,
    data: departmentsData
  } = useLoginDepartmentsDataQuery()
  const isSuccess = isLoginDataSuccess && isDepartmentsDataSuccess
  const isLoading = isPersonalLoginDataLoading || isDepartmentsDataLoading

  useEffect(() => {
    if (isSuccess && props.defaultSelectedTab === 0 && !isDataEntered.current) {
      setSelectedTab(0)
    }
  }, [props.defaultSelectedTab, isSuccess])

  // const signIn = useCallback(() => {
  //   SwalUIHelper.showLoader()
  //   signIn(identifier, password)
  //     .then((user) => {
  //       SharedUser.update(user)
  //       ElectronService.updateUser(user.jwt)
  //       SwalUIHelper.hide()
  //     })
  //     .catch(SwalUIHelper.error)
  // }, [identifier, password])

  if (isLoading) {
    return (
      <div style={{ maxHeight: '50vh' }}>
        <Loader />
      </div>
    )
  }

  return (
    <div
      className="flex flex-col w-full border-2-0 --rounded-md"
      // style={{ borderColor: muiTheme.palette.primary.main }}
    >
      <MultiTabs
        fullWidthTabs
        // tabsClassName="border-none"
        value={selectedTab}
        setValue={setSelectedTab}
        items={[
          {
            disabled: isLoading,
            title: 'По имени'
          },
          {
            title: 'По логину'
          }
        ]}
      >
        <LoginFormContent
          isLoading={isLoading}
          isDataEntered={isDataEntered}
          selectedTab={selectedTab}
          departmentsData={departmentsData}
          loginData={loginData}
        />
      </MultiTabs>
    </div>
  )
}

function LoginFormContent({
  selectedTab,
  isDataEntered,
  isLoading,
  loginData,
  departmentsData
}: {
  isLoading: boolean
  selectedTab: number
  isDataEntered: React.MutableRefObject<boolean>
  loginData: UseLoginDataQuery | undefined | null
  departmentsData: DepartmentModel[] | undefined | null
}) {
  const { signIn } = useAuth()
  const [isLoadingSignIn, setLoadingSignIn] = useState(false)
  const [signInError, setSignInError] = useState<any>(null)

  const [selectedDep, setSelectedDep] = useState<DepartmentModel | null>(null)
  const [displayAllPersons, setDisplayAllPersons] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const [identifier, setIdentifier] = useState('')
  const [inputLoginName, setInputLoginName] = useState('')
  const [password, setPassword] = useState('')

  const [userLoginsOpen, setUserLoginsOpen] = useState(false)

  const selectedDepartmentId = useMemo(
    () => (selectedDep || {}).id || 1,
    [selectedDep]
  )

  const loginUsersList = useMemo<any[]>(() => {
    let localLoginData = (loginData || []).filter((it) => !!it)
    localLoginData = displayAllPersons
      ? localLoginData
      : localLoginData.filter(
          (it: any) => it.department_id === selectedDepartmentId
        )

    return [
      ...(localLoginData || []),
      ...(!displayAllPersons ? [{ label: '➕ Показать всех' }] : [])
    ].sort(($1, $2) => {
      if (($1 as any).label) return 1
      if (($2 as any).label) return -1
      const d1 = ($1 as any).department_id
      const d2 = ($2 as any).department_id
      if (d1 === selectedDep?.id) return -1
      if (d2 === selectedDep?.id) return 1
      return d1 - d2
    })
  }, [displayAllPersons, loginData, selectedDep?.id, selectedDepartmentId])

  const selectedUser = useMemo(() => {
    return (loginData || []).filter((it) => it.username === identifier)[0]
  }, [identifier, loginData])

  useEffect(() => {
    if (selectedDep) {
      window.localStorage.setItem(
        NewLoginSelectedDepKey,
        JSON.stringify({
          id: selectedDep.id,
          location: selectedDep.location
        })
      )
    }
  }, [selectedDep])

  useEffect(() => {
    if (selectedDep) {
      return
    }
    let tempSelectedDep: any = window.localStorage.getItem(
      NewLoginSelectedDepKey
    )
    try {
      if (tempSelectedDep) {
        tempSelectedDep = JSON.parse(tempSelectedDep)
      }
    } catch (e) {
      tempSelectedDep = null
      console.warn(e)
    }

    if (!tempSelectedDep && departmentsData) {
      tempSelectedDep = departmentsData[0]
    }

    if (tempSelectedDep) {
      setSelectedDep(tempSelectedDep)
    }
  }, [selectedDep, setSelectedDep, departmentsData])

  useEffect(() => {
    setPassword('')
    setIdentifier('')
    setInputLoginName('')
    setDisplayAllPersons(false)
  }, [selectedTab, selectedDep])

  useEffect(() => {
    setPassword('')
  }, [identifier])

  return (
    <div className="flex flex-col my-2 mx-5">
      <Collapse in={selectedTab === 0 && !isLoading}>
        <React.Fragment>
          <FormControl variant="filled" className="w-full">
            <InputLabel id="select-department-label">
              Выберите клинику
            </InputLabel>
            <Select
              variant="filled"
              className="w-100 mt-2"
              value={selectedDepartmentId}
              inputProps={{
                name: 'department',
                id: 'select-department-label'
              }}
              onChange={(ev) => {
                setSelectedDep(
                  (departmentsData || []).filter(
                    (it) => it.id === ev.target.value
                  )[0]
                )
              }}
            >
              {(departmentsData || []).map((it) => (
                <MenuItem key={it.id} value={it.id}>
                  {it.location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            openOnFocus
            autoHighlight
            autoComplete
            open={userLoginsOpen}
            onOpen={() => setUserLoginsOpen(true)}
            onClose={() => setUserLoginsOpen(false)}
            className="w-full flex-fill my-4"
            options={loginUsersList}
            getOptionLabel={(v) =>
              _.get(v, 'display_name') || _.get(v, 'label')
            }
            inputValue={inputLoginName}
            value={selectedUser}
            onInput={(ev) => {
              // @ts-ignore
              setInputLoginName(ev.target.value)
            }}
            groupBy={(option) =>
              (departmentsData || []).find(
                (it: any) => it.id === _.get(option, 'department_id')
              )?.location || ''
            }
            onChange={(ev, newValue) => {
              isDataEntered.current = true

              if (!newValue || _.has(newValue, 'username')) {
                setIdentifier(_.get(newValue, 'username', ''))
                setInputLoginName(_.get(newValue, 'display_name', ''))
              } else {
                setDisplayAllPersons(true)
                setTimeout(() => setUserLoginsOpen(true), 100)
              }
            }}
            renderInput={({ ...params }) => (
              <TextField
                {...params}
                label="Выберите имя из списка"
                className="w-100 mt-2"
                variant="outlined"
                // inputProps={Object.assign(inputProps || {}, {
                //     name: 'no-no-no',
                //     autocomplete: 'off',
                //     type: 'search',
                // })}
              />
            )}
          />
        </React.Fragment>
      </Collapse>
      <Collapse in={selectedTab === 0 && isLoading}>
        <CircularProgress />
      </Collapse>
      <Collapse in={selectedTab === 1}>
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          value={identifier}
          label="Имя пользователя"
          disabled={selectedTab === 0}
          onChange={(ev) => {
            isDataEntered.current = true
            setIdentifier(ev.target.value)
          }}
          inputProps={{ autoComplete: 'username' }}
        />
      </Collapse>
      <div className="mt-4" />
      <TextField
        autoFocus
        fullWidth
        variant="outlined"
        value={password}
        label="Пароль"
        type={showPassword ? 'text' : 'password'}
        onChange={(ev) => {
          isDataEntered.current = true
          setPassword(ev.target.value)
        }}
        inputProps={{ autoComplete: 'password' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(ev) => ev.preventDefault()}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {signInError && (
        <Alert severity="error" className="mt-2">
          {signInError.message || JSON.stringify(signInError)}
        </Alert>
      )}
      <LoadingButton
        loading={isLoadingSignIn}
        variant="contained"
        className="w-full py-2 mt-4"
        onClick={(ev) => {
          ev.preventDefault()
          setLoadingSignIn(true)
          setSignInError(null)

          signIn(identifier, password)
            .catch(async (err) => {
              setSignInError(handleResponseError(err))
            })
            .finally(() => {
              setLoadingSignIn(false)
            })
        }}
      >
        Войти
      </LoadingButton>
    </div>
  )
}
