import { AxiosRequestConfig } from 'axios'
import * as _ from 'lodash'
import * as qs from 'qs'
import { QueryClient } from 'react-query'

import { queryClient } from '../../AppContainer'
import { IModelsValues } from './models'
import { ModelRelations } from './models.relations'

function getQueryClient(): QueryClient {
  return queryClient
}

export async function onBeforeRequest(
  config: AxiosRequestConfig,
  model?: IModelsValues
): Promise<void> {
  if (config?.params && typeof config?.params === 'object') {
    config.url +=
      '?' +
      qs.stringify(config.params, {
        encodeValuesOnly: true // prettify url
      })
    delete config?.params
  }
}

export async function onAfterRequest(
  response: any,
  config: AxiosRequestConfig,
  model?: IModelsValues
): Promise<void> {
  // if (response.data && model) {
  //   const dependencies = ModelsRelations[model]
  //   if (!dependencies) return
  //
  //   const type =
  //     config.method?.toLowerCase() === 'delete' ? 'update' : 'invalidate'
  //   if (Array.isArray(response.data)) {
  //     await Promise.all(
  //       response.data.map((it: any) =>
  //         processDataRow(it, model, type, dependencies)
  //       )
  //     )
  //   } else {
  //     await processDataRow(response.data, model, type, dependencies)
  //   }
  // }
}

async function processDataRow(
  item: any,
  model: IModelsValues,
  type: 'update' | 'invalidate',
  dependencies: ModelRelations[]
): Promise<void> {
  const queryClient = getQueryClient()
  for (const it of dependencies) {
    if (!it.inversedBy) return

    let dep = _.get(item, it.inversedBy)
    if (!Array.isArray(dep)) {
      dep = [dep]
    }
    if (it.mappedBy) {
      // queryClient.setQueryData(
      //   [it.target, { filters: { [it.mappedBy.inversedBy]: item.id } }],
      //   dep
      // )
      // queryClient.setQueryData(
      //   [
      //     it.target,
      //     { filters: { [it.mappedBy.inversedBy]: { id: item.id } } }
      //   ],
      //   dep
      // )
      await queryClient.refetchQueries({
        exact: false,
        queryKey: [model, { [it.mappedBy.inversedBy]: item.id }]
      })
      await queryClient.refetchQueries({
        exact: false,
        queryKey: [model, { [it.mappedBy.inversedBy]: { id: item.id } }]
      })
    }

    for (const d of dep) {
      if (!d) continue
      await queryClient.refetchQueries({
        exact: false,
        queryKey: [it.target, d.id]
      })
      // if (type === 'update') {
      //   queryClient.setQueryData([it.target, d.id], d)
      // } else if (type === 'invalidate') {
      //   queryClient.invalidateQueries([it.target, d.id])
      // }
    }
  }
}
