const points = [
  // { name: 'Istanbul[a]', latLng: [41.013611, 28.955] },
  { name: 'Berlin', latLng: [52.516667, 13.383333] },
  // { name: 'Madrid', latLng: [40.383333, 3.716667] },
  { name: 'Kyiv', latLng: [50.45, 30.523333] },
  // { name: 'Rome', latLng: [41.9, 12.5] },
  // { name: 'Paris', latLng: [48.8567, 2.3508] },
  { name: 'Bucharest', latLng: [44.4325, 26.103889] },
  // { name: 'Minsk', latLng: [53.9, 27.566667] },
  // { name: 'Vienna', latLng: [48.2, 16.366667] },
  // { name: 'Hamburg', latLng: [53.565278, 10.001389] },
  { name: 'Budapest', latLng: [47.4925, 19.051389] },
  { name: 'Warsaw', latLng: [52.233333, 21.016667] },
  // { name: 'Barcelona', latLng: [41.383333, 2.183333] },
  // { name: 'Munich', latLng: [48.133333, 11.566667] },
  { name: 'Kharkiv', latLng: [50.004444, 36.231389] },
  // { name: 'Milan', latLng: [45.466667, 9.183333] },
  // { name: 'Prague', latLng: [50.083333, 14.416667] },
  { name: 'Sofia', latLng: [42.7, 23.33] },
  // { name: 'Brussels', latLng: [50.85, 4.35] },
  // { name: 'Belgrade', latLng: [44.816667, 20.466667] },
  // { name: 'Birmingham', latLng: [52.483056, 1.893611] },
  // { name: 'Cologne', latLng: [50.936389, 6.952778] },
  { name: 'Odessa', latLng: [46.466667, 30.733333] },
  // { name: 'Naples', latLng: [40.833333, 14.25] },
  { name: 'Dnipro', latLng: [48.45, 34.983333] },
  { name: 'Zaporizhzhia', latLng: [47.8378, 35.1383] },
  { name: 'Kryvyi Rih', latLng: [47.9086, 33.3433] },
  { name: 'Mykolaiv', latLng: [46.9667, 32.0] },
  { name: 'Vinnytsia', latLng: [49.2372, 28.4672] },
  { name: 'Chernihiv', latLng: [51.4939, 31.2947] },
  { name: 'Cherkasy', latLng: [49.4444, 32.0597] },
  { name: 'Lviv', latLng: [49.842957, 24.031111] },
  { name: 'Ternopil', latLng: [49.553516, 25.594767] },
  { name: 'Zhytomyr', latLng: [50.2544, 28.6578] },
  { name: 'Rivne', latLng: [50.6197, 26.2514] },
  { name: 'Odessa', latLng: [46.482952, 30.712481] },
  { name: 'Cherkasy', latLng: [49.444431, 32.059769] },
  { name: 'Barcelona', latLng: [41.385064, 2.173403] },
  { name: 'Porto', latLng: [41.157944, -8.629105] },
  { name: 'Lisbon', latLng: [38.722252, -9.139337] },
  { name: 'Cascais', latLng: [38.696757, -9.420744] },
  {
    name: 'London',
    latLng: [51.507351, -0.127758]
  },
  {
    name: 'Madrid',
    latLng: [40.416775, -3.70379]
  }
]

export default points
