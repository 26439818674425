import {
  Close as CloseIcon,
  Done as SuccessIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon
} from '@mui/icons-material'
import { Skeleton } from '@mui/lab'
import React, { useMemo } from 'react'
import { createRoot } from 'react-dom/client'

type AlertProps = {
  title: string
  subtitle?: string
  timeout?: number

  onRemove?: () => void
  persists?: boolean
  loading?: boolean
} & ({ success: true } | { error: true } | { warning: true } | { info: true })

export const opts = {
  error: {
    container: 'bg-red-200 border-red-300',
    icon: {
      Component: ErrorIcon,
      container: 'bg-red-100 border-red-500',
      text: 'text-red-500'
    },
    title: 'text-red-800',
    subtitle: 'text-red-600'
  },
  success: {
    container: 'bg-green-200 border-green-300',
    icon: {
      Component: SuccessIcon,
      container: 'bg-green-100 border-green-500',
      text: 'text-green-500'
    },
    title: 'text-green-800',
    subtitle: 'text-green-600'
  },
  warning: {
    container: 'bg-yellow-200 border-yellow-300',
    icon: {
      Component: WarningIcon,
      container: 'bg-yellow-100 border-yellow-500',
      text: 'text-yellow-500'
    },
    title: 'text-yellow-800',
    subtitle: 'text-yellow-600'
  },
  info: {
    container: 'bg-blue-200 border-blue-300',
    icon: {
      Component: InfoIcon,
      container: 'bg-blue-100 border-blue-500',
      text: 'text-blue-500'
    },
    title: 'text-blue-800',
    subtitle: 'text-blue-600'
  }
}

export function showAlert(props: AlertProps, timeout?: number) {
  const page = document.getElementById('root')
  if (!page) return

  const alert = document.createElement('div')
  alert.style.transition = 'all 200ms cubic-bezier(0.4, 0, 0.2, 1)'
  alert.style.transform = `translateX(${-window.innerWidth}px)`
  alert.style.position = 'fixed'
  alert.style.bottom = '12px'
  alert.style.right = '0'
  alert.style.zIndex = '500600'
  page.appendChild(alert)

  let isRemoved = false
  const onRemove = () => {
    if (isRemoved) return
    isRemoved = true
    alert.style.transform = `translateX(${alert.clientWidth + 32}px)`
    setTimeout(() => alert.remove(), 250)
  }
  if (timeout) {
    setTimeout(onRemove, timeout)
  }

  // Animation in
  setTimeout(() => {
    alert.style.transform = 'translateX(0px)'
  }, 250)

  if (props.timeout) {
    setTimeout(onRemove, props.timeout)
  }

  const root = createRoot(alert)
  root.render(<Alert {...props} onRemove={onRemove} />)

  return onRemove
}

export function Alert(props: AlertProps) {
  const colors = useMemo(() => {
    const p = props as any
    return (
      (p.success && opts.success) ||
      (p.error && opts.error) ||
      (p.warning && opts.warning) ||
      opts.info
    )
  }, [props])
  return (
    <div className="w-screen sm:w-96 px-4">
      <div
        className={`alert relative flex flex-row items-center p-5 rounded-md border-b-2 ${colors.container}`}
      >
        {props.loading && (
          <div className="absolute top-0 right-0 left-0 bottom-0 z-0">
            <Skeleton
              className={`h-full ${colors.container || ''}`}
              animation="wave"
            />
          </div>
        )}
        <div
          className={`z-50 alert-icon flex items-center border-2 justify-center h-10 w-10 flex-shrink-0 rounded-full ${colors.icon.container}`}
        >
          <span className={colors.icon.text}>
            <colors.icon.Component />
          </span>
        </div>
        <div className="alert-content ml-4 z-50">
          <div className={`alert-title font-semibold text-lg ${colors.title}`}>
            {props.title}
          </div>
          {props.subtitle && (
            <div className={`alert-description text-sm ${colors.subtitle}`}>
              {props.subtitle}
            </div>
          )}
        </div>
        {!props.persists && props.onRemove && (
          <div className={`z-50 ${colors.icon.text || ''}`}>
            <CloseIcon
              fontSize="small"
              className="absolute top-2 right-2 cursor-pointer"
              onClick={props.onRemove}
            />
          </div>
        )}
      </div>
    </div>
  )
}
