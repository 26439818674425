import * as React from 'react'
import { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import LoadingScreen from '../LoadingScreen'

interface AuthGuardType {
  children: React.ReactNode
  role: string
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, role: roleName }: AuthGuardType) {
  const location = useLocation()
  const query = useMemo(() => new URLSearchParams(location.search), [location])
  const { isAuthenticated, isInitialized, role } = useAuth()

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    return <Navigate to={`/auth/sign-in?continue=${location.pathname || ''}`} />
  }

  if (isAuthenticated && role && role.name && role.name !== roleName) {
    const continuePath = query.get('continue')
    const targetPath = `/${role.name}`
    return (
      <Navigate
        to={
          continuePath && continuePath.startsWith(targetPath)
            ? continuePath
            : targetPath
        }
      />
    )
  }

  return <>{children}</>
}

export default AuthGuard
