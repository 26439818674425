import { useContext, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { QueryFunctionContext } from 'react-query/types/core/types'

import { APIRoutes, Models } from '../api/generated/models'
import {
  AnyDepartmentModel,
  CompleteUsersPermissionsUserModel
} from '../api/generated/models.types'
import { useAdminOverrideDepartment } from '../contexts/AdminSelectedDepartment'
import { useApplication } from '../contexts/ApplicationContext'
import { AuthContext } from '../contexts/JWTContext'
import { useFindModels, useFindModelsQuery } from './data/use-find-models-query'
import { useModelQuery } from './data/use-model-query'

// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useAuth = () => {
  const context = useContext(AuthContext)
  const adminOverride = useAdminOverrideDepartment()
  const application = useApplication()

  if (!context)
    throw new Error('AuthContext must be placed within AuthProvider')

  const isAuthenticated =
    Boolean(context.user?.id && context.user?.id > 0) || context.isAuthenticated

  const userQuery = useQuery<CompleteUsersPermissionsUserModel, Error>(
    ['users', context.user?.id || 0],
    async (ctx: QueryFunctionContext) => {
      const { data } = await APIRoutes.v1.personal_me({
        signal: ctx.signal
      })
      return data
    },
    {
      enabled: isAuthenticated
    }
  )
  const user = (userQuery.isSuccess ? userQuery.data : null) || context.user
  const role = useModelQuery(Models.role, user?.role || context.user?.role)

  const allDepartments = useFindModels(Models.department)
  const defaultDepartmentQuery: AnyDepartmentModel = useModelQuery(
    Models.department,
    user?.defaultDepartment || context.user?.defaultDepartment,
    {
      enabled: isAuthenticated
    }
  )
  const defaultDepartment: AnyDepartmentModel =
    adminOverride?.selected?.[0] || defaultDepartmentQuery

  const currenciesQuery = useFindModelsQuery(
    Models.priceCurrency,
    {},
    {
      retry: 1,
      enabled: isAuthenticated
    }
  )

  useEffect(() => {
    if (currenciesQuery.isSuccess) {
      application.setCurrencies(currenciesQuery.data)
    }
  }, [application, currenciesQuery])

  return useMemo(
    () => ({
      ...context,
      user,
      role,
      isAuthenticated,
      defaultDepartment,
      defaultDepartmentId:
        defaultDepartment?.id ||
        (defaultDepartment as any) ||
        user?.defaultDepartment?.id ||
        (user?.defaultDepartment as any) ||
        context.user?.defaultDepartment?.id ||
        (context.user?.defaultDepartment as any) ||
        0,
      managedDepartments: allDepartments.filter((it) =>
        user?.managed?.some((m) => m.id === it.id)
      )
    }),
    [allDepartments, context, defaultDepartment, isAuthenticated, role, user]
  )
}

export default useAuth
