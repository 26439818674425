import { addDays, endOfMonth, format, startOfMonth } from 'date-fns'
import * as dataFnsLocales from 'date-fns/locale'

import { IAnyDate, IAnyTime, isIDate, ITime } from './date.types'

function convertAnyDate(any?: IAnyDate): Date {
  if (any instanceof Date) {
    return any
  } else if (isIDate(any)) {
    return new Date(any.year, any.month - 1, any.day)
  } else if (any && ['number', 'string'].includes(typeof any)) {
    const ruDate = /(\d{1,2})\.(\d{1,2})\.(\d{4})/.exec(any.toString())
    if (ruDate) {
      return new Date(
        parseInt(ruDate[3]),
        parseInt(ruDate[2]) - 1,
        parseInt(ruDate[1])
      )
    }
    return new Date(any)
  }
  // console.debug(`Could not convert ${any} (with type ${typeof any}) to Date`)
  return new Date()
}

function convertAnyTime(time: IAnyTime): ITime {
  let hour = 0,
    minutes = 0
  if (typeof time === 'string') {
    const dt = new Date('2020-01-01T' + time)
    hour = dt.getHours()
    minutes = dt.getMinutes()
  } else if (typeof time === 'number') {
    hour = Math.floor(time / 60)
    minutes = time % 60
  } else if (time && (time as ITime).hour) {
    hour = time.hour
    minutes = time.minutes
  }
  return { hour, minutes }
}

export default class DateFormatter {
  public static defaultLocale = dataFnsLocales.enUS

  public static monthValue(_date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return `${date.getMonth()}${date.getFullYear()}`
  }

  public static timeValueOf(time: ITime): number {
    return time.hour * 60 + time.minutes
  }

  public static leadingZero(num: number): string {
    num = Math.floor(num)
    return num >= 10 ? `${num}` : `0${num}`
  }

  public static convertAnyDate(_date?: IAnyDate): Date {
    return convertAnyDate(_date)
  }

  public static convertAnyTime(_time?: IAnyTime): ITime {
    return convertAnyTime(_time)
  }

  public static date(_date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return this.format('dd MMMM yyyy', date)
  }

  public static shortDate(_date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return this.format('dd.MM.yyyy', date)
  }

  public static time(_date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return this.format('HH:mm', date)
  }

  public static timeValue(time: IAnyTime) {
    const { hour, minutes } = convertAnyTime(time)
    return hour * 60 + minutes
  }

  public static timeToDate(time: IAnyTime) {
    const { hour, minutes } = convertAnyTime(time)
    const date = new Date()
    date.setHours(hour)
    date.setMinutes(minutes)
    return date
  }

  public static dateTime(_date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return this.format('dd MMMM yyyy, в HH:mm', date)
  }

  public static nowISO(): string {
    return this.dateISO()
  }

  public static dateISO(_date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return this.format('yyyy-MM-dd', date)
  }

  public static startOfDayISO(_date?: IAnyDate): string {
    return this.dateISO(_date) + 'T00:00:00.000Z'
  }

  public static endOfDayISO(_date?: IAnyDate): string {
    return this.dateISO(_date) + 'T23:59:59.999Z'
  }

  public static dateRange(_start?: IAnyDate, _end?: IAnyDate) {
    const [start, end] = [convertAnyDate(_start), convertAnyDate(_end)]

    if (start.getMonth() === end.getMonth()) {
      return `${this.format('dd', start)} - ${this.format('dd MMMM', end)}`
    }
    return `${this.format('dd MMMM', start)} - ${this.format('dd MMMM', end)}`
  }

  public static month(_date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return this.format('LLLL', date)
  }

  public static shortMonth(_date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return this.format('LLL', date)
  }

  public static monthYear(_date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return this.format('LLLL yyyy', date)
  }

  public static format(template: string, _date?: IAnyDate): string {
    const date = convertAnyDate(_date)
    return format(date, template, { locale: DateFormatter.defaultLocale })
  }

  public static timeString(time: IAnyTime) {
    const result = this.format('p', this.timeToDate(time))
    const hoursLength = result.split(':')[0].length
    return (hoursLength === 1 ? '0' : '') + result
  }

  public static timeStringAdd(
    time: IAnyTime,
    props?: {
      hour?: number
      minutes?: number
    }
  ) {
    const newTime = convertAnyTime(time)
    newTime.hour += props?.hour || 0
    newTime.minutes += props?.minutes || 0
    return this.timeString(newTime)
  }

  public static addDays(days: number, _date?: IAnyDate) {
    const date = convertAnyDate(_date)
    return addDays(date, days)
  }

  public static addYears(years: number, _date?: IAnyDate) {
    const copy = new Date(convertAnyDate(_date).getTime())
    copy.setFullYear(copy.getFullYear() + years)
    return copy
  }

  public static getWeekRange(_date?: IAnyDate) {
    const from = new Date(convertAnyDate(_date).getTime())
    if (from.getDay() === 0) {
      from.setDate(from.getDate() - 6)
    }

    const to = new Date(from.setDate(from.getDate() - from.getDay() + 1))
    to.setDate(to.getDate() + 6)

    return { from, to }
  }

  public static getWeekStart(_date?: IAnyDate) {
    const date = convertAnyDate(_date)
    return this.getWeekRange(date).from
  }

  public static getMonthRange(_date?: IAnyDate) {
    const date = convertAnyDate(_date)
    return [startOfMonth(date), endOfMonth(date)]
  }

  public static weekDays(date?: IAnyDate) {
    return this.format('eeee', date)
  }

  // public static englishWeekDay(_date?: IAnyDate): string {
  //   const date = convertAnyDate(_date)
  //   return [
  //     'Sunday',
  //     'Monday',
  //     'Tuesday',
  //     'Wednesday',
  //     'Thursday',
  //     'Friday',
  //     'Saturday'
  //   ][date.getDay()]
  // }
  //
  // public static russianWeekDay(_date?: IAnyDate): string {
  //   const date = convertAnyDate(_date)
  //   return [
  //     'Воскресенье',
  //     'Понедельник',
  //     'Вторник',
  //     'Среда',
  //     'Четверг',
  //     'Пятница',
  //     'Суббота'
  //   ][date.getDay()]
  // }
}
