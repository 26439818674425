import { useQuery, UseQueryOptions } from 'react-query'
import { QueryFunctionContext } from 'react-query/types/core/types'

import { CompleteApplicationModel } from '../../api/generated/models.types'
import { config } from '../../config'
import useInvalidateQuery from '../useInvalidateQuery'

export const KEY = ['application']

function queryFn(
  context: QueryFunctionContext
): Promise<CompleteApplicationModel> {
  return fetch(`${config.backendUrl}/application`, {
    headers: { 'X-Key': config.backendApiKey! },
    signal: context.signal
  }).then((res) => res.json())
}

export const useApplicationDataQuery = (
  queryOptions?: UseQueryOptions<CompleteApplicationModel, Error>
) => useQuery<CompleteApplicationModel, Error>(KEY, queryFn, queryOptions)

export const useApplicationDataWithMapQuery = <T>(
  mapKey: string,
  map: (application: CompleteApplicationModel) => Promise<T>,
  queryOptions?: UseQueryOptions<T, Error>
) =>
  useQuery<T, Error>(
    [...KEY, mapKey],
    async (ctx) => {
      const result = await queryFn(ctx)
      return map(result)
    },
    queryOptions
  )

export const useInvalidateApplicationDataQuery = () =>
  useInvalidateQuery({ queryKey: KEY })
