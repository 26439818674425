import React from 'react'

interface State {
  theme: string | null
  setTheme: (theme: string | null) => void
}

const initialState: State = {
  theme: null,
  setTheme: (theme: string | null) => {}
}
const ThemeContext = React.createContext(initialState)

type ThemeProviderProps = {
  children: React.ReactNode
}

function ThemeProvider({ children }: ThemeProviderProps) {
  const initialState = () => {
    const storedTheme = localStorage.getItem('theme')

    return storedTheme ? JSON.parse(storedTheme) : null
  }

  const [themeObj, _setTheme] = React.useState(initialState())

  const setTheme = (theme: string | null) => {
    if (theme) {
      localStorage.setItem('theme', JSON.stringify(theme))
    } else {
      localStorage.removeItem('theme')
    }
    _setTheme(theme)
  }

  return (
    <ThemeContext.Provider value={{ theme: themeObj, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
