import { createGlobalStyle } from 'styled-components/macro'

import { GlobalStyleProps } from '../types/styles'

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
    margin: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
  
  .MuiPickerStaticWrapper-root {
    min-height: 300px;
  }

  //:not(.select-all) *:not(input):not(textarea):not(.user-select) {
  //  user-select: none !important;
  //}

  img {
    pointer-events: none;
  }

  .cursor-pointer * {
    cursor: pointer !important;
  }
  
  .pressable, .cursor-pointer {
    //background: inherit;
    //border-width: 1px;
    //border-color: transparent;
    //background: inherit;
  }

  .cursor-pointer:not(.nopressable):hover, .pressable:hover {
    transition: all 100ms ease-in-out;
    //position: relative;
  }

  .theme-light .cursor-pointer:not(.nopressable):hover,
  .theme-light .pressable:hover {
    backdrop-filter: brightness(0.95) drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
    transition: all 250ms ease-in-out;
  }

  .theme-light .cursor-pointer:not(.nopressable):active,
  .theme-light .pressable:active {
    /*backdrop-filter: blur(10px);*/
    backdrop-filter: brightness(0.9) drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
      //border-color: ${(props) => props.theme.palette.primary.dark};
  }

  .theme-dark .cursor-pointer:not(.nopressable):hover,
  .theme-dark .pressable:hover {
    backdrop-filter: brightness(1.25);
    //filter: drop-shadow(0 10px 8px rgb(125 125 125 / 0.04)) drop-shadow(0 4px 3px rgb(125 125 125 / 0.1));
  }

  .theme-dark .cursor-pointer:not(.nopressable):active,
  .theme-dark .pressable:active {
    /*backdrop-filter: blur(10px);*/
    backdrop-filter: brightness(1.15);
    //filter: drop-shadow(0 1px 1px rgb(125 125 125 / 0.05));
      //border-color: ${(props) => props.theme.palette.primary.dark};
  }

  //:not(.safari) {
  //  .theme-light .cursor-pointer:not(.nopressable):hover:before,
  //  .theme-light .pressable:hover:before {
  //    content: "";
  //    position: fixed;
  //    top: 0;
  //    bottom: 0;
  //    left: 0;
  //    right: 0;
  //    z-index: -1;
  //
  //    display: block;
  //    background: inherit;
  //
  //    border-radius: inherit;
  //    filter: brightness(1.1);
  //  }
  //}

  //.group:hover .group-hover\\:opacity-100 {
  //  --tw-opacity: 100 !important;
  //}
  //.group:active .group-active\\:opacity-80 {
  //  --tw-opacity: 80 !important;
  //}
`

export default GlobalStyle as any
