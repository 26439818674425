import {
  StyledEngineProvider as MUIStyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
  useTheme as useMuiTheme
} from '@mui/material/styles'
import jssPreset from '@mui/styles/jssPreset'
import StylesProvider from '@mui/styles/StylesProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import * as dataFnsLocales from 'date-fns/locale'
import { create } from 'jss'
import React, { useEffect, useRef } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components/macro'

import { THEMES } from './constants'
import { ApplicationProvider } from './contexts/ApplicationContext'
import { DateProvider } from './contexts/DateContext'
import { I18NextContextProvider } from './contexts/I18NextContext'
import { AuthProvider } from './contexts/JWTContext'
import { SettingsProvider } from './contexts/SettingsContext'
import useTheme from './hooks/useTheme'
import useTranslation from './hooks/useTranslation'
import { store } from './redux/store'
import createTheme from './theme'
import DateFormatter from './utils/date.format'

// @ts-ignore
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')!
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // notifyOnChangeProps: [
      //   'data',
      //   'isLoading',
      //   'isSuccess',
      //   'error',
      //   'isError',
      //   'dataUpdatedAt',
      //   'errorUpdatedAt'
      // ],
      refetchOnWindowFocus: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      // staleTime: Infinity,
      retry: 5
      // queryFn({ queryKey }) {
      //   let path = queryKey
      //   let init: any = {}
      //   if (path.length > 1 && typeof path[path.length - 1] === 'object') {
      //     path = path.slice(0, -1)
      //     init = queryKey[path.length - 1]
      //   }
      //   return queryRequest(queryKey.join('/'), init)
      // }
    }
  }
})

// const baseInvalidateQueries = queryClient.invalidateQueries
// queryClient.invalidateQueries = function (...props) {
//   console.log(`invalidateQueries(${JSON.stringify(props)})`)
//   // @ts-ignore
//   return baseInvalidateQueries.call(queryClient, ...props)
// }

// const baseRefetchQueries = queryClient.refetchQueries
// queryClient.refetchQueries = function (...props) {
//   console.log(`refetchQueries(${JSON.stringify(props)})`)
//   // @ts-ignore
//   return baseRefetchQueries.call(queryClient, ...props)
// }

interface Props {
  children: React.ReactNode
}

const dateFnsLocales: Record<string, any> = {
  en: dataFnsLocales.enUS,
  ua: dataFnsLocales.uk,
  ru: dataFnsLocales.ru,
  pt: dataFnsLocales.pt,
  de: dataFnsLocales.de,
  fr: dataFnsLocales.fr,
  nl: dataFnsLocales.nl
}

function MyLocalizationProvider({ children }: Props) {
  const { i18n } = useTranslation()
  const locale = dateFnsLocales[i18n.language] || dateFnsLocales.en
  DateFormatter.defaultLocale = locale
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      {children}
    </LocalizationProvider>
  )
}

function HelmetBodyTheme() {
  const theme = useMuiTheme()
  useEffect(() => {
    const classThemeName = `theme-${theme.palette.mode}`
    document.body.classList.add(classThemeName)

    const cssVariables = Object.entries({
      '--color-primary-main': theme.palette.primary.main,
      '--color-primary-light': theme.palette.primary.light,
      '--color-primary-dark': theme.palette.primary.dark
    })
    for (const [varName, varVal] of cssVariables) {
      document.body.style.setProperty(varName, varVal)
    }

    return () => {
      document.body.classList.remove(classThemeName)
      for (const [varName] of cssVariables) {
        document.body.style.removeProperty(varName)
      }
    }
  }, [theme])
  return null
}

export default function AppContainer(props: Props) {
  const { theme } = useTheme()
  const themeName = theme || THEMES.DEFAULT

  const queryClientRef = useRef(queryClient)

  return (
    <I18NextContextProvider>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Modern Clinic" />
        <QueryClientProvider client={queryClientRef.current}>
          {process.env.NODE_ENV === 'development' && (
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          )}
          <ApplicationProvider>
            <Provider store={store}>
              <StylesProvider jss={jss}>
                <MyLocalizationProvider>
                  <MUIStyledEngineProvider>
                    <MUIThemeProvider theme={createTheme(themeName)}>
                      <ThemeProvider theme={createTheme(themeName)}>
                        <HelmetBodyTheme />
                        <AuthProvider>
                          <DateProvider>
                            <SettingsProvider>
                              {props.children}
                            </SettingsProvider>
                          </DateProvider>
                        </AuthProvider>
                      </ThemeProvider>
                    </MUIThemeProvider>
                  </MUIStyledEngineProvider>
                </MyLocalizationProvider>
              </StylesProvider>
            </Provider>
          </ApplicationProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </I18NextContextProvider>
  )
}
