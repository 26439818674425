import _ from 'lodash'
import React, { ContextType, useContext, useMemo } from 'react'

import { I18NextContext } from '../contexts/I18NextContext'
import resources from '../translations'
import { Leaves, LeavesRecords } from '../types/shared'
import { StringUtils } from '../utils/string'

type TranslationsResourcesKeys = keyof typeof resources.ua
type NamespaceResources<
  Namespace extends TranslationsResourcesKeys | undefined = undefined
> = Namespace extends TranslationsResourcesKeys
  ? typeof resources.ua[Namespace]
  : typeof resources.ua

type NamespaceValueOrReactNode<T> = T extends object ? React.ReactNode : string

export interface UseTranslationsValue<
  Namespace extends TranslationsResourcesKeys | undefined = undefined
> {
  i18n: React.ContextType<typeof I18NextContext>

  t<K extends Leaves<NamespaceResources<Namespace>>>(
    key: K,
    props?: Record<string, any> | any[]
    // @ts-ignore
  ): NamespaceValueOrReactNode<LeavesRecords<NamespaceResources<Namespace>>[K]>
  tAny(key: any, props?: Record<string, any> | any[]): string
}

export default function useTranslation<
  Namespace extends TranslationsResourcesKeys | undefined = undefined
>(namespace?: Namespace): UseTranslationsValue<Namespace> {
  const i18n = useContext(I18NextContext)
  if (!i18n)
    throw new Error('I18NextContext must be placed within I18NextProvider')

  const t = useMemo(() => getTranslations(i18n, namespace), [i18n, namespace])
  // @ts-ignore
  return { t, tAny: t, i18n }
}

export function getTranslations<
  Namespace extends TranslationsResourcesKeys | undefined = undefined
>(
  context: ContextType<typeof I18NextContext>,
  namespace?: Namespace
): UseTranslationsValue<Namespace>['t'] {
  return (
    key: Leaves<NamespaceResources<Namespace>>,
    props?: Record<string, any> | any[]
  ): any => {
    if (typeof key !== 'string') {
      return React.isValidElement(key) ? key : JSON.stringify(key)
    }
    if (!key) {
      return key
    }

    // let a = an['']
    let value = _.get(
      namespace ? _.get(resources, namespace) : resources,
      `${context.language}.${namespace ? `${namespace}.` : ''}${key}`
    )
    if (!value) {
      return `${key}${props ? `(${JSON.stringify(props)})` : ''}`
    }

    if (typeof value === 'string') {
      return StringUtils.format(value, props)
    } else if (React.isValidElement(value)) {
      return value
    }
    try {
      return React.createElement(value, props)
    } catch (err) {
      return JSON.stringify(value)
    }
  }
}
