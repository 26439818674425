const config = {
  apiKey: 'AIzaSyA4Wsk00zEyKWC3GGXs-E4OKor0CmHPYIM',
  authDomain: 'profident-dnipro-ukraine.firebaseapp.com',
  databaseURL: 'https://profident-dnipro-ukraine.firebaseio.com',
  projectId: 'profident-dnipro-ukraine',
  storageBucket: 'profident-dnipro-ukraine.appspot.com',
  messagingSenderId: '286036448283',
  appId: '1:286036448283:web:5751154eef3e6bcb6e54be',
  measurementId: 'G-B8QKMY1GWF'
}

export default config
