// import TabsListUnstyled from '@mui/base/TabsListUnstyled'
// import TabsUnstyled from '@mui/base/TabsUnstyled'
// import TabUnstyled from '@mui/base/TabUnstyled'
import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'

import useForceUpdate from '../../hooks/useForceUpdate'
import Loader from '../Loader'

const BASE_TAB_PADDING_X = 16

const SelectedChip = styled('div')`
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: ${(props) => props.theme.palette.primary.light};
  border-radius: 4px;
`

// const Tab = styled(TabUnstyled)<{ px: number }>`
//   z-index: 10;
//   //border: 1px solid white;
//   font-family: IBM Plex Sans, sans-serif;
//   color: ${(props) => props.theme.palette.primary.contrastText};
//   font-size: 0.875rem;
//   font-weight: bold;
//   background-color: transparent;
//   width: 100%;
//   padding: 6px ${(props) => props.px}px;
//   margin: 6px 6px;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   white-space: nowrap;
//
//   &:focus {
//     color: #fff;
//     border-radius: 4px;
//     outline: ${(props) => `1px solid ${props.theme.palette.primary.main}`};
//   }
// `
//
// const TabsList = styled(TabsListUnstyled)<{
//   noHeaderBorder?: boolean
// }>`
//   border: ${(props) =>
//     props.noHeaderBorder
//       ? ''
//       : `2px solid ${props.theme.palette.primary.main}`};
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   align-content: space-between;
// `

interface Props {
  value?: number
  setValue?: React.Dispatch<number>

  items: {
    title: string
    Component?: React.ComponentType
    disabled?: boolean
  }[]
  children?:
    | React.ReactNode
    | React.ComponentType<{ selectedTab: number; minSelected: number }>
  prependChildren?: React.ReactNode
  tabsRef?: React.RefObject<HTMLDivElement>
  containerRef?: React.RefObject<HTMLDivElement>
  style?: React.CSSProperties
  className?: string
  tabsClassName?: string
  tabItemClassName?: string
  defaultValue?: number
  minSelected?: number

  containerClassName?: string
  containerStyles?: React.CSSProperties
  tabContainerStyles?: React.CSSProperties
  tabStyles?: React.CSSProperties
  noHeaderBorder?: boolean
  noScrollable?: boolean

  paddingX?: number
  fullWidthTabs?: boolean
}

function getElementFullSize(element?: HTMLElement | null): {
  width: number
  height: number
  ml: number
  mr: number
  mt: number
  mb: number
} {
  if (!element) return { width: 0, height: 0, ml: 0, mr: 0, mt: 0, mb: 0 }
  const style =
    (element as any).currentStyle || window.getComputedStyle(element)
  const ml = parseFloat(style.marginLeft)
  const mr = parseFloat(style.marginRight)
  const mt = parseFloat(style.marginTop)
  const mb = parseFloat(style.marginBottom)
  return {
    ml,
    mr,
    mt,
    mb,
    width: element.offsetWidth,
    height: element.offsetHeight
  }
}

function NewMultiTabs(props: Props) {
  const theme = useTheme()

  const minSelected = Math.max(
    Math.min(props.minSelected || 1, props.items.length),
    1
  )

  const [tabState, setTabState] = useState(props.defaultValue || 0)
  const tab = props.value || tabState
  const setTab = props.setValue || setTabState
  const targetTab = Math.max(Math.min(tab, props.items.length - minSelected), 0)

  const tabsRef = useRef<Record<number, HTMLButtonElement>>({})
  const setTabsRef = (i: number, ref: HTMLButtonElement) => {
    tabsRef.current = { ...tabsRef.current, [i]: ref }
  }

  // const tabRef = useRef<HTMLDivElement>(null)
  // const tabWidth = tabsRef.current[targetTab]?.offsetWidth || 0
  // const tabHeight = tabsRef.current[targetTab]?.offsetHeight || 0
  const tabLeftPosition = tabsRef.current[targetTab]?.offsetLeft || 0

  // We need this to update selected area width
  const forceUpdate = useForceUpdate()
  useEffect(() => {
    setTimeout(() => {
      forceUpdate()
    }, 250)
  }, [forceUpdate])

  const tabPaddingX = props.paddingX ?? BASE_TAB_PADDING_X
  const highlightedTabsElements = new Array(minSelected)
    .fill(undefined)
    .map((_, i) => getElementFullSize(tabsRef.current[targetTab + i]))

  return (
    <div
      className={`flex flex-col w-full space-y-2 ${props.className || ''}`}
      style={props.style}
    >
      {props.prependChildren && props.prependChildren}
      <div
        className="w-full max-w-full overflow-x-auto overflow-y-hidden"
        style={{
          borderRadius: 8,
          border: `2px solid ${theme.palette.primary.light}`,
          ...(props.tabContainerStyles || {})
        }}
      >
        <div
          ref={props.tabsRef}
          className="relative flex flex-row items-center justify-items-stretch w-auto p-1 space-x-2"
        >
          {/*{props.prependChildren && props.prependChildren}*/}
          {props.items.map((it, i) => {
            const isDisabled =
              it.disabled === true || minSelected === props.items.length
            const isSelected = targetTab > i || targetTab + minSelected <= i
            return (
              <Button
                key={i}
                className={`z-10 ${props.tabItemClassName || ''}`}
                ref={(ref: any) => setTabsRef(i, ref)}
                onClick={() => setTab(i)}
                disabled={isDisabled}
                variant="text"
                color="primary"
                style={{
                  ...(props.fullWidthTabs ? { flex: 1 } : {}),
                  minWidth: 100,
                  lineClamp: 2,
                  color:
                    it.disabled === true
                      ? undefined
                      : isDisabled || isSelected
                      ? theme.palette.primary.main
                      : theme.palette.primary.contrastText
                  // borderRadius: 8,
                  // border: `1px solid ${theme.palette.primary.main}`
                }}
              >
                {it.title}
              </Button>
            )
          })}
          {minSelected !== props.items.length && (
            <SelectedChip
              className="absolute"
              ref={(node: any) => {
                if (node) {
                  node.style.setProperty('margin', '0', 'important')
                }
              }}
              style={{
                left: 6,
                transform: `translateX(${
                  // targetTab * (tabWidth + TAB_PADDING_X - 4)
                  tabLeftPosition - 6
                }px)`,
                height: highlightedTabsElements.reduce(
                  (height, size) =>
                    Math.max(height, size.height + size.mt + size.mb),
                  0
                ),
                width: highlightedTabsElements.reduce(
                  (sum, size, i, all) =>
                    sum +
                    size.width +
                    (i === 0 ? 0 : size.ml) +
                    (i === all.length - 1 ? 0 : size.mr),
                  0
                )
                // width: `calc(${tabWidth * minSelected + 1}px + 0.5rem * ${
                //   minSelected - 1
                // }px)`,
                // width:
                //   tabWidth * minSelected +
                //   1 +
                //   '0.5rem' * (minSelected - 1),
                // height: tabRef.current?.offsetHeight
              }}
            />
          )}
        </div>
      </div>
      {/*<TabsUnstyled*/}
      {/*  ref={ref}*/}
      {/*  value={tab}*/}
      {/*  className={`w-full max-w-full`}*/}
      {/*  style={{ marginBottom: '16px' }}*/}
      {/*  onChange={(ev, tab) => setTab(tab as number)}*/}
      {/*>*/}
      {/*  {props.prependChildren && props.prependChildren}*/}
      {/*  <TabsList*/}
      {/*    ref={props.tabsRef}*/}
      {/*    noHeaderBorder={props.noHeaderBorder}*/}
      {/*    style={props.tabContainerStyles}*/}
      {/*    className={`relative max-w-full overflow-x-scroll w-full ${*/}
      {/*      props.tabsClassName || ''*/}
      {/*    }`}*/}
      {/*  >*/}
      {/*    {props.items.map((it, i) => {*/}
      {/*      const isDisabled =*/}
      {/*        it.disabled === true || minSelected === props.items.length*/}
      {/*      return (*/}
      {/*        <Tab*/}
      {/*          key={i}*/}
      {/*          px={BASE_TAB_PADDING_X}*/}
      {/*          ref={(ref: any) => setTabsRef(i, ref)}*/}
      {/*          className={*/}
      {/*            (isDisabled ? '' : 'cursor-pointer ') +*/}
      {/*            (props.tabItemClassName || '')*/}
      {/*          }*/}
      {/*          disabled={isDisabled}*/}
      {/*          style={{*/}
      {/*            ...(minSelected === props.items.length*/}
      {/*              ? {*/}
      {/*                color: theme.palette.primary.light*/}
      {/*              }*/}
      {/*              : targetTab > i || targetTab + minSelected <= i*/}
      {/*                ? {*/}
      {/*                  color: theme.palette.primary.main*/}
      {/*                }*/}
      {/*                : {}),*/}
      {/*            ...(props.tabStyles || {})*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {it.title}*/}
      {/*        </Tab>*/}
      {/*      )*/}
      {/*    })}*/}
      {/*    {minSelected !== props.items.length && (*/}
      {/*      <SelectedChip*/}
      {/*        className="absolute"*/}
      {/*        style={{*/}
      {/*          left: 6,*/}
      {/*          transform: `translateX(${*/}
      {/*            // targetTab * (tabWidth + TAB_PADDING_X - 4)*/}
      {/*            tabLeftPosition - 6*/}
      {/*          }px)`,*/}
      {/*          width:*/}
      {/*            tabWidth * minSelected +*/}
      {/*            1 +*/}
      {/*            (BASE_TAB_PADDING_X - 4) * (minSelected - 1),*/}
      {/*          height: tabHeight*/}
      {/*          // height: tabRef.current?.offsetHeight*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </TabsList>*/}
      {/*</TabsUnstyled>*/}
      <div
        ref={props.containerRef}
        className={`grid w-full relative overflow-x-visible grow ${
          props.containerClassName || ''
        }`}
        style={{
          margin: '0 8px',
          ...(props.noScrollable
            ? {
                minHeight: '100%',
                overflowY: 'hidden'
              }
            : {
                maxHeight: '100%',
                overflowY: 'scroll'
              }),
          gridTemplateColumns: `repeat(${minSelected}, minmax(0, 1fr))`,
          columnGap: tabPaddingX,
          ...(props.containerStyles || {})
        }}
      >
        <React.Suspense fallback={<Loader />}>
          {props.items
            .slice(targetTab, targetTab + minSelected)
            .map(
              ({ Component }, i) =>
                Component && <Component key={targetTab + i} />
            )}
          {props.children &&
            (React.isValidElement(props.children)
              ? props.children
              : React.createElement(props.children as any, {
                  selectedTab: targetTab,
                  minSelected: minSelected
                }))}
        </React.Suspense>
      </div>
    </div>
  )
}

// function MultiTabs(props: Props, ref: React.ForwardedRef<HTMLDivElement>) {
//   const theme = useTheme()
//   const forceUpdate = useForceUpdate()
//
//   const minSelected = Math.max(
//     Math.min(props.minSelected || 1, props.items.length),
//     1
//   )
//
//   const [tabState, setTabState] = useState(props.defaultValue || 0)
//   const tab = props.value || tabState
//   const setTab = props.setValue || setTabState
//   const targetTab = Math.max(Math.min(tab, props.items.length - minSelected), 0)
//
//   const tabsRef = useRef<Record<number, HTMLButtonElement>>({})
//   const setTabsRef = (i: number, ref: HTMLButtonElement) => {
//     tabsRef.current = { ...tabsRef.current, [i]: ref }
//   }
//
//   // const tabRef = useRef<HTMLDivElement>(null)
//   const tabWidth = tabsRef.current[targetTab]?.offsetWidth || 0
//   const tabHeight = tabsRef.current[targetTab]?.offsetHeight || 0
//   const tabLeftPosition = tabsRef.current[targetTab]?.offsetLeft || 0
//
//   useEffect(() => {
//     setTimeout(() => {
//       forceUpdate()
//     }, 250)
//   }, [forceUpdate])
//
//   /**
//    style={{
//               ...(minSelected === props.items.length
//                 ? {
//                   background: theme.palette.primary.light
//                 }
//                 : {}),
//               (...(targetTab > i || (targetTab + minSelected) < i)
//                 ? {
//                   color: theme.palette.primary.main,
//                 }
//                 : {})
//             }}
//    */
//
//   // const deviceDetails = getDeviceDetails()
//   // if (deviceDetails.browser.name.includes('Safari')) {
//   //   return <LegacyMultiTabs ref={ref} {...props} />
//   // }
//
//   const tabPaddingX = props.paddingX ?? BASE_TAB_PADDING_X
//
//   return (
//     <div
//       className={`flex flex-col w-full h-full ${props.className || ''}`}
//       style={props.style}
//     >
//       <TabsUnstyled
//         ref={ref}
//         value={tab}
//         className={`w-full max-w-full`}
//         style={{ marginBottom: '16px' }}
//         onChange={(ev, tab) => setTab(tab as number)}
//       >
//         {props.prependChildren && props.prependChildren}
//         <TabsList
//           ref={props.tabsRef}
//           noHeaderBorder={props.noHeaderBorder}
//           style={props.tabContainerStyles}
//           className={`relative max-w-full overflow-x-scroll w-full ${
//             props.tabsClassName || ''
//           }`}
//         >
//           {props.items.map((it, i) => {
//             const isDisabled =
//               it.disabled === true || minSelected === props.items.length
//             return (
//               <Tab
//                 key={i}
//                 px={BASE_TAB_PADDING_X}
//                 ref={(ref: any) => setTabsRef(i, ref)}
//                 className={
//                   (isDisabled ? '' : 'cursor-pointer ') +
//                   (props.tabItemClassName || '')
//                 }
//                 disabled={isDisabled}
//                 style={{
//                   ...(minSelected === props.items.length
//                     ? {
//                         color: theme.palette.primary.light
//                       }
//                     : targetTab > i || targetTab + minSelected <= i
//                     ? {
//                         color: theme.palette.primary.main
//                       }
//                     : {}),
//                   ...(props.tabStyles || {})
//                 }}
//               >
//                 {it.title}
//               </Tab>
//             )
//           })}
//           {minSelected !== props.items.length && (
//             <SelectedChip
//               className="absolute"
//               style={{
//                 left: 6,
//                 transform: `translateX(${
//                   // targetTab * (tabWidth + TAB_PADDING_X - 4)
//                   tabLeftPosition - 6
//                 }px)`,
//                 width:
//                   tabWidth * minSelected +
//                   1 +
//                   (BASE_TAB_PADDING_X - 4) * (minSelected - 1),
//                 height: tabHeight
//                 // height: tabRef.current?.offsetHeight
//               }}
//             />
//           )}
//         </TabsList>
//       </TabsUnstyled>
//       <div
//         ref={props.containerRef}
//         className={`grid w-full relative overflow-x-visible ${
//           props.containerClassName || ''
//         }`}
//         style={{
//           margin: '0 8px',
//           ...(props.noScrollable
//             ? {
//                 minHeight: '100%',
//                 overflowY: 'hidden'
//               }
//             : {
//                 maxHeight: '100%',
//                 overflowY: 'scroll'
//               }),
//           gridTemplateColumns: `repeat(${minSelected}, minmax(0, 1fr))`,
//           columnGap: tabPaddingX,
//           ...(props.containerStyles || {})
//         }}
//       >
//         <React.Suspense fallback={<Loader />}>
//           {props.items
//             .slice(targetTab, targetTab + minSelected)
//             .map(
//               ({ Component }, i) =>
//                 Component && <Component key={targetTab + i} />
//             )}
//           {props.children &&
//             (React.isValidElement(props.children)
//               ? props.children
//               : React.createElement(props.children as any, {
//                   selectedTab: targetTab,
//                   minSelected: minSelected
//                 }))}
//         </React.Suspense>
//       </div>
//     </div>
//   )
// }

// const LegacyMultiTabs = React.forwardRef(
//   (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
//     const theme = useTheme()
//     const forceUpdate = useForceUpdate()
//
//     const minSelected = Math.max(
//       Math.min(props.minSelected || 1, props.items.length),
//       1
//     )
//
//     const [tabState, setTabState] = useState(props.defaultValue || 0)
//     const tab = props.value || tabState
//     const setTab = props.setValue || setTabState
//     const targetTab = Math.max(Math.min(tab, props.items.length - minSelected), 0)
//
//     const tabsRef = useRef<Record<number, HTMLButtonElement>>({})
//     const setTabsRef = (i: number, ref: HTMLButtonElement) => {
//       tabsRef.current = { ...tabsRef.current, [i]: ref }
//     }
//
//     // const tabRef = useRef<HTMLDivElement>(null)
//     const tabWidth = tabsRef.current[targetTab]?.offsetWidth || 0
//     const tabHeight = tabsRef.current[targetTab]?.offsetHeight || 0
//     const tabLeftPosition = tabsRef.current[targetTab]?.offsetLeft || 0
//
//     useEffect(() => {
//       setTimeout(() => {
//         forceUpdate()
//       }, 250)
//     }, [forceUpdate])
//
//     return (
//       <MaterialTabs>
//         <MaterialTab/>
//       </MaterialTabs>
//     )
//   }
// )

// export default React.forwardRef(MultiTabs)
export default NewMultiTabs
