export const UNBREAKABLE_SPACE = '\u00A0'

export function formatNumberWithSpaces(
  num: number,
  fractionDigits: number | undefined = 0
) {
  const numTxt =
    fractionDigits !== undefined ? num.toFixed(fractionDigits) : num.toString()
  try {
    const regExp = new RegExp('\\B(?<!\\.\\d*)(?=(\\d{3})+(?!\\d))', 'g')
    return numTxt.replace(regExp, UNBREAKABLE_SPACE)
  } catch (err) {
    return numTxt
  }
}

export function formatNumberToLength(num: any, length: number = 2): string {
  // return `${Math.trunc(num / 10)}${Math.trunc(num % 10)}`;
  // return num.toLocaleString('en-US', { minimumIntegerDigits: length })
  const ns = (num || '').toString()
  return (ns.length < length ? '0'.repeat(length - ns.length) : '') + ns
}
