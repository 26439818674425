import _ from 'lodash'
import { useQuery, UseQueryOptions } from 'react-query'
import { QueryFunctionContext } from 'react-query/types/core/types'

import { IModelsValues } from '../../api/generated/models'
import {
  CompleteModelType,
  SimpleModelType
} from '../../api/generated/models.types'
import { routeOf } from '../../api/routes'

export const useModelQuery = <
  Model extends IModelsValues,
  Result extends
    | SimpleModelType<Model>
    | CompleteModelType<Model>
    | number
    | null
    | undefined = SimpleModelType<Model> | CompleteModelType<Model>
>(
  type: Model,
  model: Result,
  queryOptions?: UseQueryOptions<Result, Error>
) => {
  const modelId = typeof model === 'number' ? model : _.get(model, 'id')
  const query = useQuery<Result, Error>(
    [type, modelId],
    async (ctx: QueryFunctionContext) => {
      const data = await routeOf(type).findOne(
        { id: modelId },
        {
          signal: ctx.signal
        }
      )
      return data as Result
    },
    {
      ...(queryOptions || {}),
      enabled: !!modelId
    }
  )
  return query.isSuccess
    ? query.data
    : typeof model === 'number'
    ? ({} as any)
    : model
}
