import axios from 'axios'
import { createContext, ReactNode, useEffect, useReducer } from 'react'
import { useQueryClient } from 'react-query'

import LoadingScreen from '../components/LoadingScreen'
import { config } from '../config'
import { AuthState, AuthUser, JWTContextType } from '../types/auth'
import { ActionMap } from '../types/context'
import { isValidToken, setSession } from '../utils/jwt'

const INITIALIZE = 'INITIALIZE'
const SIGN_IN = 'SIGN_IN'
const SIGN_OUT = 'SIGN_OUT'

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean
    user: AuthUser
  }
  [SIGN_IN]: {
    user: AuthUser
  }
  [SIGN_OUT]: undefined
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      }
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      }
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }

    default:
      return state
  }
}

const AuthContext = createContext<JWTContextType | null>(null)

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState)
  const queryClient = useQueryClient()

  useEffect(() => {
    const initialize = async () => {
      try {
        let accessToken = window.localStorage.getItem('accessToken')
        if (!accessToken) {
          // Remove after complete migration
          try {
            const userData = JSON.parse(
              window.localStorage.getItem('shared@user') || '{}'
            )
            accessToken = userData.jwt
          } catch (err) {}
        }

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken)

          const response = await axios.get('/v1/user/refresh')
          if (response.status === 200) {
            const { jwt, user } = response.data
            setSession(jwt)

            return dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user
              }
            })
          }
        }
      } catch (err) {
        console.error(err)
      }

      setSession(null)
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null
        }
      })
    }

    initialize()
  }, [])

  const signIn = async (identifier: string, password: string) => {
    const response = await fetch(`${config.backendUrl}/auth/local`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        identifier,
        password
      })
    })
    const { jwt }: { jwt: string } = await response.json()
    setSession(jwt)

    const { data: user } = await axios.get('/v1/personal/me')
    dispatch({
      type: SIGN_IN,
      payload: { user }
    })
    return user
  }

  const signOut = () => {
    setSession(null)
    dispatch({ type: SIGN_OUT })
    queryClient.clear()
  }

  if (!state.isInitialized) {
    return <LoadingScreen />
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        role: state.user?.role || null,
        method: 'jwt',
        signIn,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
