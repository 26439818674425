import '@mui/lab/themeAugmentation'

import { createTheme as createMuiTheme } from '@mui/material/styles'

import breakpoints from './breakpoints'
import components from './components'
import { customShadows } from './new-shadows'
import ComponentsOverrides from './overrides'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import variants from './variants'

const createTheme = (name: string) => {
  let themeConfig = variants.find((variant) => variant.name === name)

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`))
    themeConfig = variants[0]
  }

  const isLight = (themeConfig.palette as any).mode === 'light'
  const newPalette = isLight ? palette.light : palette.dark

  const theme = createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      typography: typography,
      shadows: shadows,
      palette: {
        ...themeConfig.palette,
        grey: newPalette.grey,
        gradients: newPalette.gradients,
        chart: newPalette.chart
      },
      // @ts-ignore
      components: components(themeConfig.palette),
      customShadows: isLight ? customShadows.light : customShadows.dark,
      zIndex: {
        snackbar: 5000,
        tooltip: 2000,
        mobileStepper: 1500,
        modal: 1400,
        speedDial: 1000,
        fab: 1000,
        drawer: 900,
        appBar: 800
      }
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar
    }
  )

  ComponentsOverrides(theme)
  return theme
}

export default createTheme
