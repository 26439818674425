import { Dialog, DialogProps, useMediaQuery, useTheme } from '@mui/material'
import { alpha } from '@mui/material/styles'
import React from 'react'

export interface IBaseDialogProps extends DialogProps {
  disableFullScreen?: boolean
  blurBackdrop?: boolean
}

function IBaseDialog({ children, ...props }: IBaseDialogProps) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(
    theme.breakpoints.down(props.maxWidth || 'sm')
  )

  return (
    <Dialog
      scroll="paper"
      {...props}
      hideBackdrop={props.blurBackdrop}
      fullScreen={props.disableFullScreen ? false : fullScreen}
      style={Object.assign(props.style || {}, {
        ...(props.blurBackdrop
          ? {
              // backgroundColor: 'rgba(111, 126, 140, 0.2)',
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
              backdropFilter: 'blur(4px)'
            }
          : {})
      })}
      PaperProps={{
        ...(props.PaperProps || {}),
        style: {
          ...(props.PaperProps?.style || {}),
          backgroundColor: theme.palette.background.default
        }
      }}
    >
      <b>{fullScreen}</b>
      {children}
    </Dialog>
  )
}

IBaseDialog.whyDidYouRender = true
export default IBaseDialog
