import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import SocketIOClient from 'socket.io-client'
import SocketIOWildcard from 'socketio-wildcard'

import { IModelsValues } from '../api/generated/models'
import { ModelsRelations } from '../api/generated/models.relations'
import { queryClient } from '../AppContainer'
import { showAlert } from '../components/shared/Alert'
import { config } from '../config'

type RealtimeEventHandler<T = any> = (
  event: string,
  result: T,
  isMounted: boolean
) => void
type RealtimeHandler<T = any> = (event: string, result: T) => void

// interface IEntryEvents {
//   create: string
//   update: string
//   delete: string
// }

export enum CUDAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

export function entryEvent(model: string, action: CUDAction): string {
  return `entry:${model}:${action}`
}

class SharedSocket {
  private subs: Record<string, Record<string, RealtimeEventHandler>> = {}
  private timeout: NodeJS.Timeout | undefined
  private closeAlert: (() => void) | undefined
  private reconnectCounter: number = 0
  private destroyed: boolean = false

  private socketClient = SocketIOClient(config.backendWSUrl!, {
    reconnectionDelayMax: 1e3,
    reconnectionDelay: 5e2,
    reconnection: true,

    query: {},
    secure: true,
    path: '/socket.io',
    autoConnect: false,
    transports: ['websocket', 'polling'], // use WebSocket first, if available
    upgrade: true
  })

  constructor() {
    // @ts-ignore
    window.socket = this.socketClient
    const patch = SocketIOWildcard(SocketIOClient.Manager)
    patch(this.socketClient)
  }

  // public getEntryUpdateEvents(model: string): IEntryEvents {
  //   return {
  //     create: entryEvent(model, CUDAction.CREATE),
  //     update: entryEvent(model, CUDAction.UPDATE),
  //     delete: entryEvent(model, CUDAction.DELETE)
  //   }
  // }
  //
  // public getEntryUpdateEventsList(model: string): string[] {
  //   return [
  //     entryEvent(model, CUDAction.CREATE),
  //     entryEvent(model, CUDAction.UPDATE),
  //     entryEvent(model, CUDAction.DELETE)
  //   ]
  // }

  // public useOnCUD(
  //   model: string,
  //   handlers: {
  //     create?: RealtimeHandler
  //     update?: RealtimeHandler
  //     delete?: RealtimeHandler
  //   },
  //   deps?: React.DependencyList
  // ) {
  //   const actions: string[] = []
  //   const events = {
  //     create: entryEvent(model, CUDAction.CREATE),
  //     update: entryEvent(model, CUDAction.UPDATE),
  //     delete: entryEvent(model, CUDAction.DELETE)
  //   }
  //
  //   if (handlers.create) {
  //     actions.push(events.create)
  //   }
  //   if (handlers.update) {
  //     actions.push(events.update)
  //   }
  //   if (handlers.delete) {
  //     actions.push(events.delete)
  //   }
  //   return this.useEvents(
  //     actions,
  //     (event, model: any) => {
  //       switch (event) {
  //         case events.create:
  //           return handlers.create?.(event, model)
  //         case events.update:
  //           return handlers.update?.(event, model)
  //         case events.delete:
  //           return handlers.delete?.(event, model)
  //         default:
  //           throw new Error('Unknown event received')
  //       }
  //     },
  //     deps
  //   )
  // }

  // public useOnCreate(
  //   model: string,
  //   handler: RealtimeHandler,
  //   deps?: React.DependencyList
  // ) {
  //   return this.useOnAction(CUDAction.CREATE, model, handler, deps)
  // }
  //
  // public useOnUpdate(
  //   model: string,
  //   handler: RealtimeHandler,
  //   deps?: React.DependencyList
  // ) {
  //   return this.useOnAction(CUDAction.UPDATE, model, handler, deps)
  // }
  //
  // public useOnDelete(
  //   model: string,
  //   handler: RealtimeHandler,
  //   deps?: React.DependencyList
  // ) {
  //   return this.useOnAction(CUDAction.DELETE, model, handler, deps)
  // }

  public useEvent(
    event: string,
    handler: RealtimeEventHandler,
    deps?: React.DependencyList
  ) {
    // eslint-disable-next-line
    return React.useEffect(() => {
      let isMounted = true
      const subKeys = this.onEvent(event, handler, isMounted)
      return () => {
        isMounted = false
        this.remove(subKeys)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, handler, ...(deps || [])])
  }

  public useEvents(
    events: string[],
    handler: RealtimeEventHandler,
    deps?: React.DependencyList
  ) {
    // eslint-disable-next-line
    return React.useEffect(() => {
      let isMounted = true
      const subKeys = events.map((ev) => this.onEvent(ev, handler, isMounted))
      return () => {
        isMounted = false
        subKeys.forEach((ev) => this.remove(ev))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events, handler, ...(deps || [])])
  }

  public useEventState(event: string, defaultValue?: any) {
    // eslint-disable-next-line
    const [state, setState] = React.useState(defaultValue)
    this.useEvents(
      [event],
      (_, val) => {
        setState(val)
      },
      [event]
    )
    return [state, setState]
  }

  // private useOnAction(
  //   action: CUDAction,
  //   model: string,
  //   handler: RealtimeHandler,
  //   deps?: React.DependencyList
  // ) {
  //   return this.useEvents([entryEvent(model, action)], handler, deps)
  // }

  // public subscribeCUD(
  //   model: string,
  //   handlers: {
  //     create?: RealtimeHandler
  //     update?: RealtimeHandler
  //     delete?: RealtimeHandler
  //   }
  // ) {
  //   const events = {
  //     create: entryEvent(model, CUDAction.CREATE),
  //     update: entryEvent(model, CUDAction.UPDATE),
  //     delete: entryEvent(model, CUDAction.DELETE)
  //   }
  //
  //   let subKey: any = {
  //     create: null,
  //     update: null,
  //     delete: null
  //   }
  //
  //   if (handlers.create) {
  //     subKey.create = this.on(events.create, handlers.create)
  //   }
  //   if (handlers.update) {
  //     subKey.update = this.on(events.update, handlers.update)
  //   }
  //   if (handlers.delete) {
  //     subKey.delete = this.on(events.delete, handlers.delete)
  //   }
  //
  //   return () => {
  //     this.remove(subKey.create)
  //     this.remove(subKey.update)
  //     this.remove(subKey.delete)
  //   }
  // }

  private shouldEmit: any[] = []

  public get connected(): boolean {
    return this.loaded && this.socketClient.connected
  }

  public get loaded(): boolean {
    return !!this.socketClient
  }

  initialize(accessToken: string) {
    this.socketClient.close()
    this.socketClient.removeAllListeners()

    this.socketClient.io.opts.query = { token: accessToken }
    this.socketClient.on('connect', () => {
      console.log('connect')
      // Object.values({
      //   ...(this.subs.connect ?? {}),
      //   ...(this.subs['*'] ?? {})
      // }).forEach((callback: any) => {
      //   callback.apply(null)
      // })

      this.closeAlert?.()
      this.reconnectCounter = 0

      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      console.log('Successfully connected!')
      const emitting = [...(this.shouldEmit || [])]
      this.shouldEmit = []
      emitting.forEach((it) => {
        this.emit(it.event, it.data, it.callback)
      })
      // TODO: electron service
      // ElectronService.onSharedUserBroadcastId((id) => {
      //   this.emit('user:broadcast', id)
      // })

      this.socketClient.on('entry', async ({ event, model, payload }: any) => {
        // await new Promise((resolve) => setTimeout(resolve, 2000))
        await queryClient.setQueryData([model, payload.id], payload)
        await queryClient.invalidateQueries({
          exact: false,
          queryKey: [model],
          predicate(query) {
            if (
              !query.state.data ||
              !Array.isArray(query.queryKey) ||
              query.queryKey[0] !== model
            ) {
              return false
            }
            let changed = false
            let result: any = query.state.data
            if (Array.isArray(result)) {
              result = [...result]
                .map((it) => {
                  if (it.id === payload.id) {
                    changed = true
                    return event === 'delete' ? null : payload
                  }
                  return it
                })
                .filter(Boolean)
            } else if (result.id === payload.id) {
              changed = true
              result = payload
            }

            if (changed) {
              query.setData(result)
            }
            return !changed
          }
        })

        const relations = ModelsRelations[model as IModelsValues] || []
        for (const it of relations) {
          if (!it.mappedBy) continue
          let deps = payload[it.name]
          if (!Array.isArray(deps)) {
            deps = [deps]
          }

          for (const d of deps) {
            if (!d) continue
            const depId = typeof d === 'object' ? d.id : d

            await queryClient.invalidateQueries([
              it.mappedBy.target,
              { [it.mappedBy.inversedBy]: depId }
            ])
            await queryClient.invalidateQueries([
              it.mappedBy.target,
              { [it.mappedBy.inversedBy]: { id: depId } }
            ])
          }
        }
      })
    })
    // this.socketClient.on('connect_error', (err: any) => this.handleError(err));
    // this.socketClient.on('error', (err: any) => this.handleError(err));
    this.socketClient.on('disconnect', () => {
      this.closeAlert?.()
      if (this.destroyed) return
      this.closeAlert = showAlert({
        error: true,
        persists: true,
        loading: true,
        title: 'Соединение с сервером...',
        subtitle: 'Попытка повторного подключения'
      })
      this.timeout = setTimeout(() => {
        if (!this.socketClient.connected) {
          // @ts-ignore
          window.location.reload(true)
        } else {
          this.closeAlert?.()
          console.log('Skipping reload, socket is connected')
        }
      }, 2 * 60_000)
      console.log('Reconnect attempt...')
    })

    this.socketClient.on('connect_error', () => {
      // revert to classic upgrade
      this.socketClient.io.opts.transports = ['polling', 'websocket']
    })

    this.socketClient.on('*', ({ data }: any) => {
      Object.values({
        ...(this.subs[data[0]] ?? {}),
        ...(this.subs['*'] ?? {})
      }).forEach((callback) => {
        callback.apply(null, data)
      })
    })

    this.socketClient.connect()
  }

  // private handleError(err: any) {
  //   switch (err.code) {
  //     case 'invalid_token':
  //     case 'user_invalid':
  //       break
  //     default:
  //       // SwalUIHelper.error(err)
  //       break
  //   }
  //   console.log(err instanceof Error) // true
  //   console.log(err.message) // not authorized
  //   console.log(err.data) // { content: "Please retry later" }
  // }

  connect(callback: () => void) {
    this.on('connect', callback)
  }

  on(event: string, callback: RealtimeHandler): { event: string; key: string } {
    const key = nanoid()
    if (!this.subs[event]) {
      this.subs[event] = {}
    }
    this.subs[event][key] = callback
    return { event, key }
    // return this.socketClient.on(event, callback)
  }

  onRaw(event: string, callback: RealtimeHandler) {
    return this.socketClient.on(event, callback)
  }

  offRaw(event: string, callback?: RealtimeHandler) {
    return this.socketClient.off(event, callback)
  }

  onEvent(
    event: string,
    callback: RealtimeEventHandler,
    isMounter: boolean = true
  ): { event: string; key: string } {
    return this.on(event, (event, ...props) =>
      callback(event, props, isMounter)
    )
  }

  emit(
    event: string,
    data?: any,
    callback?: (...args: any[]) => void
  ): SocketIOClient.Emitter {
    if (!this.connected) {
      this.shouldEmit.push({ event, data, callback })
      return this.socketClient
    }
    return this.socketClient.emit(event, data, callback)
  }

  remove(props?: { event: string; key: string } | null) {
    if (!props) {
      return
    }
    const { event, key } = props
    if (this.subs[event]) {
      delete this.subs[event][key]
    }
  }

  disconnect() {
    this.destroyed = true
    if (this.socketClient.connected) {
      this.socketClient.close()
    }
  }
}

const socket = new SharedSocket()
// @ts-ignore
import('./socket.io.hack').then((hk) => {
  hk.default(socket)
})
export default socket
