import './index.css'

import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import AppContainer from './AppContainer'
import MotionLazyContainer from './components/animate/MotionLazyContainer'
import { ProgressBarStyle } from './components/ProgressBar'
import { ThemeProvider } from './contexts/ThemeContext'
import DialogsStore from './dialogs/lib'
import { ApplicationRoutes } from './routes'

function App() {
  return (
    <MotionLazyContainer>
      <BrowserRouter>
        <ThemeProvider>
          <AppContainer>
            <ProgressBarStyle />
            <ApplicationRoutes />
            <DialogsStore />
          </AppContainer>
        </ThemeProvider>
      </BrowserRouter>
    </MotionLazyContainer>
  )
}

export default App
