// Themes
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO',
  MUI_BLUE_LIGHT: 'MUI BLUE LIGHT',
  MUI_BLUE_DARK: 'MUI BLUE DARK'
}

export enum Roles {
  Admin = 'admin',
  Reception = 'reception',
  Doctor = 'doctor'
}
