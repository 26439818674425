import axios from 'axios'
import * as jose from 'jose'

import { config } from '../config'
import socket from './socket.io'

axios.defaults.baseURL = config.backendUrl

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false
  }
  const decoded = jose.decodeJwt(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp && decoded.exp > currentTime
}

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;
//
//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   expiredTimer = window.setTimeout(() => {
//   }, timeLeft);
// };

// axios.interceptors.request.use(function (config) {
//   const accessToken = localStorage.getItem('accessToken')
//   config.headers || (config.headers = {})
//   if (accessToken) {
//     config.headers.Authorization = `Bearer ${accessToken}`
//   }
//   return config
// })

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    socket.initialize(accessToken)
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    socket.disconnect()
    localStorage.removeItem('accessToken')
    delete axios.defaults.headers.common.Authorization
  }
}

export { isValidToken, setSession }
