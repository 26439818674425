import { CircularProgress } from '@mui/material'
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress'
import { styled } from '@mui/material/styles'
import React from 'react'

const Root = styled('div')`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  min-width: 100%;
`

function Loader(props: CircularProgressProps) {
  return (
    <Root>
      <CircularProgress color="secondary" {...props} />
    </Root>
  )
}

export default Loader
