export interface CookieServiceSetPeriod {
  minutes?: number
  hours?: number
  days?: number
}

export default class CookieService {
  public static NONE = Symbol('none')

  public static set<T = string>(
    name: string,
    value: T,
    expire: CookieServiceSetPeriod = { days: 1 }
  ) {
    let expires = ''
    if (expire) {
      let date = new Date()
      date.setTime(
        date.getTime() +
          (((expire.days || 0) * 24 + (expire.hours || 0)) * 60 +
            (expire.minutes || 0)) *
            60 *
            1000
      )
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + JSON.stringify(value) + expires + '; path=/'
  }

  public static get<T>(name: string): T | Symbol {
    try {
      const nameEQ = name + '='
      const ca = document.cookie.split(';')
      for (let c of ca) {
        c = c.trim()
        if (c.indexOf(nameEQ) === 0) {
          return JSON.parse(c.substring(nameEQ.length, c.length))
        }
      }
    } catch (err) {
      console.warn(err)
    }
    return this.NONE
  }

  public static remove(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}
