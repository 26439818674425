import { UNBREAKABLE_SPACE } from '../utils/numbers'

export default {
  titles: {
    search: 'Поиск',
    changeLanguage: 'Изменить язык',
    visits: {
      history: 'ИСТОРИЯ ВИЗИТОВ',
      price: 'СТОИМОСТЬ ВИЗИТА'
    }
  },
  labels: {
    appointmentDate: 'Дата приеёма',
    comment: 'Коментарий',
    choseDepartment: 'Выберите клинику',
    timeStart: 'Начало',
    timeEnd: 'Конец',
    clinic: 'Клиника',
    cabinet: 'Кабинет',
    patient: 'Пациент',
    patientBalance: 'БАЛАНС',
    timeFromStartToEnd: 'с {start} до {end}',
    paymentDebtForDate: 'ДОЛГ за {date}',
    visit: {
      price: 'Стоимость визита',
      notRegistered: 'Визит не зафиксирован',
      paid: {
        full: 'Оплачено',
        partly: 'Оплачено частично',
        not: 'Визит не оплачен'
      }
    }
  },
  placeholders: {
    selectDoctor: 'Выберите доктора',
    selectCabinet: 'Выберите кабинет',
    enterPatientName: 'Введите ФИО пациента',
    enterInsurancePartnerName: 'Введите название страховой компании',
    timeStart: 'Время начала',
    timeEnd: 'Время конца'
  },
  hints: {
    startEnterToStartSearch: 'Начните вводить, чтобы начать поиск'
  },
  buttons: {
    ok: 'ОК',
    add: 'Добавить',
    save: 'Сохранить',
    create: 'Создать',
    edit: 'Изменить',
    find: 'Найти',
    clear: 'Очистить',
    print: 'Распечатать',
    close: 'Закрыть',
    submit: 'Подтвердить',
    cancel: 'Отменить',
    download: 'Загрузить',
    select: 'Выбрать',
    delete: 'Удалить',
    stash: 'Отменить',
    prevDay: (
      <>
        Предыдущий
        <br />
        день
      </>
    ),
    nextDay: (
      <>
        Следующий
        <br />
        день
      </>
    )
  },
  request: {
    errors: {
      unknown: 'Request failed due to unknown reason, please try again later'
    }
  },
  errors: {
    somethingWentWrong: 'Something went wrong',
    field: {
      invalid: 'Введено неправильное значение',
      required: 'Это поле обязательное',
      numberMustBeGreaterZero: 'Число должно быть больше 0',
      shouldBeGreaterThan: 'Значение должно быть больше {value}',
      dateShouldBeGreaterThanToday: 'Дата должна быть больше сегодня'
    },
    endShouldGreaterThanStart: 'Конец должен быть больше начала',
    phone: {
      isNotPossible: 'Введите правильный номер телефона'
    }
  },
  tooltips: {
    edit: 'Редактировать',
    delete: 'Удалить',
    comment: 'Коментарий',
    appointmentTime: 'Время приёма',
    appointmentDate: 'Дата приёма',
    printReceipt: 'Напечатать гарантийный талон',
    showReceipt: 'Показать гарантийный талон',
    attendingDoctor: 'Лечащий врач',
    patientBalanceExplanation:
      'Вычет суммы всех платежей пациента от суммы всех его визитов',
    patientBalanceRecompute:
      'Если вам кажеться что баланс пациента неверный вы можете запросить автоматический пересчет баланса',
    showFullInfo: 'Открыть полную информацию',
    printCheck: 'Напечатать чек',
    payments: {
      createNew: 'Создать новый платеж'
    }
  },
  components: {
    appointment: {
      buttons: {
        add: 'Добавить запись на прием'
      }
    },
    patient: {
      title: {
        debtsList: 'Список долгов ({count} шт.)'
      },
      create: {
        title: 'Создать нового пациента'
      },
      edit: {
        title: 'Редактировать пациента'
      },
      tooltips: {
        balance: {
          explanation:
            'Вычет суммы всех платежей пациента от суммы всех его визитов',
          recompute:
            'Если вам кажеться что баланс пациента неверный вы можете запросить автоматический пересчет баланса'
        }
      },
      labels: {
        debts: {
          list: 'Список долгов ({count} шт.)',
          count: 'Учитывать'
        }
      },
      fields: {
        phonesList: 'Список телефонов'
      },
      hints: {
        maybeYouHaveSearchThisPatient: 'Возможно вы искали этого пациента?'
      }
    },
    payment: {
      title: {
        many: 'Платежи',
        canceled: 'Отменные платежи'
      },
      create: {
        title: 'Создать платеж'
      },
      labels: {
        sum: 'Сума',
        paymentSum: 'Сумма платежа',
        canceled: 'Отменено',
        canceledTrue: 'Да',
        canceledFalse: 'Нет'
      },
      tooltips: {
        cancel: 'Отменить платеж',
        printReceipt: 'Напечатать талон на этот платеж'
      }
    },
    prepayment: {
      create: {
        title: 'Создать предоплату'
      },
      hints: {
        alreadyPayed: 'Невозможно оплатить визит: Уже оплачен'
      }
    },
    paymentMethod: {
      title: {
        one: 'Платежный метод',
        many: 'Платежные методы'
      }
    },
    price: {
      title: {
        one: 'Цена',
        multiple: 'Цены'
      },
      create: {
        discount: {
          title: 'Создать скидку',
          type: {
            title: 'Тип скидки',
            amountOff: 'Фиксированная сумма',
            percentOff: 'Процент от стоимости (от 0 до 100)'
          }
        }
      },
      labels: {
        priceFor: 'за {count} {unit}',
        valueInPriceList: 'Цена в прайс-листе',
        valueRange: 'от {from} до {to}'
      },
      fields: {
        description: 'Описание'
      }
    },
    insurancePartner: {
      title: {
        one: 'Страховая компания'
      },
      fields: {
        number: 'Номер полиса',
        limit: 'Начальный лимит',
        balance: 'Остаток'
      }
    },
    insurancePolicy: {
      title: {
        one: 'Страховой полис',
        many: 'Страховые полисы',
        ended: 'Оконченые страховые полисы',
        confirmExtend: 'Подтвердите расширение страхового полиса'
      },
      create: {
        title: 'Создать запис на приём',
        one: 'Создать страховой полис',
        new: 'Создать новый'
      },
      edit: {
        title: 'Редактировать запись на приём',
        one: 'Редактировать страховой полис'
      },
      fields: {
        number: 'Номер страхового полиса',
        limit: 'Лимит полиса',
        extendable: 'Расширяемый',
        endDate: 'Дата конец полиса'
      },
      labels: {
        remain: 'ост. {value}',
        remainFull: 'остаток {value}',
        dueToDate: 'до {date}',
        areYouSureToExtendLimit:
          'Вы действительно хотите расширить лимит полиса с {from} до {to}',
        doExtendingPolicyLimit: 'Расширение лимита...',
        maybeYouWantedToFoundThisPolicy: 'Возможно вы искали этот полис?',
        youCantCreatePaymentMoreThanBalance: `Вы не можете создать платеж на сумму больше чем баланс полиса:${UNBREAKABLE_SPACE}{value}`
      }
    },
    dentalFormula: {
      create: {
        title: 'Создать новую зубную формулу',
        new: 'Создать новую'
      }
    }
  },
  pages: {
    VisitsPage: {
      welcome: 'Добро пожаловать в {application}!',
      welcomeSubtitle: 'Виберите запись на приём в списке слева',
      OR: 'ИЛИ'
    }
  },
  dialogs: {
    createPayment: {
      labels: {
        sumToPay: 'Сумма к оплате',
        selectPaymentMethod: '⬇️ Выберите 1 или более способов оплаты ⬇️️',
        selectNewPaymentMethod: 'Виберіть метод оплати',
        paymentsList: 'Список оплат',
        addComment: 'Добавить коментарий к платежу'
      },
      buttons: {
        addPayment: 'Добавить оплату'
      },
      fields: {
        comment: 'Коментарий'
      },
      sendSMS: {
        withRequisites: 'Отправить СМС с реквизитами',
        selectPhone: 'Выберите на какой номер телефона отправить СМС'
      }
    }
  }
}
