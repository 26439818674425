"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getComposedDetails = void 0;
function getComposedDetails(navigatorDetails, parser, canvas, fallback) {
    var parserResult = parser.getResult();
    return {
        language: getLanguage(navigatorDetails, fallback),
        deviceName: getDeviceName(parserResult),
        browser: getBrowser(navigatorDetails, parserResult, fallback),
        os: getOs(parserResult, fallback),
        hardware: getHardware(navigatorDetails, parserResult, canvas, fallback),
        platform: getPlatform(parserResult, fallback),
    };
}
exports.getComposedDetails = getComposedDetails;
function getDeviceName(browserDetails) {
    var devType = getDeviceType(browserDetails).replace(/\b[a-z]/g, function (x) { return x.toUpperCase(); });
    var modelString = browserDetails.device.vendor ? browserDetails.device.vendor : '';
    modelString += browserDetails.device.model
        ? (modelString.length > 0 ? ' ' : '') +
            (browserDetails.device.model.length > 2 ? browserDetails.device.model : devType)
        : '';
    var os = browserDetails.os.name ? browserDetails.os.name : '';
    modelString = modelString.length > 0 ? modelString : os + (os.length > 0 ? ' ' : '') + devType;
    return modelString;
}
function getLanguage(allDetails, fallback) {
    return withFallback(allDetails.language, fallback);
}
function getBrowser(allDetails, browserDetails, fallback) {
    return {
        vendor: allDetails.vendor,
        name: withFallback(browserDetails.browser.name, fallback),
        version: withFallback(browserDetails.browser.version, fallback),
        engine: browserDetails.engine.name
            ? browserDetails.engine.name + (browserDetails.engine.version ? ' ' + browserDetails.engine.version : '')
            : fallback,
    };
}
function getOs(browserDetails, fallback) {
    return {
        vendor: getOsVendor(browserDetails, fallback),
        name: withFallback(browserDetails.os.name, fallback),
        version: withFallback(browserDetails.os.version, '0.0'),
    };
}
function getOsVendor(browserDetails, fallback) {
    var osToVendorMap = {
        windows: 'Microsoft',
        ios: 'Apple',
        mac: 'Apple',
        android: 'Google',
    };
    var osVendor;
    if (browserDetails.os.name) {
        var osname_1 = browserDetails.os.name;
        Object.entries(osToVendorMap).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (osname_1.toLowerCase().includes(key)) {
                osVendor = value;
            }
        });
        if (osVendor) {
            return osVendor;
        }
        else {
            return osname_1;
        }
    }
    else {
        return fallback;
    }
}
function getHardware(allDetails, browserDetails, canvas, fallback) {
    return {
        cpu: {
            name: '',
            architecture: withFallback(browserDetails.cpu.architecture, fallback),
            type: getOsType(browserDetails, fallback),
            cores: allDetails.hardwareConcurrency,
        },
        ram: getRam(allDetails),
        screen: getScreen(allDetails),
        gpu: getGPU(canvas, fallback),
    };
}
function getRam(allDetails) {
    var ram = allDetails.deviceMemory;
    ram = ram ? ram : 0;
    return {
        size: ram,
        unit: 'GB',
    };
}
function getOsType(browserDetails, fallback) {
    if (browserDetails.cpu.architecture) {
        if (browserDetails.cpu.architecture.toLowerCase().includes('64')) {
            return '64 Bit';
        }
        else {
            return '32 Bit';
        }
    }
    else {
        return fallback;
    }
}
function getGPU(canvas, fallback) {
    var gl;
    var gpuName;
    try {
        gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    }
    catch (e) {
        gpuName = undefined;
    }
    if (gl) {
        var gpu = gl.getParameter(gl.getExtension('WEBGL_debug_renderer_info').UNMASKED_RENDERER_WEBGL);
        gpuName = parseGpuInfo(gpu);
    }
    return {
        vendor: gpuName ? gpuName.slice(0, gpuName.indexOf(' ')) : fallback,
        name: gpuName ? gpuName : fallback,
    };
}
function extractValue(reg, str) {
    var matches = str.match(reg);
    return matches && matches[0];
}
function parseGpuInfo(renderer) {
    return (extractValue(/(NVIDIA|AMD|Intel)\D*\d*\S*/, renderer) || renderer).trim();
}
function getScreen(allDetails) {
    return {
        height: window.screen.height,
        width: window.screen.width,
        hasTouch: allDetails.maxTouchPoints > 0,
        touchPoints: allDetails.maxTouchPoints ? allDetails.maxTouchPoints : 0,
    };
}
function getPlatform(browserDetails, fallback) {
    return {
        model: withFallback(browserDetails.device.model, fallback),
        type: getDeviceType(browserDetails),
        vendor: withFallback(browserDetails.device.vendor, fallback),
    };
}
function getDeviceType(browserDetails) {
    if (browserDetails.device.type) {
        return browserDetails.device.type;
    }
    var ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return 'mobile';
    }
    return 'desktop';
}
function withFallback(detail, fallback) {
    return detail ? detail : fallback;
}
