import { Avatar, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import getDeviceDetails from 'device-details'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import { config } from '../../config'
import { useApplication } from '../../contexts/ApplicationContext'
import LoginPage from './LoginPage'
import EuropeMap from './LoginPage/EuropeMap'

const Brand = styled('img')`
  //fill: white;
  width: auto;
  max-height: 200px;
  min-height: 64px;
  margin-bottom: 32px;
  max-width: 60%;
`

const WelcomeText = styled('h2')`
  z-index: 100;
  position: relative;
  font-weight: bold;

  font-style: normal;
  color: #fff;

  ${(props) => props.theme.breakpoints.up('xl')} {
    font-size: 60px;
    line-height: 68px;
    margin-top: 50px;
    margin-bottom: 120px;
  }

  font-size: 36px;
  line-height: 42px;
  margin-top: 20px;
  margin-bottom: 60px;
`

// const Wrapper = styled(Card)`
//   min-width: 300px;
//   padding: ${(props) => props.theme.spacing(6)};
//
//   ${(props) => props.theme.breakpoints.up('md')} {
//     padding: ${(props) =>
//       props.theme.spacing(2) +
//       ' ' +
//       props.theme.spacing(10) +
//       ' ' +
//       props.theme.spacing(10)};
//   }
// `
const Container = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
`

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  flex: 0;

  background: white;
  height: 100%;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-bottom: 300px;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 40px 40px;
    padding-bottom: 200px;
    min-width: 480px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    justify-content: flex-start;
    padding: 40px 16px;
    min-width: 100%;
    width: 100%;
  }
`

const SideComponent = styled('div')`
  display: flex;
  align-items: center;
  padding: 64px;

  position: relative;
  flex: 1;

  background: #1f2937;
  //background-size: 98%;
  //background-position: center;
  //background-repeat: no-repeat;
  //background-image: url('/static/img/placeholder-v2.png');
`

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`

function SignIn() {
  const application = useApplication()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const deviceDetails = getDeviceDetails()
  return (
    <React.Fragment>
      <Helmet title="Log In" />
      <Container>
        <Wrapper>
          <Brand src={application.bigLogoUrl} />
          {/*<WelcomeText>Welcome back!</WelcomeText>*/}
          {/*<SignInComponent />*/}
          <LoginPage defaultSelectedTab={isMdUp ? 0 : 1} />
          <span>
            By signing up, you agree to the Terms of Service.
            <br />
            You also agree to receive emails from Modern Clinic.
          </span>
        </Wrapper>
        {isMdUp && (
          <SideComponent>
            {!deviceDetails.browser.name.includes('Safari') && (
              <div
                className="absolute z-0 top-0 left-0 right-0 bottom-0"
                style={{ height: '100vh', opacity: 0.5 }}
              >
                <EuropeMap />
              </div>
            )}
            {/*<div*/}
            {/*  className="absolute z-10 top-0 left-0 right-0 bottom-0"*/}
            {/*  style={{ height: '100vh', background: 'rgba(31, 41, 55, 0.8)' }}*/}
            {/*/>*/}
            {config.isProduction && false && (
              <WelcomeText>
                Over 1,000 doctors
                <br />
                worldwide chose
                <br />
                <b
                  style={{
                    fontWeight: 900,
                    color: theme.palette.primary.light
                  }}
                >
                  Modern Clinic
                </b>
              </WelcomeText>
            )}
          </SideComponent>
        )}
      </Container>
    </React.Fragment>
  )
}

export default SignIn
