import React, { ReactNode, useState } from 'react'

import IPureDialogComponent, { DialogType } from './component'
import { IDialogComponentProps } from './types'

type StateType = Record<number, ReactNode>
let __globalSetState: React.Dispatch<React.SetStateAction<StateType>> =
  (() => {}) as any

export function openDialog<P = {}>(props: IDialogComponentProps<P>) {
  return new Promise<void>((resolve) => {
    __globalSetState((components: StateType) => {
      const nextIndex = Object.keys(components).length
      return {
        ...components,
        [nextIndex]: (
          <IPureDialogComponent
            {...props}
            type={DialogType.DIALOG}
            close={() => {
              __globalSetState((state: StateType) => {
                const _state = { ...state }
                delete _state[nextIndex]
                return _state
              })

              resolve()
            }}
            index={nextIndex}
            key={nextIndex}
          />
        )
      }
    })
  })
}

export function openSidePanel<P = {}>(props: IDialogComponentProps<P>) {
  return new Promise<void>((resolve) => {
    __globalSetState((components: StateType) => {
      const nextIndex = Object.keys(components).length
      return {
        ...components,
        [nextIndex]: (
          <IPureDialogComponent
            {...props}
            type={DialogType.SIDE_PANEL}
            close={() => {
              __globalSetState((state: StateType) => {
                const _state = { ...state }
                delete _state[nextIndex]
                return _state
              })

              resolve()
            }}
            index={nextIndex}
            key={nextIndex}
          />
        )
      }
    })
  })
}

function DialogsStore() {
  const [state, setState] = useState<StateType>({})
  __globalSetState = setState
  return <React.Fragment children={Object.values(state)} />
}

export default DialogsStore
