import { useQuery, UseQueryOptions } from 'react-query'
import { QueryFunctionContext } from 'react-query/types/core/types'

import { DepartmentModel } from '../../api/generated/models.types'
import { config } from '../../config'

export const KEY = ['v1', 'auth', 'departments']

function queryFn(context: QueryFunctionContext): Promise<DepartmentModel[]> {
  return fetch(`${config.backendUrl}/v1/auth/departments`, {
    headers: { 'X-Key': config.backendApiKey! }
    // signal: context.signal
  }).then((res) => res.json())
}

export const useLoginDepartmentsDataQuery = (
  queryOptions?: UseQueryOptions<DepartmentModel[], Error>
) =>
  useQuery<DepartmentModel[], Error>(
    ['v1', 'auth', 'departments'],
    queryFn,
    queryOptions
  )
