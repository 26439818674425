import React, { useContext } from 'react'

import { ramda } from '../utils/imports'
import { DeepPartial } from '../utils/types'

interface SettingsType {
  dayView: {
    showAllTabs: boolean
    groupBy: 'cabinet' | 'doctor'
  }
}

type SetSettingsValue =
  | DeepPartial<SettingsType>
  | ((prevState: SettingsType) => DeepPartial<SettingsType>)

interface SettingsContextType {
  settings: SettingsType
  setSettings: (value: SetSettingsValue) => void
}

const initialState: SettingsType = {
  dayView: {
    showAllTabs: false,
    groupBy: 'cabinet'
  }
}

const SettingsContext = React.createContext<SettingsContextType>({
  settings: initialState,
  setSettings() {}
})

type SettingsProviderProps = {
  children: React.ReactNode
}

function SettingsProvider({ children }: SettingsProviderProps) {
  const initializeState = () => {
    const stored = localStorage.getItem('settings')
    return stored ? JSON.parse(stored) : initialState
  }

  const [settings, _setSettings] = React.useState<SettingsType>(
    initializeState()
  )

  const setSettings = (value: SetSettingsValue) => {
    const _settings = ramda.mergeDeepRight(
      settings,
      typeof value === 'function' ? value(settings) : value
    )
    localStorage.setItem('settings', JSON.stringify(_settings))
    _setSettings(_settings)
  }

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  )
}

function useSettings(): SettingsContextType {
  return useContext(SettingsContext)
}

export { SettingsProvider, useSettings }
