"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ua_parser_js_1 = require("ua-parser-js");
var utils_1 = require("./utils");
function getDeviceDetails(options) {
    var navigatorDetails = (options === null || options === void 0 ? void 0 : options.navigator) ? options.navigator : window.navigator;
    var fallback = (options === null || options === void 0 ? void 0 : options.fallback) ? options.fallback : 'Unknown';
    var parser = (options === null || options === void 0 ? void 0 : options.navigator) ? new ua_parser_js_1.UAParser(options.navigator.userAgent) : new ua_parser_js_1.UAParser();
    var canvas = (options === null || options === void 0 ? void 0 : options.canvas) ? options.canvas : document.createElement('canvas');
    return utils_1.getComposedDetails(navigatorDetails, parser, canvas, fallback);
}
exports.default = getDeviceDetails;
module.exports = getDeviceDetails;
