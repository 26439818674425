import { LoadingButton } from '@mui/lab'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Paper,
  Slide
} from '@mui/material'
import jsonStableStringify from 'json-stable-stringify'
import React from 'react'

import IBaseDialog from '../../components/mui/IBaseDialog'
import { I18NextContext } from '../../contexts/I18NextContext'
import { getTranslations } from '../../hooks/useTranslation'
import { IDialogComponentProps } from './types'

type ReactComponent<T = any> = (props: T) => JSX.Element

export enum DialogType {
  DIALOG,
  SIDE_PANEL
}

type Props<P> = IDialogComponentProps<P> & {
  index: number
  close: (index: number) => void
  type: DialogType
}

const DialogClosedSymbol = Symbol('DialogClosedSymbol')
const PaperSlideComponentTimeout = {
  enter: 500,
  exit: 250
}

function PaperSlideComponent({ in: transitionIn, type, ...props }: any) {
  const TransitionComponent = type === DialogType.SIDE_PANEL ? Slide : Fade
  return (
    <TransitionComponent
      in={!!transitionIn}
      // direction={transitionIn ? 'left' : 'right'}
      direction="left"
      timeout={PaperSlideComponentTimeout}
    >
      <Paper {...props} />
    </TransitionComponent>
  )
}

class IPureDialogComponent<P> extends React.PureComponent<Props<P>> {
  static contextType = I18NextContext
  context!: React.ContextType<typeof I18NextContext>

  customProps: Record<string, any> = {}
  state: Record<string | symbol, any> = {}

  constructor(props: Props<P>) {
    super(props)

    this.state = Object.assign(
      {
        ...Object.fromEntries(
          (this.props.actions || []).map((it) => [`button@${it.id}`, true])
        )
      },
      props.componentState || {},
      this.state
    )
  }

  get isClosed() {
    return !!this.state[DialogClosedSymbol]
  }

  close = async () => {
    this.setState({ [DialogClosedSymbol]: true })
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        this.props.close(this.props.index)
        resolve()
      }, PaperSlideComponentTimeout.exit)
    })
  }

  setMultipleState = (props: Record<string, any>) => {
    this.setState((prevState) => ({
      ...(prevState || {}),
      ...(props || {})
    }))
  }

  onCustomProps = (key: string, value: P) => {
    if (value) {
      this.customProps[key] = value
    } else {
      delete this.customProps[key]
    }
  }

  onStateValue = (key: string | null, defaultValue?: any) => {
    const stateValue = key ? this.state[key] : this.state
    if (typeof stateValue === 'undefined') {
      return defaultValue
    }
    return stateValue
  }

  onState = (key: string | null, defaultValue?: any) => {
    if (!key) {
      return [this.state, this.setState]
    }

    let stateValue = this.state[key]
    if (typeof stateValue === 'undefined') {
      stateValue = defaultValue
    }
    return [
      stateValue,
      (value: string) => {
        // if (typeof value === 'function') {
        //     this.setState((prevState: Record<string, any>) => ({
        //         ...prevState, [key]: (value as Function)(prevState[key] || defaultValue),
        //     }))
        // } else {
        this.setState((prevState) => ({
          ...prevState,
          [key]: value
        }))
        // }
      }
    ]
  }

  onButtonDisabledState = (key: string | null) => {
    return this.onState(`button@disabled@${key}`)
  }

  onButtonLoadingState = (key: string | null) => {
    return this.onState(`button@loading@${key}`)
  }

  render() {
    const t = getTranslations(this.context)

    let ChildComponent = (this.props.Component ||
      (() => this.props.children || null)) as ReactComponent
    const componentProps = Object.assign(this.props.componentProps || {}, {
      key: jsonStableStringify(this.props.componentProps || {}),
      setMultipleState: this.setMultipleState,
      setCustomProps: this.onCustomProps,
      getStateValue: this.onStateValue,
      customProps: this.customProps,
      getButtonDisabledState: this.onButtonDisabledState,
      getButtonLoadingState: this.onButtonLoadingState,
      getState: this.onState,
      close: this.close
    })
    const dialogProps = this.props.dialogProps || {}

    return (
      <IBaseDialog
        open={true}
        blurBackdrop={this.props.blurBackdrop}
        onClose={this.props.disableClose ? undefined : this.close}
        {...dialogProps}
        fullScreen={
          this.props.forceDisableFullScreen
            ? false
            : this.props.dialogProps?.fullScreen
        }
        scroll="paper"
        style={{
          ...(dialogProps?.style || {}),
          zIndex: 1300 + this.props.index
        }}
        PaperComponent={PaperSlideComponent}
        PaperProps={
          {
            in: !this.isClosed,
            type: this.props.type,
            ...(dialogProps?.PaperProps || {}),
            style: {
              ...(dialogProps?.PaperProps?.style || {}),
              ...(this.props.type === DialogType.SIDE_PANEL
                ? {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    margin: 0,
                    maxHeight: 'unset',
                    borderRadius: 0
                  }
                : {})
            }
          } as any
        }
      >
        {this.props.title && (
          <>
            <DialogTitle>
              {t(this.props.title as any)}
              {this.props.subtitle && (
                <div className="text-gray-500 text-sm">
                  {t(this.props.subtitle as any)}
                </div>
              )}
            </DialogTitle>
            <Divider />
          </>
        )}
        {this.props.noDialogContentWrapper ? (
          <ChildComponent {...componentProps} />
        ) : (
          <DialogContent {...(this.props.containerProps || {})}>
            <ChildComponent {...componentProps} />
          </DialogContent>
        )}
        {this.props.actions && (
          <DialogActions {...(this.props.actionsContainerProps || {})}>
            {this.props.actions.map((action, i) => {
              const disabled =
                this.state[`button@disabled@${action.id}`] === true
              const loading = this.state[`button@loading@${action.id}`] === true
              const children = (
                <LoadingButton
                  key={i}
                  loading={loading}
                  color={action.color}
                  variant={action.variant}
                  className={`mx-2 ${
                    action.className || ''
                    // enabled // TODO
                    //   ? ``
                    //   : `bg-gray-200 hover:bg-gray-400 text-gray-500`
                  }`}
                  disabled={disabled}
                  onClick={() => {
                    try {
                      action.onClick({
                        props: this.props,
                        state: this.state,
                        close: this.close,
                        customProps: this.customProps,
                        componentProps
                      })
                    } finally {
                      if (action.closeDialog) {
                        this.close()
                      }
                    }
                  }}
                >
                  {action.title &&
                    (typeof action.title === 'string'
                      ? t(action.title as any)
                      : action.title)}
                </LoadingButton>
              )
              return action.Component
                ? action.Component({ children })
                : children
            })}
          </DialogActions>
        )}
      </IBaseDialog>
    )
  }
}

export default IPureDialogComponent
