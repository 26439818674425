import { IModelsValues } from './models'
export interface ModelRelations {
  name: string
  relation: string
  inversedBy?: string
  mappedBy?: Omit<ModelRelations, 'mappedBy'> & { inversedBy: string }
  target: IModelsValues
}

export const ModelsRelations: Record<IModelsValues, ModelRelations[]> = {
  user: [
    {
      name: 'role',
      relation: 'manyToOne',
      target: 'role',
      inversedBy: 'users',
      mappedBy: {
        name: 'users',
        relation: 'oneToMany',
        target: 'user',
        inversedBy: 'role'
      }
    },
    {
      name: 'defaultCabinet',
      relation: 'manyToOne',
      target: 'cabinet',
      inversedBy: 'users',
      mappedBy: {
        name: 'users',
        relation: 'oneToMany',
        target: 'user',
        inversedBy: 'defaultCabinet'
      }
    },
    {
      name: 'defaultDepartment',
      relation: 'manyToOne',
      target: 'department',
      inversedBy: 'users',
      mappedBy: {
        name: 'users',
        relation: 'oneToMany',
        target: 'user',
        inversedBy: 'defaultDepartment'
      }
    },
    {
      name: 'managed',
      relation: 'manyToMany',
      target: 'department',
      inversedBy: 'managers',
      mappedBy: {
        name: 'managers',
        relation: 'manyToMany',
        target: 'user',
        inversedBy: 'managed'
      }
    },
    {
      name: 'application',
      relation: 'manyToOne',
      target: 'application',
      inversedBy: 'users',
      mappedBy: {
        name: 'users',
        relation: 'oneToMany',
        target: 'user',
        inversedBy: 'application'
      }
    }
  ],
  role: [
    {
      name: 'users',
      relation: 'oneToMany',
      target: 'user',
      inversedBy: 'role',
      mappedBy: {
        name: 'role',
        relation: 'manyToOne',
        target: 'role',
        inversedBy: 'users'
      }
    }
  ],
  action: [
    {
      name: 'user',
      relation: 'oneToOne',
      target: 'user'
    }
  ],
  'api-key': [],
  application: [],
  'application-plugins': [],
  appointment: [
    {
      name: 'cabinet',
      relation: 'oneToOne',
      target: 'cabinet'
    },
    {
      name: 'doctor',
      relation: 'oneToOne',
      target: 'user'
    },
    {
      name: 'patient',
      relation: 'manyToOne',
      target: 'patient',
      inversedBy: 'appointments',
      mappedBy: {
        name: 'appointments',
        relation: 'oneToMany',
        target: 'appointment',
        inversedBy: 'patient'
      }
    },
    {
      name: 'visit',
      relation: 'oneToOne',
      target: 'visit',
      inversedBy: 'appointment',
      mappedBy: {
        name: 'appointment',
        relation: 'oneToOne',
        target: 'appointment',
        inversedBy: 'visit'
      }
    },
    {
      name: 'payments',
      relation: 'oneToMany',
      target: 'payment',
      inversedBy: 'appointment',
      mappedBy: {
        name: 'appointment',
        relation: 'manyToOne',
        target: 'appointment',
        inversedBy: 'payments'
      }
    }
  ],
  assets: [],
  'billing-feature': [],
  'billing-plan': [],
  'billing-subscription': [],
  cabinet: [
    {
      name: 'department',
      relation: 'manyToOne',
      target: 'department',
      inversedBy: 'cabinets',
      mappedBy: {
        name: 'cabinets',
        relation: 'oneToMany',
        target: 'cabinet',
        inversedBy: 'department'
      }
    },
    {
      name: 'defaultDoctor',
      relation: 'oneToOne',
      target: 'user'
    },
    {
      name: 'users',
      relation: 'oneToMany',
      target: 'user',
      inversedBy: 'defaultCabinet',
      mappedBy: {
        name: 'defaultCabinet',
        relation: 'manyToOne',
        target: 'cabinet',
        inversedBy: 'users'
      }
    }
  ],
  cashier: [],
  'custom-routes': [],
  'dental-formula': [],
  department: [
    {
      name: 'cabinets',
      relation: 'oneToMany',
      target: 'cabinet',
      inversedBy: 'department',
      mappedBy: {
        name: 'department',
        relation: 'manyToOne',
        target: 'department',
        inversedBy: 'cabinets'
      }
    },
    {
      name: 'users',
      relation: 'oneToMany',
      target: 'user',
      inversedBy: 'defaultDepartment',
      mappedBy: {
        name: 'defaultDepartment',
        relation: 'manyToOne',
        target: 'department',
        inversedBy: 'users'
      }
    },
    {
      name: 'managers',
      relation: 'manyToMany',
      target: 'user',
      inversedBy: 'managed',
      mappedBy: {
        name: 'managed',
        relation: 'manyToMany',
        target: 'department',
        inversedBy: 'managers'
      }
    },
    {
      name: 'application',
      relation: 'manyToOne',
      target: 'application',
      inversedBy: 'departments',
      mappedBy: {
        name: 'departments',
        relation: 'oneToMany',
        target: 'department',
        inversedBy: 'application'
      }
    }
  ],
  'diagnosis-sample': [],
  'diagnosis-sample-group': [],
  'diagnosis-sample-procedure': [],
  'fiscal-configuration': [],
  'fiscal-report-rule': [],
  'insurance-partner': [],
  'insurance-policy': [],
  notification: [
    {
      name: 'users',
      relation: 'oneToMany',
      target: 'user'
    }
  ],
  patient: [
    {
      name: 'appointments',
      relation: 'oneToMany',
      target: 'appointment',
      inversedBy: 'patient',
      mappedBy: {
        name: 'patient',
        relation: 'manyToOne',
        target: 'patient',
        inversedBy: 'appointments'
      }
    },
    {
      name: 'payments',
      relation: 'oneToMany',
      target: 'payment',
      inversedBy: 'patient',
      mappedBy: {
        name: 'patient',
        relation: 'manyToOne',
        target: 'patient',
        inversedBy: 'payments'
      }
    },
    {
      name: 'departments',
      relation: 'manyToMany',
      target: 'department'
    }
  ],
  payment: [
    {
      name: 'patient',
      relation: 'manyToOne',
      target: 'patient',
      inversedBy: 'payments',
      mappedBy: {
        name: 'payments',
        relation: 'oneToMany',
        target: 'payment',
        inversedBy: 'patient'
      }
    },
    {
      name: 'appointment',
      relation: 'manyToOne',
      target: 'appointment',
      inversedBy: 'payments',
      mappedBy: {
        name: 'payments',
        relation: 'oneToMany',
        target: 'payment',
        inversedBy: 'appointment'
      }
    }
  ],
  'payment-method': [],
  price: [
    {
      name: 'application',
      relation: 'manyToOne',
      target: 'application',
      inversedBy: 'prices',
      mappedBy: {
        name: 'prices',
        relation: 'oneToMany',
        target: 'price',
        inversedBy: 'application'
      }
    }
  ],
  'price-category': [],
  'price-currency': [],
  public: [],
  'rfid-device': [],
  'rfid-tag': [],
  session: [],
  shift: [],
  'store-category': [],
  'store-item': [],
  'store-storage': [],
  'store-transaction': [],
  'tech-executor': [],
  'tech-price': [],
  'tech-work': [],
  'treatment-plan': [],
  v1: [],
  vacation: [
    {
      name: 'user',
      relation: 'oneToOne',
      target: 'user'
    },
    {
      name: 'shift',
      relation: 'oneToOne',
      target: 'shift'
    }
  ],
  visit: [
    {
      name: 'procedures',
      relation: 'manyToOne',
      target: 'price'
    },
    {
      name: 'appointment',
      relation: 'oneToOne',
      target: 'appointment',
      inversedBy: 'visit',
      mappedBy: {
        name: 'visit',
        relation: 'oneToOne',
        target: 'visit',
        inversedBy: 'appointment'
      }
    },
    {
      name: 'history',
      relation: 'oneToMany',
      target: 'visit',
      inversedBy: 'actual',
      mappedBy: {
        name: 'actual',
        relation: 'manyToOne',
        target: 'visit',
        inversedBy: 'history'
      }
    },
    {
      name: 'actual',
      relation: 'manyToOne',
      target: 'visit',
      inversedBy: 'history',
      mappedBy: {
        name: 'history',
        relation: 'oneToMany',
        target: 'visit',
        inversedBy: 'actual'
      }
    }
  ],
  'web-article': [],
  'web-contact': [],
  'web-header': [],
  'web-main-page': [],
  'web-photogallery': [],
  'web-price': [],
  'web-privacy-policy': [],
  'web-request': [],
  'web-special-offer': [],
  'work-time': [],
  'youtube-credential': []
}
