export type IAnyDate = Date | IDate | string | number | null | undefined
export type IAnyTime = ITime | string | number | null | undefined

export interface ITime {
  hour: number
  minutes: number
}

export interface IDate {
  year: number
  month: number
  day: number
}

export function isIDate(data: any): data is IDate {
  return (
    data &&
    typeof data === 'object' &&
    data.year &&
    typeof data.year === 'number' &&
    data.month &&
    typeof data.month === 'number' &&
    data.day &&
    typeof data.day === 'number'
  )
}

export function isITime(time: any): time is ITime {
  return (
    time &&
    typeof time === 'object' &&
    time.hour &&
    typeof time.hour === 'number' &&
    time.minutes &&
    typeof time.minutes === 'number'
  )
}
