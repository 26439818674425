import { CssBaseline } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

import GlobalStyle from '../components/GlobalStyle'

const Root = styled('div')`
  max-width: 520px;
  margin: -32px auto 0;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`

export default function Auth({ children }: { children: React.ReactNode }) {
  // useEffect(() => {
  //   queryClient.clear()
  // }, [])
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  )
}
