import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'

import { Models } from '../api/generated/models'
import { CompleteDepartmentModel } from '../api/generated/models.types'
import { useFindModels } from '../hooks/data/use-find-models-query'
import useAuth from '../hooks/useAuth'

interface State {
  selected: CompleteDepartmentModel[] | null
  setSelected: React.Dispatch<
    React.SetStateAction<CompleteDepartmentModel[] | null>
  >
}

const initialState: State = {
  selected: null,
  setSelected: () => {}
}

const AdminSelectedDepartmentContext = createContext(initialState)
const LC_STORAGE_KEY = 'AdminSelectedDepartmentContextState'
const valueOnLoad = window.localStorage.getItem(LC_STORAGE_KEY)

export default function AdminSelectedDepartmentProvider({
  children
}: {
  children: ReactNode
}) {
  const { isAuthenticated } = useAuth()
  const allDepartments = useFindModels(
    Models.department,
    {},
    {
      enabled: isAuthenticated,
      retry: 1
    }
  )
  const [selected, setSelected] = useState<CompleteDepartmentModel[] | null>(
    null
  )
  useEffect(() => {
    try {
      const item = valueOnLoad
      if (!item) return
      const id = JSON.parse(item)
      const found = Array.isArray(id)
        ? id.map((id) => allDepartments.find((it) => it.id === id))
        : [allDepartments.find((it) => it.id === id)]
      if (found) {
        setSelected(found.filter((it) => !!it) as any)
      }
    } catch (err) {
      console.warn(err)
    }
  }, [allDepartments])
  useEffect(() => {
    if (selected && selected.length > 0) {
      window.localStorage.setItem(
        LC_STORAGE_KEY,
        JSON.stringify(selected.map((it) => it.id))
      )
    } else {
      window.localStorage.removeItem(LC_STORAGE_KEY)
    }
  }, [selected])
  return (
    <AdminSelectedDepartmentContext.Provider value={{ selected, setSelected }}>
      {children}
    </AdminSelectedDepartmentContext.Provider>
  )
}

function useAdminOverrideDepartment() {
  return useContext(AdminSelectedDepartmentContext) || {}
}

// const QUERY_KEY = ['admin', 'useAdminOverrideDepartment']
// function useNewAdminOverrideDepartment() {
//   const queryClient = useQueryClient()
//   const allDepartments = useFindModels(
//     Models.department,
//     {},
//     {
//       retry: 1
//     }
//   )
//   const selected = useQuery<CompleteDepartmentModel | null>(QUERY_KEY, () => {
//     return null
//   })
//   useEffect(() => {
//     try {
//       const item = valueOnLoad
//       if (!item) return
//       const id = JSON.parse(item)
//       const found = allDepartments.find((it) => it.id === id)
//       if (found) {
//         queryClient.setQueriesData(QUERY_KEY, found)
//       }
//     } catch (err) {
//       console.warn(err)
//     }
//   }, [allDepartments, queryClient])
//   useEffect(() => {
//     if (selected.data && selected.data.id) {
//       window.localStorage.setItem(
//         LC_STORAGE_KEY,
//         JSON.stringify(selected.data.id)
//       )
//     } else {
//       window.localStorage.removeItem(LC_STORAGE_KEY)
//     }
//   }, [selected])
//
//   return useMemo(
//     () => ({
//       selected: selected.data || null,
//       setSelected: (value: CompleteDepartmentModel | null) => {
//         queryClient.setQueryData(QUERY_KEY, value)
//       }
//     }),
//     [queryClient, selected.data]
//   )
// }

export { AdminSelectedDepartmentContext, useAdminOverrideDepartment }
