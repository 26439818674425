// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as Sentry from '@sentry/react'
// Add the Performance Monitoring library
import { getAnalytics } from 'firebase/analytics'
import * as firebase from 'firebase/app'
import { getPerformance } from 'firebase/performance'

import { config } from '../../config'
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import FirebaseConfig from './FirebaseConfig'

abstract class IFirebaseService {
  abstract get isProduction(): boolean
  // abstract logEvent(name: string, info: Record<string, any>): void

  reportError(error: any, info: Record<string, any>): void {
    Sentry.captureException(error.toString(), info)
    // this.logEvent('error', {
    //   location: window.location.toString(),
    //   errorStr: error.toString(),
    //   error: JSON.stringify(error),
    //   info: JSON.stringify(info)
    // })
  }
}

class LocalFirebaseServiceImpl extends IFirebaseService {
  get isProduction() {
    return false
  }

  // logEvent(name: string, info: Record<string, any>): void {
  //   console.log(`[FIREBASE] ${name}`)
  //   console.log(`[FIREBASE] ${JSON.stringify(info, null, '\t')}`)
  // }
}

class ProductionFirebaseServiceImpl extends IFirebaseService {
  private readonly app = firebase.initializeApp(FirebaseConfig)

  private get analytics() {
    return getAnalytics(this.app)
  }

  private get performance() {
    return getPerformance(this.app)
  }

  get isProduction() {
    return true
  }

  constructor() {
    super()

    firebase.setLogLevel('info')
    this.app.automaticDataCollectionEnabled = true
    getPerformance()
    getAnalytics()
    // this.analytics.app.automaticDataCollectionEnabled = true
    // this.performance.dataCollectionEnabled = true

    // SharedUser.subscribe(() => {
    //     if (SharedUser.model && SharedUser.model.user) {
    //         this.analytics.setUserId(SharedUser.model.user.id.toString())
    //     }
    // })
  }

  // logEvent(name: string, info: Record<string, any>): void {
  //   this.analytics.logEvent(name, info)
  // }
}

const FirebaseService = config.isProduction
  ? new ProductionFirebaseServiceImpl()
  : new LocalFirebaseServiceImpl()
export default FirebaseService
