import { useQuery, UseQueryOptions } from 'react-query'
import { QueryFunctionContext } from 'react-query/types/core/types'

import { config } from '../../config'

export const KEY = ['v1', 'geoip2', 'city']

interface GeoIP2Data {
  continent: {
    code: string
    geonameId: number
    names: Localized
  }
  country: {
    geonameId: number
    isInEuropeanUnion: boolean
    isoCode: string
    names: Localized
  }
  registeredCountry: {
    geonameId: number
    isInEuropeanUnion: boolean
    isoCode: string
    names: Localized
  }
  traits: {
    isAnonymous: boolean
    isAnonymousProxy: boolean
    isAnonymousVpn: boolean
    isHostingProvider: boolean
    isLegitimateProxy: boolean
    isPublicProxy: boolean
    isResidentialProxy: boolean
    isSatelliteProvider: boolean
    isTorExitNode: boolean
    ipAddress: string
    network: string
  }
  city: {
    geonameId: number
    names: Localized
  }
  location: {
    accuracyRadius: number
    latitude: number
    longitude: number
    timeZone: string
  }
  postal: {
    code: string
  }
  subdivisions: {
    geonameId: number
    isoCode: string
    names: Localized
  }[]
}

interface Localized {
  de: string
  en: string
  es: string
  fr: string
  ja: string
  'pt-BR': string
  ru: string
  'zh-CN': string
}

function queryFn(context: QueryFunctionContext): Promise<GeoIP2Data> {
  return fetch(`${config.backendUrl}/v1/geoip2/city`, {
    headers: { 'X-Key': config.backendApiKey! },
    signal: context.signal
  }).then((res) => res.json())
}

export const useGeoData = (queryOptions?: UseQueryOptions<GeoIP2Data, Error>) =>
  useQuery<GeoIP2Data, Error>(KEY, queryFn, queryOptions)
