import axios, { AxiosPromise, AxiosRequestConfig, Method } from 'axios'
// @ts-ignore
import createError from 'axios/lib/core/createError'
import _ from 'lodash'
import * as qs from 'qs'

import CookieService from '../../services/CookieService'
import socket from '../../utils/socket.io'
import { onAfterRequest, onBeforeRequest } from './models.rest.hooks'
import { CompleteModelType } from './models.types'

function setWSRequestFailed(request: {
  method: any
  path?: string
  params: any
  query: any
}) {
  const key = JSON.stringify([
    'wsRequestFailed',
    request.method,
    request.path,
    request.params,
    request.query
  ])
  CookieService.set(key, { yes: 1 }, { minutes: 10 })
}

function getWSRequestFailed(request: {
  method: any
  path?: string
  params: any
  query: any
}) {
  if (window.location.host.includes('localhost')) return false
  const key = JSON.stringify([
    'wsRequestFailed',
    request.method,
    request.path,
    request.params,
    request.query
  ])
  return _.get(CookieService.get(key), 'yes') === 1
}

export type PathParam = string | number

export function createRoute<T>([method, url, model]: [
  Method,
  string,
  IModelsValues | undefined
]) {
  const baseHandler = createRouteWithPath<{}, T>([method, model], () => url)
  return (init?: AxiosRequestConfig): Promise<any> => {
    return baseHandler({}, init)
  }
}

export function createRouteWithPath<Params, T>(
  [method, model]: [Method, IModelsValues | undefined],
  getUrl: (params: Params) => string
) {
  return async (
    params: Params,
    init?: AxiosRequestConfig
  ): Promise<T | any> => {
    const config: AxiosRequestConfig = {
      ...(init || {}),
      method,
      url: getUrl(params)
    }
    const wsRequest = {
      method,
      params: params || {},
      path: config.url,
      query: init?.params
        ? typeof init.params === 'string'
          ? qs.parse(init.params)
          : init.params
        : {},
      body: config.data
    }
    await onBeforeRequest(config, model)
    let data

    if (
      socket.connected &&
      method.toLowerCase() === 'get' &&
      !getWSRequestFailed(wsRequest)
    ) {
      const wsResponse = await new Promise<AxiosPromise>((resolve, reject) => {
        let wsReqeustTimeout = setTimeout(() => {
          setWSRequestFailed(wsRequest)
          console.log('Request timout error occurred', wsRequest)
          return reject(
            createError(
              'Request timout error occurred',
              config,
              null,
              wsRequest,
              null
            )
          )
        }, 15_000)

        socket.emit('api:execute', wsRequest, (response) => {
          clearTimeout(wsReqeustTimeout)

          if (response.status !== 200) {
            setWSRequestFailed(wsRequest)
            console.log('Request error occurred', wsRequest, response)
            return reject(
              createError(
                'Request error occurred',
                config,
                response.status,
                wsRequest,
                response
              )
            )
          }
          return resolve(response)
        })
      })
      data = wsResponse.data
      console.log({
        request: wsRequest,
        response: wsResponse
      })
    } else {
      const httpResponse = await axios(config)
      data = httpResponse.data
    }
    await onAfterRequest(data, config, model)
    return data
  }
}

export const Models: IModels = {
  user: 'user',
  role: 'role',
  action: 'action',
  apiKey: 'api-key',
  application: 'application',
  applicationPlugins: 'application-plugins',
  appointment: 'appointment',
  assets: 'assets',
  billingFeature: 'billing-feature',
  billingPlan: 'billing-plan',
  billingSubscription: 'billing-subscription',
  cabinet: 'cabinet',
  cashier: 'cashier',
  customRoutes: 'custom-routes',
  dentalFormula: 'dental-formula',
  department: 'department',
  diagnosisSample: 'diagnosis-sample',
  diagnosisSampleGroup: 'diagnosis-sample-group',
  diagnosisSampleProcedure: 'diagnosis-sample-procedure',
  fiscalConfiguration: 'fiscal-configuration',
  fiscalReportRule: 'fiscal-report-rule',
  insurancePartner: 'insurance-partner',
  insurancePolicy: 'insurance-policy',
  notification: 'notification',
  patient: 'patient',
  payment: 'payment',
  paymentMethod: 'payment-method',
  price: 'price',
  priceCategory: 'price-category',
  priceCurrency: 'price-currency',
  public: 'public',
  rfidDevice: 'rfid-device',
  rfidTag: 'rfid-tag',
  session: 'session',
  shift: 'shift',
  storeCategory: 'store-category',
  storeItem: 'store-item',
  storeStorage: 'store-storage',
  storeTransaction: 'store-transaction',
  techExecutor: 'tech-executor',
  techPrice: 'tech-price',
  techWork: 'tech-work',
  treatmentPlan: 'treatment-plan',
  v1: 'v1',
  vacation: 'vacation',
  visit: 'visit',
  webArticle: 'web-article',
  webContact: 'web-contact',
  webHeader: 'web-header',
  webMainPage: 'web-main-page',
  webPhotogallery: 'web-photogallery',
  webPrice: 'web-price',
  webPrivacyPolicy: 'web-privacy-policy',
  webRequest: 'web-request',
  webSpecialOffer: 'web-special-offer',
  workTime: 'work-time',
  youtubeCredential: 'youtube-credential'
}

export type IModels = {
  user: 'user'
  role: 'role'
  action: 'action'
  apiKey: 'api-key'
  application: 'application'
  applicationPlugins: 'application-plugins'
  appointment: 'appointment'
  assets: 'assets'
  billingFeature: 'billing-feature'
  billingPlan: 'billing-plan'
  billingSubscription: 'billing-subscription'
  cabinet: 'cabinet'
  cashier: 'cashier'
  customRoutes: 'custom-routes'
  dentalFormula: 'dental-formula'
  department: 'department'
  diagnosisSample: 'diagnosis-sample'
  diagnosisSampleGroup: 'diagnosis-sample-group'
  diagnosisSampleProcedure: 'diagnosis-sample-procedure'
  fiscalConfiguration: 'fiscal-configuration'
  fiscalReportRule: 'fiscal-report-rule'
  insurancePartner: 'insurance-partner'
  insurancePolicy: 'insurance-policy'
  notification: 'notification'
  patient: 'patient'
  payment: 'payment'
  paymentMethod: 'payment-method'
  price: 'price'
  priceCategory: 'price-category'
  priceCurrency: 'price-currency'
  public: 'public'
  rfidDevice: 'rfid-device'
  rfidTag: 'rfid-tag'
  session: 'session'
  shift: 'shift'
  storeCategory: 'store-category'
  storeItem: 'store-item'
  storeStorage: 'store-storage'
  storeTransaction: 'store-transaction'
  techExecutor: 'tech-executor'
  techPrice: 'tech-price'
  techWork: 'tech-work'
  treatmentPlan: 'treatment-plan'
  v1: 'v1'
  vacation: 'vacation'
  visit: 'visit'
  webArticle: 'web-article'
  webContact: 'web-contact'
  webHeader: 'web-header'
  webMainPage: 'web-main-page'
  webPhotogallery: 'web-photogallery'
  webPrice: 'web-price'
  webPrivacyPolicy: 'web-privacy-policy'
  webRequest: 'web-request'
  webSpecialOffer: 'web-special-offer'
  workTime: 'work-time'
  youtubeCredential: 'youtube-credential'
}

export type IModelsValues = IModels[keyof IModels]

// export interface BaseAPIResponse<Data> {
//   data: Data
//   meta?: {}
//   error?: {}
// }
export type BaseAPIResponse<Data> = Data

export type IAPIRoutes = {
  action: {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'action'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'action'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'action'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'action'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'action'>>>
  }
  'api-key': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'api-key'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'api-key'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'api-key'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'api-key'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'api-key'>>>
  }
  application: {
    current: (config?: AxiosRequestConfig) => Promise<any>
    pluginsList: (config?: AxiosRequestConfig) => Promise<any>
    pluginsInstalled: (config?: AxiosRequestConfig) => Promise<any>
    logoPNG: (
      params: Record<'size', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    logo: (
      params: Record<'size', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'application'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'application'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'application'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'application'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'application'>>>
  }
  'application-plugins': {
    monobankPaymentsNotifierPaymentMethodIdAuthPOST: (
      params: Record<'paymentMethodId', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    monobankPaymentsNotifierPaymentMethodIdAccounts: (
      params: Record<'paymentMethodId', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    uaFiscalPaymentMethodIdPrecreate: (
      params: Record<'paymentMethodId', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'application-plugins'>>>
  }
  appointment: {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'appointment'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'appointment'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'appointment'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'appointment'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'appointment'>>>
  }
  assets: {
    find: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'assets'>[]>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'assets'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'assets'>>>
  }
  'billing-feature': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-feature'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-feature'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-feature'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-feature'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-feature'>>>
  }
  'billing-plan': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-plan'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-plan'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-plan'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-plan'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-plan'>>>
  }
  'billing-subscription': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-subscription'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-subscription'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-subscription'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-subscription'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'billing-subscription'>>>
  }
  cabinet: {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'cabinet'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'cabinet'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'cabinet'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'cabinet'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'cabinet'>>>
  }
  cashier: {
    documentsUID: (
      params: Record<'UID', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdObjectsRegistrarFiscalNumLocalNumState: (
      params: Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdObjectsRegistrarFiscalNumLocalNumZrep: (
      params: Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdObjectsRegistrarFiscalNumLocalNumZrepPOST: (
      params: Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdObjectsRegistrarFiscalNumLocalNumShiftStatePOST: (
      params: Record<'userId' | 'fiscalNum' | 'localNum' | 'state', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdObjectsRegistrarFiscalNumLocalNumCheckPOST: (
      params: Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdObjectsRegistrarFiscalNumLocalNumDocumentPUT: (
      params: Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdObjectsRegistrarFiscalNumLocalNumDocuments: (
      params: Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdObjectsRegistrarFiscalNumLocalNumServiceTypePOST: (
      params: Record<'userId' | 'fiscalNum' | 'localNum' | 'type', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdObjectsGET: (
      params: Record<'userId', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserIdOperatorsGET: (
      params: Record<'userId', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersUserId: (
      params: Record<'userId', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    users___GET: (config?: AxiosRequestConfig) => Promise<any>
    users___POST: (config?: AxiosRequestConfig) => Promise<any>
    usersUserIdDELETE: (
      params: Record<'userId', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
  }
  'custom-routes': {
    rolesFindOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    usersFindOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
  }
  'dental-formula': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'dental-formula'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'dental-formula'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'dental-formula'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'dental-formula'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'dental-formula'>>>
  }
  department: {
    requestAll: (config?: AxiosRequestConfig) => Promise<any>
    requestOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'department'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'department'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'department'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'department'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'department'>>>
  }
  'diagnosis-sample': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample'>>>
  }
  'diagnosis-sample-group': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>>>
  }
  'diagnosis-sample-procedure': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>[]>
    >
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>>
    >
    create: (
      config?: AxiosRequestConfig
    ) => Promise<
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>>
    >
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>>
    >
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>>
    >
  }
  'fiscal-configuration': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-configuration'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-configuration'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-configuration'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-configuration'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-configuration'>>>
  }
  'fiscal-report-rule': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>>>
  }
  'insurance-partner': {
    keyedFind: (config?: AxiosRequestConfig) => Promise<any>
    previewAppointmentReport: (
      params: Record<'id' | 'app', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    previewAllReport: (
      params: Record<'month' | 'year', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    previewReport: (
      params: Record<'id' | 'month' | 'year', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    allReports: (
      params: Record<'month' | 'year' | 'any', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    reportAppointment: (
      params: Record<'id' | 'app' | 'any', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    report: (
      params: Record<'id' | 'month' | 'year' | 'any', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-partner'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-partner'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-partner'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-partner'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-partner'>>>
  }
  'insurance-policy': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-policy'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-policy'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-policy'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-policy'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'insurance-policy'>>>
  }
  notification: {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'notification'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'notification'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'notification'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'notification'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'notification'>>>
  }
  patient: {
    debts: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    appDebts: (
      params: Record<'appId' | 'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'patient'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'patient'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'patient'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'patient'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'patient'>>>
  }
  payment: {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment'>>>
  }
  'payment-method': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment-method'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment-method'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment-method'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment-method'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'payment-method'>>>
  }
  price: {
    top: (config?: AxiosRequestConfig) => Promise<any>
    listInPdf: (config?: AxiosRequestConfig) => Promise<any>
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price'>>>
  }
  'price-category': {
    keyedFind: (config?: AxiosRequestConfig) => Promise<any>
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-category'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-category'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-category'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-category'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-category'>>>
  }
  'price-currency': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-currency'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-currency'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-currency'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-currency'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'price-currency'>>>
  }
  public: {
    v1RequestCallPOST: (config?: AxiosRequestConfig) => Promise<any>
    v1RequestAppointmentPOST: (config?: AxiosRequestConfig) => Promise<any>
    v1PagesMain: (config?: AxiosRequestConfig) => Promise<any>
    v1PagesHeader: (config?: AxiosRequestConfig) => Promise<any>
    v1PagesPrivacyPolicy: (config?: AxiosRequestConfig) => Promise<any>
    v1_prices: (config?: AxiosRequestConfig) => Promise<any>
    v1_contacts: (config?: AxiosRequestConfig) => Promise<any>
    v1_specialOffers: (config?: AxiosRequestConfig) => Promise<any>
    v1_partners: (config?: AxiosRequestConfig) => Promise<any>
    v1_articles: (config?: AxiosRequestConfig) => Promise<any>
    v2CashierRegistrarOrder: (
      params: Record<'registrar' | 'order', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
  }
  'rfid-device': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-device'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-device'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-device'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-device'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-device'>>>
  }
  'rfid-tag': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-tag'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-tag'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-tag'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-tag'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'rfid-tag'>>>
  }
  session: {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'session'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'session'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'session'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'session'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'session'>>>
  }
  shift: {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'shift'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'shift'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'shift'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'shift'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'shift'>>>
  }
  'store-category': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-category'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-category'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-category'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-category'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-category'>>>
  }
  'store-item': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-item'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-item'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-item'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-item'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-item'>>>
  }
  'store-storage': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-storage'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-storage'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-storage'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-storage'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-storage'>>>
  }
  'store-transaction': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-transaction'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-transaction'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-transaction'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-transaction'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'store-transaction'>>>
  }
  'tech-executor': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-executor'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-executor'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-executor'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-executor'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-executor'>>>
  }
  'tech-price': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-price'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-price'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-price'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-price'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-price'>>>
  }
  'tech-work': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-work'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-work'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-work'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-work'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'tech-work'>>>
  }
  'treatment-plan': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'treatment-plan'>[]>>
    count: (config?: AxiosRequestConfig) => Promise<any>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'treatment-plan'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'treatment-plan'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'treatment-plan'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'treatment-plan'>>>
  }
  v1: {
    adminCronName: (
      params: Record<'name', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    analyticsPricesCategories: (config?: AxiosRequestConfig) => Promise<any>
    analyticsVisitsCompletions: (config?: AxiosRequestConfig) => Promise<any>
    analyticsVisitsDays: (config?: AxiosRequestConfig) => Promise<any>
    analyticsDoctorsDay: (config?: AxiosRequestConfig) => Promise<any>
    analyticsDoctorsVisit: (config?: AxiosRequestConfig) => Promise<any>
    analyticsPaymentsMethods: (config?: AxiosRequestConfig) => Promise<any>
    analyticsPaymentsPartners: (config?: AxiosRequestConfig) => Promise<any>
    appointments_date: (config?: AxiosRequestConfig) => Promise<any>
    appointments_canCreate: (config?: AxiosRequestConfig) => Promise<any>
    appointments_create__POST: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'v1'>>>
    auth_departments: (config?: AxiosRequestConfig) => Promise<any>
    doctors_isFree: (config?: AxiosRequestConfig) => Promise<any>
    email_list: (config?: AxiosRequestConfig) => Promise<any>
    geoip2_city: (config?: AxiosRequestConfig) => Promise<any>
    geoip2_country: (config?: AxiosRequestConfig) => Promise<any>
    legacy_telegram_callback: (config?: AxiosRequestConfig) => Promise<any>
    patients_all: (config?: AxiosRequestConfig) => Promise<any>
    personal_me: (config?: AxiosRequestConfig) => Promise<any>
    personal_all: (config?: AxiosRequestConfig) => Promise<any>
    personal_update__POST: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'v1'>>>
    personal_loginData: (config?: AxiosRequestConfig) => Promise<any>
    places_departments: (config?: AxiosRequestConfig) => Promise<any>
    places_cabinets: (config?: AxiosRequestConfig) => Promise<any>
    policies_limits: (config?: AxiosRequestConfig) => Promise<any>
    prices_all: (config?: AxiosRequestConfig) => Promise<any>
    prices_categories: (config?: AxiosRequestConfig) => Promise<any>
    requests___callback__POST: (config?: AxiosRequestConfig) => Promise<any>
    requests_appointment: (config?: AxiosRequestConfig) => Promise<any>
    requests_call: (config?: AxiosRequestConfig) => Promise<any>
    requests_appointmentRich: (config?: AxiosRequestConfig) => Promise<any>
    security_exit__PATCH: (config?: AxiosRequestConfig) => Promise<any>
    security_block__PATCH: (config?: AxiosRequestConfig) => Promise<any>
    security_unblock__PATCH: (config?: AxiosRequestConfig) => Promise<any>
    security_inject__PATCH: (config?: AxiosRequestConfig) => Promise<any>
    security_ask_reload__PATCH: (config?: AxiosRequestConfig) => Promise<any>
    security_reload__PATCH: (config?: AxiosRequestConfig) => Promise<any>
    securityDeveloperPassword: (
      params: Record<'password', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    sms_send__POST: (config?: AxiosRequestConfig) => Promise<any>
    user_refresh: (config?: AxiosRequestConfig) => Promise<any>
    utils_away: (config?: AxiosRequestConfig) => Promise<any>
    utils_calendar: (config?: AxiosRequestConfig) => Promise<any>
    webhooks_monobank__POST: (config?: AxiosRequestConfig) => Promise<any>
    youtube_liveStream: (config?: AxiosRequestConfig) => Promise<any>
    youtube_live: (config?: AxiosRequestConfig) => Promise<any>
    youtube_oauth: (config?: AxiosRequestConfig) => Promise<any>
    youtube_connect: (config?: AxiosRequestConfig) => Promise<any>
  }
  vacation: {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'vacation'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'vacation'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'vacation'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'vacation'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'vacation'>>>
  }
  visit: {
    fiscal: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<any>
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'visit'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'visit'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'visit'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'visit'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'visit'>>>
  }
  'web-article': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-article'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-article'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-article'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-article'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-article'>>>
    createLocalization: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-article'>>>
  }
  'web-contact': {
    keyedFind: (config?: AxiosRequestConfig) => Promise<any>
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-contact'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-contact'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-contact'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-contact'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-contact'>>>
    createLocalization: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-contact'>>>
  }
  'web-header': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-header'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-header'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-header'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-header'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-header'>>>
    createLocalization: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-header'>>>
  }
  'web-main-page': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-main-page'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-main-page'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-main-page'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-main-page'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-main-page'>>>
    createLocalization: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-main-page'>>>
  }
  'web-photogallery': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-photogallery'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-photogallery'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-photogallery'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-photogallery'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-photogallery'>>>
  }
  'web-price': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-price'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-price'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-price'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-price'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-price'>>>
    createLocalization: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-price'>>>
  }
  'web-privacy-policy': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-privacy-policy'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-privacy-policy'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-privacy-policy'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-privacy-policy'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-privacy-policy'>>>
  }
  'web-request': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-request'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-request'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-request'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-request'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-request'>>>
  }
  'web-special-offer': {
    keyedFind: (config?: AxiosRequestConfig) => Promise<any>
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-special-offer'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-special-offer'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-special-offer'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-special-offer'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-special-offer'>>>
    createLocalization: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'web-special-offer'>>>
  }
  'work-time': {
    find: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'work-time'>[]>>
    findOne: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'work-time'>>>
    create: (
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'work-time'>>>
    update: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'work-time'>>>
    delete: (
      params: Record<'id', PathParam>,
      config?: AxiosRequestConfig
    ) => Promise<BaseAPIResponse<CompleteModelType<'work-time'>>>
  }
  'youtube-credential': {}
}

export const APIRoutes: IAPIRoutes = {
  action: {
    find: createRoute<BaseAPIResponse<CompleteModelType<'action'>>>([
      'GET',
      '/actions',
      'action'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'action'>>
    >(['GET', 'action'], (p) => `/actions/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'action'>>>([
      'POST',
      '/actions',
      'action'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'action'>>
    >(['PUT', 'action'], (p) => `/actions/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'action'>>
    >(['DELETE', 'action'], (p) => `/actions/${p.id}`)
  },
  'api-key': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'api-key'>>>([
      'GET',
      '/api-keys',
      'api-key'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'api-key'>>
    >(['GET', 'api-key'], (p) => `/api-keys/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'api-key'>>>([
      'POST',
      '/api-keys',
      'api-key'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'api-key'>>
    >(['PUT', 'api-key'], (p) => `/api-keys/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'api-key'>>
    >(['DELETE', 'api-key'], (p) => `/api-keys/${p.id}`)
  },
  application: {
    current: createRoute<any>(['GET', '/application', 'application']),
    pluginsList: createRoute<any>([
      'GET',
      '/application/plugins/list',
      'application'
    ]),
    pluginsInstalled: createRoute<any>([
      'GET',
      '/application/plugins/installed',
      'application'
    ]),
    logoPNG: createRouteWithPath<Record<'size', PathParam>, any>(
      ['GET', 'application'],
      (p) => `/application/logo/${p.size}.png`
    ),
    logo: createRouteWithPath<Record<'size', PathParam>, any>(
      ['GET', 'application'],
      (p) => `/application/logo/${p.size}`
    ),
    find: createRoute<BaseAPIResponse<CompleteModelType<'application'>>>([
      'GET',
      '/applications',
      'application'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'application'>>
    >(['GET', 'application'], (p) => `/applications/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'application'>>>([
      'POST',
      '/applications',
      'application'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'application'>>
    >(['PUT', 'application'], (p) => `/applications/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'application'>>
    >(['DELETE', 'application'], (p) => `/applications/${p.id}`)
  },
  'application-plugins': {
    monobankPaymentsNotifierPaymentMethodIdAuthPOST: createRouteWithPath<
      Record<'paymentMethodId', PathParam>,
      any
    >(
      ['POST', 'application-plugins'],
      (p) =>
        `/application/plugins/monobank-payments-notifier/${p.paymentMethodId}/auth`
    ),
    monobankPaymentsNotifierPaymentMethodIdAccounts: createRouteWithPath<
      Record<'paymentMethodId', PathParam>,
      any
    >(
      ['GET', 'application-plugins'],
      (p) =>
        `/application/plugins/monobank-payments-notifier/${p.paymentMethodId}/accounts`
    ),
    uaFiscalPaymentMethodIdPrecreate: createRouteWithPath<
      Record<'paymentMethodId', PathParam>,
      BaseAPIResponse<CompleteModelType<'application-plugins'>>
    >(
      ['GET', 'application-plugins'],
      (p) => `/application/plugins/ua-fiscal/${p.paymentMethodId}/precreate`
    )
  },
  appointment: {
    find: createRoute<BaseAPIResponse<CompleteModelType<'appointment'>>>([
      'GET',
      '/appointments',
      'appointment'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'appointment'>>
    >(['GET', 'appointment'], (p) => `/appointments/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'appointment'>>>([
      'POST',
      '/appointments',
      'appointment'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'appointment'>>
    >(['PUT', 'appointment'], (p) => `/appointments/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'appointment'>>
    >(['DELETE', 'appointment'], (p) => `/appointments/${p.id}`)
  },
  assets: {
    find: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'assets'>>
    >(['GET', undefined], (p) => `/assets/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'assets'>>>([
      'POST',
      '/assets',
      undefined
    ]),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'assets'>>
    >(['DELETE', undefined], (p) => `/assets/${p.id}`)
  },
  'billing-feature': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'billing-feature'>>>([
      'GET',
      '/billing/feature',
      'billing-feature'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'billing-feature'>>
    >(['GET', 'billing-feature'], (p) => `/billing/feature/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'billing-feature'>>>([
      'POST',
      '/billing/feature',
      'billing-feature'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'billing-feature'>>
    >(['PUT', 'billing-feature'], (p) => `/billing/feature/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'billing-feature'>>
    >(['DELETE', 'billing-feature'], (p) => `/billing/feature/${p.id}`)
  },
  'billing-plan': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'billing-plan'>>>([
      'GET',
      '/billing/plans',
      'billing-plan'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'billing-plan'>>
    >(['GET', 'billing-plan'], (p) => `/billing/plans/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'billing-plan'>>>([
      'POST',
      '/billing/plans',
      'billing-plan'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'billing-plan'>>
    >(['PUT', 'billing-plan'], (p) => `/billing/plans/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'billing-plan'>>
    >(['DELETE', 'billing-plan'], (p) => `/billing/plans/${p.id}`)
  },
  'billing-subscription': {
    find: createRoute<
      BaseAPIResponse<CompleteModelType<'billing-subscription'>>
    >(['GET', '/billing/subscriptions', 'billing-subscription']),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'billing-subscription'>>
    >(['GET', 'billing-subscription'], (p) => `/billing/subscriptions/${p.id}`),
    create: createRoute<
      BaseAPIResponse<CompleteModelType<'billing-subscription'>>
    >(['POST', '/billing/subscriptions', 'billing-subscription']),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'billing-subscription'>>
    >(['PUT', 'billing-subscription'], (p) => `/billing/subscriptions/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'billing-subscription'>>
    >(
      ['DELETE', 'billing-subscription'],
      (p) => `/billing/subscriptions/${p.id}`
    )
  },
  cabinet: {
    find: createRoute<BaseAPIResponse<CompleteModelType<'cabinet'>>>([
      'GET',
      '/cabinets',
      'cabinet'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'cabinet'>>
    >(['GET', 'cabinet'], (p) => `/cabinets/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'cabinet'>>>([
      'POST',
      '/cabinets',
      'cabinet'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'cabinet'>>
    >(['PUT', 'cabinet'], (p) => `/cabinets/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'cabinet'>>
    >(['DELETE', 'cabinet'], (p) => `/cabinets/${p.id}`)
  },
  cashier: {
    documentsUID: createRouteWithPath<Record<'UID', PathParam>, any>(
      ['GET', undefined],
      (p) => `/cashier/documents/${p.UID}`
    ),
    usersUserIdObjectsRegistrarFiscalNumLocalNumState: createRouteWithPath<
      Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      any
    >(
      ['GET', undefined],
      (p) =>
        `/cashier/users/${p.userId}/objects/registrar/${p.fiscalNum}/${p.localNum}/state`
    ),
    usersUserIdObjectsRegistrarFiscalNumLocalNumZrep: createRouteWithPath<
      Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      any
    >(
      ['GET', undefined],
      (p) =>
        `/cashier/users/${p.userId}/objects/registrar/${p.fiscalNum}/${p.localNum}/zrep`
    ),
    usersUserIdObjectsRegistrarFiscalNumLocalNumZrepPOST: createRouteWithPath<
      Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      any
    >(
      ['POST', undefined],
      (p) =>
        `/cashier/users/${p.userId}/objects/registrar/${p.fiscalNum}/${p.localNum}/zrep`
    ),
    usersUserIdObjectsRegistrarFiscalNumLocalNumShiftStatePOST:
      createRouteWithPath<
        Record<'userId' | 'fiscalNum' | 'localNum' | 'state', PathParam>,
        any
      >(
        ['POST', undefined],
        (p) =>
          `/cashier/users/${p.userId}/objects/registrar/${p.fiscalNum}/${p.localNum}/shift/${p.state}`
      ),
    usersUserIdObjectsRegistrarFiscalNumLocalNumCheckPOST: createRouteWithPath<
      Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      any
    >(
      ['POST', undefined],
      (p) =>
        `/cashier/users/${p.userId}/objects/registrar/${p.fiscalNum}/${p.localNum}/check`
    ),
    usersUserIdObjectsRegistrarFiscalNumLocalNumDocumentPUT:
      createRouteWithPath<
        Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
        any
      >(
        ['PUT', undefined],
        (p) =>
          `/cashier/users/${p.userId}/objects/registrar/${p.fiscalNum}/${p.localNum}/document`
      ),
    usersUserIdObjectsRegistrarFiscalNumLocalNumDocuments: createRouteWithPath<
      Record<'userId' | 'fiscalNum' | 'localNum', PathParam>,
      any
    >(
      ['GET', undefined],
      (p) =>
        `/cashier/users/${p.userId}/objects/registrar/${p.fiscalNum}/${p.localNum}/documents`
    ),
    usersUserIdObjectsRegistrarFiscalNumLocalNumServiceTypePOST:
      createRouteWithPath<
        Record<'userId' | 'fiscalNum' | 'localNum' | 'type', PathParam>,
        any
      >(
        ['POST', undefined],
        (p) =>
          `/cashier/users/${p.userId}/objects/registrar/${p.fiscalNum}/${p.localNum}/service/${p.type}`
      ),
    usersUserIdObjectsGET: createRouteWithPath<
      Record<'userId', PathParam>,
      any
    >(['GET', undefined], (p) => `/cashier/users/${p.userId}/objects`),
    usersUserIdOperatorsGET: createRouteWithPath<
      Record<'userId', PathParam>,
      any
    >(['GET', undefined], (p) => `/cashier/users/${p.userId}/operators`),
    usersUserId: createRouteWithPath<Record<'userId', PathParam>, any>(
      ['GET', undefined],
      (p) => `/cashier/users/${p.userId}`
    ),
    users___GET: createRoute<any>(['GET', '/cashier/users', undefined]),
    users___POST: createRoute<any>(['POST', '/cashier/users', undefined]),
    usersUserIdDELETE: createRouteWithPath<Record<'userId', PathParam>, any>(
      ['DELETE', undefined],
      (p) => `/cashier/users/${p.userId}`
    )
  },
  'custom-routes': {
    rolesFindOne: createRouteWithPath<Record<'id', PathParam>, any>(
      ['GET', 'custom-routes'],
      (p) => `/roles/${p.id}`
    ),
    usersFindOne: createRouteWithPath<Record<'id', PathParam>, any>(
      ['GET', 'custom-routes'],
      (p) => `/users/${p.id}`
    )
  },
  'dental-formula': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'dental-formula'>>>([
      'GET',
      '/dental-formulas',
      'dental-formula'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'dental-formula'>>
    >(['GET', 'dental-formula'], (p) => `/dental-formulas/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'dental-formula'>>>([
      'POST',
      '/dental-formulas',
      'dental-formula'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'dental-formula'>>
    >(['PUT', 'dental-formula'], (p) => `/dental-formulas/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'dental-formula'>>
    >(['DELETE', 'dental-formula'], (p) => `/dental-formulas/${p.id}`)
  },
  department: {
    requestAll: createRoute<any>(['GET', '/v1/departments', 'department']),
    requestOne: createRouteWithPath<Record<'id', PathParam>, any>(
      ['GET', 'department'],
      (p) => `/v1/departments/${p.id}`
    ),
    find: createRoute<BaseAPIResponse<CompleteModelType<'department'>>>([
      'GET',
      '/departments',
      'department'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'department'>>
    >(['GET', 'department'], (p) => `/departments/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'department'>>>([
      'POST',
      '/departments',
      'department'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'department'>>
    >(['PUT', 'department'], (p) => `/departments/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'department'>>
    >(['DELETE', 'department'], (p) => `/departments/${p.id}`)
  },
  'diagnosis-sample': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'diagnosis-sample'>>>([
      'GET',
      '/diagnosis-samples',
      'diagnosis-sample'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'diagnosis-sample'>>
    >(['GET', 'diagnosis-sample'], (p) => `/diagnosis-samples/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'diagnosis-sample'>>>(
      ['POST', '/diagnosis-samples', 'diagnosis-sample']
    ),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'diagnosis-sample'>>
    >(['PUT', 'diagnosis-sample'], (p) => `/diagnosis-samples/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'diagnosis-sample'>>
    >(['DELETE', 'diagnosis-sample'], (p) => `/diagnosis-samples/${p.id}`)
  },
  'diagnosis-sample-group': {
    find: createRoute<
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>>
    >(['GET', '/diagnosis-samples-groups', 'diagnosis-sample-group']),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>>
    >(
      ['GET', 'diagnosis-sample-group'],
      (p) => `/diagnosis-samples-groups/${p.id}`
    ),
    create: createRoute<
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>>
    >(['POST', '/diagnosis-samples-groups', 'diagnosis-sample-group']),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>>
    >(
      ['PUT', 'diagnosis-sample-group'],
      (p) => `/diagnosis-samples-groups/${p.id}`
    ),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-group'>>
    >(
      ['DELETE', 'diagnosis-sample-group'],
      (p) => `/diagnosis-samples-groups/${p.id}`
    )
  },
  'diagnosis-sample-procedure': {
    find: createRoute<
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>>
    >(['GET', '/diagnosis-samples-procedures', 'diagnosis-sample-procedure']),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>>
    >(
      ['GET', 'diagnosis-sample-procedure'],
      (p) => `/diagnosis-samples-procedures/${p.id}`
    ),
    create: createRoute<
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>>
    >(['POST', '/diagnosis-samples-procedures', 'diagnosis-sample-procedure']),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>>
    >(
      ['PUT', 'diagnosis-sample-procedure'],
      (p) => `/diagnosis-samples-procedures/${p.id}`
    ),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'diagnosis-sample-procedure'>>
    >(
      ['DELETE', 'diagnosis-sample-procedure'],
      (p) => `/diagnosis-samples-procedures/${p.id}`
    )
  },
  'fiscal-configuration': {
    find: createRoute<
      BaseAPIResponse<CompleteModelType<'fiscal-configuration'>>
    >(['GET', '/fiscal/configurations', 'fiscal-configuration']),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'fiscal-configuration'>>
    >(['GET', 'fiscal-configuration'], (p) => `/fiscal/configurations/${p.id}`),
    create: createRoute<
      BaseAPIResponse<CompleteModelType<'fiscal-configuration'>>
    >(['POST', '/fiscal/configurations', 'fiscal-configuration']),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'fiscal-configuration'>>
    >(['PUT', 'fiscal-configuration'], (p) => `/fiscal/configurations/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'fiscal-configuration'>>
    >(
      ['DELETE', 'fiscal-configuration'],
      (p) => `/fiscal/configurations/${p.id}`
    )
  },
  'fiscal-report-rule': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>>>(
      ['GET', '/fiscal/report-rules', 'fiscal-report-rule']
    ),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>>
    >(['GET', 'fiscal-report-rule'], (p) => `/fiscal/report-rules/${p.id}`),
    create: createRoute<
      BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>>
    >(['POST', '/fiscal/report-rules', 'fiscal-report-rule']),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>>
    >(['PUT', 'fiscal-report-rule'], (p) => `/fiscal/report-rules/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'fiscal-report-rule'>>
    >(['DELETE', 'fiscal-report-rule'], (p) => `/fiscal/report-rules/${p.id}`)
  },
  'insurance-partner': {
    keyedFind: createRoute<any>([
      'GET',
      '/keyed/insurance-partner',
      'insurance-partner'
    ]),
    previewAppointmentReport: createRouteWithPath<
      Record<'id' | 'app', PathParam>,
      any
    >(
      ['GET', 'insurance-partner'],
      (p) => `/insurance/partner/${p.id}/report/appointment/${p.app}/preview`
    ),
    previewAllReport: createRouteWithPath<
      Record<'month' | 'year', PathParam>,
      any
    >(
      ['GET', 'insurance-partner'],
      (p) => `/insurance/partner/all/report/${p.month}/${p.year}/preview`
    ),
    previewReport: createRouteWithPath<
      Record<'id' | 'month' | 'year', PathParam>,
      any
    >(
      ['GET', 'insurance-partner'],
      (p) => `/insurance/partner/${p.id}/report/${p.month}/${p.year}/preview`
    ),
    allReports: createRouteWithPath<
      Record<'month' | 'year' | 'any', PathParam>,
      any
    >(
      ['GET', 'insurance-partner'],
      (p) => `/insurance/partner/all/report/${p.month}/${p.year}/${p.any}`
    ),
    reportAppointment: createRouteWithPath<
      Record<'id' | 'app' | 'any', PathParam>,
      any
    >(
      ['GET', 'insurance-partner'],
      (p) => `/insurance/partner/${p.id}/report/appointment/${p.app}/${p.any}`
    ),
    report: createRouteWithPath<
      Record<'id' | 'month' | 'year' | 'any', PathParam>,
      any
    >(
      ['GET', 'insurance-partner'],
      (p) => `/insurance/partner/${p.id}/report/${p.month}/${p.year}/${p.any}`
    ),
    find: createRoute<BaseAPIResponse<CompleteModelType<'insurance-partner'>>>([
      'GET',
      '/insurance/partner',
      'insurance-partner'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'insurance-partner'>>
    >(['GET', 'insurance-partner'], (p) => `/insurance/partner/${p.id}`),
    create: createRoute<
      BaseAPIResponse<CompleteModelType<'insurance-partner'>>
    >(['POST', '/insurance/partner', 'insurance-partner']),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'insurance-partner'>>
    >(['PUT', 'insurance-partner'], (p) => `/insurance/partner/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'insurance-partner'>>
    >(['DELETE', 'insurance-partner'], (p) => `/insurance/partner/${p.id}`)
  },
  'insurance-policy': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'insurance-policy'>>>([
      'GET',
      '/insurance/policy',
      'insurance-policy'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'insurance-policy'>>
    >(['GET', 'insurance-policy'], (p) => `/insurance/policy/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'insurance-policy'>>>(
      ['POST', '/insurance/policy', 'insurance-policy']
    ),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'insurance-policy'>>
    >(['PUT', 'insurance-policy'], (p) => `/insurance/policy/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'insurance-policy'>>
    >(['DELETE', 'insurance-policy'], (p) => `/insurance/policy/${p.id}`)
  },
  notification: {
    find: createRoute<BaseAPIResponse<CompleteModelType<'notification'>>>([
      'GET',
      '/notifications',
      'notification'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'notification'>>
    >(['GET', 'notification'], (p) => `/notifications/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'notification'>>>([
      'POST',
      '/notifications',
      'notification'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'notification'>>
    >(['PUT', 'notification'], (p) => `/notifications/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'notification'>>
    >(['DELETE', 'notification'], (p) => `/notifications/${p.id}`)
  },
  patient: {
    debts: createRouteWithPath<Record<'id', PathParam>, any>(
      ['GET', 'patient'],
      (p) => `/patients/${p.id}/debts`
    ),
    appDebts: createRouteWithPath<Record<'appId' | 'id', PathParam>, any>(
      ['GET', 'patient'],
      (p) => `/appointments/${p.appId}/patients/${p.id}/debts`
    ),
    find: createRoute<BaseAPIResponse<CompleteModelType<'patient'>>>([
      'GET',
      '/patients',
      'patient'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'patient'>>
    >(['GET', 'patient'], (p) => `/patients/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'patient'>>>([
      'POST',
      '/patients',
      'patient'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'patient'>>
    >(['PUT', 'patient'], (p) => `/patients/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'patient'>>
    >(['DELETE', 'patient'], (p) => `/patients/${p.id}`)
  },
  payment: {
    find: createRoute<BaseAPIResponse<CompleteModelType<'payment'>>>([
      'GET',
      '/payments',
      'payment'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'payment'>>
    >(['GET', 'payment'], (p) => `/payments/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'payment'>>>([
      'POST',
      '/payments',
      'payment'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'payment'>>
    >(['PUT', 'payment'], (p) => `/payments/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'payment'>>
    >(['DELETE', 'payment'], (p) => `/payments/${p.id}`)
  },
  'payment-method': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'payment-method'>>>([
      'GET',
      '/payment-methods',
      'payment-method'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'payment-method'>>
    >(['GET', 'payment-method'], (p) => `/payment-methods/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'payment-method'>>>([
      'POST',
      '/payment-methods',
      'payment-method'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'payment-method'>>
    >(['PUT', 'payment-method'], (p) => `/payment-methods/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'payment-method'>>
    >(['DELETE', 'payment-method'], (p) => `/payment-methods/${p.id}`)
  },
  price: {
    top: createRoute<any>(['GET', '/prices/top', 'price']),
    listInPdf: createRoute<any>(['GET', '/prices.pdf', 'price']),
    find: createRoute<BaseAPIResponse<CompleteModelType<'price'>>>([
      'GET',
      '/prices',
      'price'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'price'>>
    >(['GET', 'price'], (p) => `/prices/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'price'>>>([
      'POST',
      '/prices',
      'price'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'price'>>
    >(['PUT', 'price'], (p) => `/prices/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'price'>>
    >(['DELETE', 'price'], (p) => `/prices/${p.id}`)
  },
  'price-category': {
    keyedFind: createRoute<any>([
      'GET',
      '/keyed/prices-categories',
      'price-category'
    ]),
    find: createRoute<BaseAPIResponse<CompleteModelType<'price-category'>>>([
      'GET',
      '/prices-categories',
      'price-category'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'price-category'>>
    >(['GET', 'price-category'], (p) => `/prices-categories/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'price-category'>>>([
      'POST',
      '/prices-categories',
      'price-category'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'price-category'>>
    >(['PUT', 'price-category'], (p) => `/prices-categories/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'price-category'>>
    >(['DELETE', 'price-category'], (p) => `/prices-categories/${p.id}`)
  },
  'price-currency': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'price-currency'>>>([
      'GET',
      '/prices-currencies',
      'price-currency'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'price-currency'>>
    >(['GET', 'price-currency'], (p) => `/prices-currencies/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'price-currency'>>>([
      'POST',
      '/prices-currencies',
      'price-currency'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'price-currency'>>
    >(['PUT', 'price-currency'], (p) => `/prices-currencies/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'price-currency'>>
    >(['DELETE', 'price-currency'], (p) => `/prices-currencies/${p.id}`)
  },
  public: {
    v1RequestCallPOST: createRoute<any>([
      'POST',
      '/public/v1/request/call',
      undefined
    ]),
    v1RequestAppointmentPOST: createRoute<any>([
      'POST',
      '/public/v1/request/appointment',
      undefined
    ]),
    v1PagesMain: createRoute<any>(['GET', '/public/v1/pages/main', undefined]),
    v1PagesHeader: createRoute<any>([
      'GET',
      '/public/v1/pages/header',
      undefined
    ]),
    v1PagesPrivacyPolicy: createRoute<any>([
      'GET',
      '/public/v1/pages/privacy-policy',
      undefined
    ]),
    v1_prices: createRoute<any>(['GET', '/public/v1/prices', undefined]),
    v1_contacts: createRoute<any>(['GET', '/public/v1/contacts', undefined]),
    v1_specialOffers: createRoute<any>([
      'GET',
      '/public/v1/specialOffers',
      undefined
    ]),
    v1_partners: createRoute<any>(['GET', '/public/v1/partners', undefined]),
    v1_articles: createRoute<any>(['GET', '/public/v1/articles', undefined]),
    v2CashierRegistrarOrder: createRouteWithPath<
      Record<'registrar' | 'order', PathParam>,
      any
    >(['GET', undefined], (p) => `/public/v2/cashier/${p.registrar}/${p.order}`)
  },
  'rfid-device': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'rfid-device'>>>([
      'GET',
      '/rfid/devices',
      'rfid-device'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'rfid-device'>>
    >(['GET', 'rfid-device'], (p) => `/rfid-devices/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'rfid-device'>>>([
      'POST',
      '/rfid/devices',
      'rfid-device'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'rfid-device'>>
    >(['PUT', 'rfid-device'], (p) => `/rfid/devices/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'rfid-device'>>
    >(['DELETE', 'rfid-device'], (p) => `/rfid/devices/${p.id}`)
  },
  'rfid-tag': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'rfid-tag'>>>([
      'GET',
      '/rfid/tags',
      'rfid-tag'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'rfid-tag'>>
    >(['GET', 'rfid-tag'], (p) => `/rfid/tags/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'rfid-tag'>>>([
      'POST',
      '/rfid/tags',
      'rfid-tag'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'rfid-tag'>>
    >(['PUT', 'rfid-tag'], (p) => `/rfid/tags/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'rfid-tag'>>
    >(['DELETE', 'rfid-tag'], (p) => `/rfid/tags/${p.id}`)
  },
  session: {
    find: createRoute<BaseAPIResponse<CompleteModelType<'session'>>>([
      'GET',
      '/sessions',
      'session'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'session'>>
    >(['GET', 'session'], (p) => `/sessions/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'session'>>>([
      'POST',
      '/sessions',
      'session'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'session'>>
    >(['PUT', 'session'], (p) => `/sessions/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'session'>>
    >(['DELETE', 'session'], (p) => `/sessions/${p.id}`)
  },
  shift: {
    find: createRoute<BaseAPIResponse<CompleteModelType<'shift'>>>([
      'GET',
      '/shifts',
      'shift'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'shift'>>
    >(['GET', 'shift'], (p) => `/shifts/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'shift'>>>([
      'POST',
      '/shifts',
      'shift'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'shift'>>
    >(['PUT', 'shift'], (p) => `/shifts/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'shift'>>
    >(['DELETE', 'shift'], (p) => `/shifts/${p.id}`)
  },
  'store-category': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'store-category'>>>([
      'GET',
      '/store/categories',
      'store-category'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-category'>>
    >(['GET', 'store-category'], (p) => `/store/categories/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'store-category'>>>([
      'POST',
      '/store/categories',
      'store-category'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-category'>>
    >(['PUT', 'store-category'], (p) => `/store/categories/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-category'>>
    >(['DELETE', 'store-category'], (p) => `/store/categories/${p.id}`)
  },
  'store-item': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'store-item'>>>([
      'GET',
      '/store/items',
      'store-item'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-item'>>
    >(['GET', 'store-item'], (p) => `/store/items/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'store-item'>>>([
      'POST',
      '/store/items',
      'store-item'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-item'>>
    >(['PUT', 'store-item'], (p) => `/store/items/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-item'>>
    >(['DELETE', 'store-item'], (p) => `/store/items/${p.id}`)
  },
  'store-storage': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'store-storage'>>>([
      'GET',
      '/store/storages',
      'store-storage'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-storage'>>
    >(['GET', 'store-storage'], (p) => `/store/storages/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'store-storage'>>>([
      'POST',
      '/store/storages',
      'store-storage'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-storage'>>
    >(['PUT', 'store-storage'], (p) => `/store/storages/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-storage'>>
    >(['DELETE', 'store-storage'], (p) => `/store/storages/${p.id}`)
  },
  'store-transaction': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'store-transaction'>>>([
      'GET',
      '/store/transactions',
      'store-transaction'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-transaction'>>
    >(['GET', 'store-transaction'], (p) => `/store/transactions/${p.id}`),
    create: createRoute<
      BaseAPIResponse<CompleteModelType<'store-transaction'>>
    >(['POST', '/store/transactions', 'store-transaction']),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-transaction'>>
    >(['PUT', 'store-transaction'], (p) => `/store/transactions/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'store-transaction'>>
    >(['DELETE', 'store-transaction'], (p) => `/store/transactions/${p.id}`)
  },
  'tech-executor': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'tech-executor'>>>([
      'GET',
      '/tech/executors',
      'tech-executor'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'tech-executor'>>
    >(['GET', 'tech-executor'], (p) => `/tech/executors/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'tech-executor'>>>([
      'POST',
      '/tech/executors',
      'tech-executor'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'tech-executor'>>
    >(['PUT', 'tech-executor'], (p) => `/tech/executors/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'tech-executor'>>
    >(['DELETE', 'tech-executor'], (p) => `/tech/executors/${p.id}`)
  },
  'tech-price': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'tech-price'>>>([
      'GET',
      '/tech/prices',
      'tech-price'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'tech-price'>>
    >(['GET', 'tech-price'], (p) => `/tech/prices/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'tech-price'>>>([
      'POST',
      '/tech/prices',
      'tech-price'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'tech-price'>>
    >(['PUT', 'tech-price'], (p) => `/tech/prices/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'tech-price'>>
    >(['DELETE', 'tech-price'], (p) => `/tech/prices/${p.id}`)
  },
  'tech-work': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'tech-work'>>>([
      'GET',
      '/tech-works',
      'tech-work'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'tech-work'>>
    >(['GET', 'tech-work'], (p) => `/tech-works/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'tech-work'>>>([
      'POST',
      '/tech-works',
      'tech-work'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'tech-work'>>
    >(['PUT', 'tech-work'], (p) => `/tech-works/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'tech-work'>>
    >(['DELETE', 'tech-work'], (p) => `/tech-works/${p.id}`)
  },
  'treatment-plan': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'treatment-plan'>>>([
      'GET',
      '/patient/treatment/plans',
      'treatment-plan'
    ]),
    count: createRoute<any>([
      'GET',
      '/patient/treatment/plans/count',
      'treatment-plan'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'treatment-plan'>>
    >(['GET', 'treatment-plan'], (p) => `/patient/treatment/plans/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'treatment-plan'>>>([
      'POST',
      '/patient/treatment/plans',
      'treatment-plan'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'treatment-plan'>>
    >(['PUT', 'treatment-plan'], (p) => `/patient/treatment/plans/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'treatment-plan'>>
    >(['DELETE', 'treatment-plan'], (p) => `/patient/treatment/plans/${p.id}`)
  },
  v1: {
    adminCronName: createRouteWithPath<Record<'name', PathParam>, any>(
      ['GET', undefined],
      (p) => `/v1/admin/cron/${p.name}`
    ),
    analyticsPricesCategories: createRoute<any>([
      'GET',
      '/v1/analytics/prices/categories',
      undefined
    ]),
    analyticsVisitsCompletions: createRoute<any>([
      'GET',
      '/v1/analytics/visits/completions',
      undefined
    ]),
    analyticsVisitsDays: createRoute<any>([
      'GET',
      '/v1/analytics/visits/days',
      undefined
    ]),
    analyticsDoctorsDay: createRoute<any>([
      'GET',
      '/v1/analytics/doctors/day',
      undefined
    ]),
    analyticsDoctorsVisit: createRoute<any>([
      'GET',
      '/v1/analytics/doctors/visit',
      undefined
    ]),
    analyticsPaymentsMethods: createRoute<any>([
      'GET',
      '/v1/analytics/payments/methods',
      undefined
    ]),
    analyticsPaymentsPartners: createRoute<any>([
      'GET',
      '/v1/analytics/payments/partners',
      undefined
    ]),
    appointments_date: createRoute<any>([
      'GET',
      '/v1/appointments/date',
      undefined
    ]),
    appointments_canCreate: createRoute<any>([
      'GET',
      '/v1/appointments/canCreate',
      undefined
    ]),
    appointments_create__POST: createRoute<
      BaseAPIResponse<CompleteModelType<'v1'>>
    >(['POST', '/v1/appointments/create', undefined]),
    auth_departments: createRoute<any>([
      'GET',
      '/v1/auth/departments',
      undefined
    ]),
    doctors_isFree: createRoute<any>(['GET', '/v1/doctors/isFree', undefined]),
    email_list: createRoute<any>(['GET', '/v1/email/list', undefined]),
    geoip2_city: createRoute<any>(['GET', '/v1/geoip2/city', undefined]),
    geoip2_country: createRoute<any>(['GET', '/v1/geoip2/country', undefined]),
    legacy_telegram_callback: createRoute<any>([
      'GET',
      '/v1/legacy_telegram/callback',
      undefined
    ]),
    patients_all: createRoute<any>(['GET', '/v1/patients/all', undefined]),
    personal_me: createRoute<any>(['GET', '/v1/personal/me', undefined]),
    personal_all: createRoute<any>(['GET', '/v1/personal/all', undefined]),
    personal_update__POST: createRoute<
      BaseAPIResponse<CompleteModelType<'v1'>>
    >(['POST', '/v1/personal/update', undefined]),
    personal_loginData: createRoute<any>([
      'GET',
      '/v1/personal/loginData',
      undefined
    ]),
    places_departments: createRoute<any>([
      'GET',
      '/v1/places/departments',
      undefined
    ]),
    places_cabinets: createRoute<any>([
      'GET',
      '/v1/places/cabinets',
      undefined
    ]),
    policies_limits: createRoute<any>([
      'GET',
      '/v1/policies/limits',
      undefined
    ]),
    prices_all: createRoute<any>(['GET', '/v1/prices/all', undefined]),
    prices_categories: createRoute<any>([
      'GET',
      '/v1/prices/categories',
      undefined
    ]),
    requests___callback__POST: createRoute<any>([
      'POST',
      '/v1/requests',
      undefined
    ]),
    requests_appointment: createRoute<any>([
      'GET',
      '/v1/requests/appointment',
      undefined
    ]),
    requests_call: createRoute<any>(['GET', '/v1/requests/call', undefined]),
    requests_appointmentRich: createRoute<any>([
      'GET',
      '/v1/requests/appointmentRich',
      undefined
    ]),
    security_exit__PATCH: createRoute<any>([
      'PATCH',
      '/v1/security/exit',
      undefined
    ]),
    security_block__PATCH: createRoute<any>([
      'PATCH',
      '/v1/security/block',
      undefined
    ]),
    security_unblock__PATCH: createRoute<any>([
      'PATCH',
      '/v1/security/unblock',
      undefined
    ]),
    security_inject__PATCH: createRoute<any>([
      'PATCH',
      '/v1/security/inject',
      undefined
    ]),
    security_ask_reload__PATCH: createRoute<any>([
      'PATCH',
      '/v1/security/ask_reload',
      undefined
    ]),
    security_reload__PATCH: createRoute<any>([
      'PATCH',
      '/v1/security/reload',
      undefined
    ]),
    securityDeveloperPassword: createRouteWithPath<
      Record<'password', PathParam>,
      any
    >(['GET', undefined], (p) => `/v1/security/developer/${p.password}`),
    sms_send__POST: createRoute<any>(['POST', '/v1/sms/send', undefined]),
    user_refresh: createRoute<any>(['GET', '/v1/user/refresh', undefined]),
    utils_away: createRoute<any>(['GET', '/v1/utils/away', undefined]),
    utils_calendar: createRoute<any>(['GET', '/v1/utils/calendar', undefined]),
    webhooks_monobank__POST: createRoute<any>([
      'POST',
      '/v1/webhooks/monobank',
      undefined
    ]),
    youtube_liveStream: createRoute<any>([
      'GET',
      '/v1/youtube/liveStream',
      undefined
    ]),
    youtube_live: createRoute<any>(['GET', '/v1/youtube/live', undefined]),
    youtube_oauth: createRoute<any>(['GET', '/v1/youtube/oauth', undefined]),
    youtube_connect: createRoute<any>(['GET', '/v1/youtube/connect', undefined])
  },
  vacation: {
    find: createRoute<BaseAPIResponse<CompleteModelType<'vacation'>>>([
      'GET',
      '/vacations',
      'vacation'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'vacation'>>
    >(['GET', 'vacation'], (p) => `/vacations/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'vacation'>>>([
      'POST',
      '/vacations',
      'vacation'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'vacation'>>
    >(['PUT', 'vacation'], (p) => `/vacations/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'vacation'>>
    >(['DELETE', 'vacation'], (p) => `/vacations/${p.id}`)
  },
  visit: {
    fiscal: createRouteWithPath<Record<'id', PathParam>, any>(
      ['POST', 'visit'],
      (p) => `/visits/${p.id}/fiscal`
    ),
    find: createRoute<BaseAPIResponse<CompleteModelType<'visit'>>>([
      'GET',
      '/visits',
      'visit'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'visit'>>
    >(['GET', 'visit'], (p) => `/visits/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'visit'>>>([
      'POST',
      '/visits',
      'visit'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'visit'>>
    >(['PUT', 'visit'], (p) => `/visits/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'visit'>>
    >(['DELETE', 'visit'], (p) => `/visits/${p.id}`)
  },
  'web-article': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'web-article'>>>([
      'GET',
      '/web/articles',
      'web-article'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-article'>>
    >(['GET', 'web-article'], (p) => `/web/articles/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'web-article'>>>([
      'POST',
      '/web/articles',
      'web-article'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-article'>>
    >(['PUT', 'web-article'], (p) => `/web/articles/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-article'>>
    >(['DELETE', 'web-article'], (p) => `/web/articles/${p.id}`),
    createLocalization: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-article'>>
    >(['POST', undefined], (p) => `/web-articles/${p.id}/localizations`)
  },
  'web-contact': {
    keyedFind: createRoute<any>(['GET', '/keyed/contacts', 'web-contact']),
    find: createRoute<BaseAPIResponse<CompleteModelType<'web-contact'>>>([
      'GET',
      '/web/contacts',
      'web-contact'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-contact'>>
    >(['GET', 'web-contact'], (p) => `/web/contacts/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'web-contact'>>>([
      'POST',
      '/web/contacts',
      'web-contact'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-contact'>>
    >(['PUT', 'web-contact'], (p) => `/web/contacts/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-contact'>>
    >(['DELETE', 'web-contact'], (p) => `/web/contacts/${p.id}`),
    createLocalization: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-contact'>>
    >(['POST', undefined], (p) => `/web-contacts/${p.id}/localizations`)
  },
  'web-header': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'web-header'>>>([
      'GET',
      '/web/headers',
      'web-header'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-header'>>
    >(['GET', 'web-header'], (p) => `/web/headers/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'web-header'>>>([
      'POST',
      '/web/headers',
      'web-header'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-header'>>
    >(['PUT', 'web-header'], (p) => `/web/headers/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-header'>>
    >(['DELETE', 'web-header'], (p) => `/web/headers/${p.id}`),
    createLocalization: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-header'>>
    >(['POST', undefined], (p) => `/web-headers/${p.id}/localizations`)
  },
  'web-main-page': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'web-main-page'>>>([
      'GET',
      '/web/main-pages',
      'web-main-page'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-main-page'>>
    >(['GET', 'web-main-page'], (p) => `/web/main-pages/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'web-main-page'>>>([
      'POST',
      '/web/main-pages',
      'web-main-page'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-main-page'>>
    >(['PUT', 'web-main-page'], (p) => `/web/main-pages/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-main-page'>>
    >(['DELETE', 'web-main-page'], (p) => `/web/main-pages/${p.id}`),
    createLocalization: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-main-page'>>
    >(['POST', undefined], (p) => `/web-main-pages/${p.id}/localizations`)
  },
  'web-photogallery': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'web-photogallery'>>>([
      'GET',
      '/web/photogalleries',
      'web-photogallery'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-photogallery'>>
    >(['GET', 'web-photogallery'], (p) => `/web/photogalleries/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'web-photogallery'>>>(
      ['POST', '/web/photogalleries', 'web-photogallery']
    ),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-photogallery'>>
    >(['PUT', 'web-photogallery'], (p) => `/web/photogalleries/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-photogallery'>>
    >(['DELETE', 'web-photogallery'], (p) => `/web/photogalleries/${p.id}`)
  },
  'web-price': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'web-price'>>>([
      'GET',
      '/web/prices',
      'web-price'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-price'>>
    >(['GET', 'web-price'], (p) => `/web/prices/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'web-price'>>>([
      'POST',
      '/web/prices',
      'web-price'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-price'>>
    >(['PUT', 'web-price'], (p) => `/web/prices/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-price'>>
    >(['DELETE', 'web-price'], (p) => `/web/prices/${p.id}`),
    createLocalization: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-price'>>
    >(['POST', undefined], (p) => `/web-prices/${p.id}/localizations`)
  },
  'web-privacy-policy': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'web-privacy-policy'>>>(
      ['GET', '/web/privacy-policies', 'web-privacy-policy']
    ),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-privacy-policy'>>
    >(['GET', 'web-privacy-policy'], (p) => `/web/privacy-policies/${p.id}`),
    create: createRoute<
      BaseAPIResponse<CompleteModelType<'web-privacy-policy'>>
    >(['POST', '/web/privacy-policies', 'web-privacy-policy']),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-privacy-policy'>>
    >(['PUT', 'web-privacy-policy'], (p) => `/web/privacy-policies/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-privacy-policy'>>
    >(['DELETE', 'web-privacy-policy'], (p) => `/web/privacy-policies/${p.id}`)
  },
  'web-request': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'web-request'>>>([
      'GET',
      '/web/requests',
      'web-request'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-request'>>
    >(['GET', 'web-request'], (p) => `/web/requests/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'web-request'>>>([
      'POST',
      '/web/requests',
      'web-request'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-request'>>
    >(['PUT', 'web-request'], (p) => `/web/requests/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-request'>>
    >(['DELETE', 'web-request'], (p) => `/web/requests/${p.id}`)
  },
  'web-special-offer': {
    keyedFind: createRoute<any>([
      'GET',
      '/keyed/web/special-offers',
      'web-special-offer'
    ]),
    find: createRoute<BaseAPIResponse<CompleteModelType<'web-special-offer'>>>([
      'GET',
      '/web/special-offers',
      'web-special-offer'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-special-offer'>>
    >(['GET', 'web-special-offer'], (p) => `/web/special-offers/${p.id}`),
    create: createRoute<
      BaseAPIResponse<CompleteModelType<'web-special-offer'>>
    >(['POST', '/web/special-offers', 'web-special-offer']),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-special-offer'>>
    >(['PUT', 'web-special-offer'], (p) => `/web/special-offers/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-special-offer'>>
    >(['DELETE', 'web-special-offer'], (p) => `/web/special-offers/${p.id}`),
    createLocalization: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'web-special-offer'>>
    >(['POST', undefined], (p) => `/web-special-offers/${p.id}/localizations`)
  },
  'work-time': {
    find: createRoute<BaseAPIResponse<CompleteModelType<'work-time'>>>([
      'GET',
      '/work-times',
      'work-time'
    ]),
    findOne: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'work-time'>>
    >(['GET', 'work-time'], (p) => `/work-times/${p.id}`),
    create: createRoute<BaseAPIResponse<CompleteModelType<'work-time'>>>([
      'POST',
      '/work-times',
      'work-time'
    ]),
    update: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'work-time'>>
    >(['PUT', 'work-time'], (p) => `/work-times/${p.id}`),
    delete: createRouteWithPath<
      Record<'id', PathParam>,
      BaseAPIResponse<CompleteModelType<'work-time'>>
    >(['DELETE', 'work-time'], (p) => `/work-times/${p.id}`)
  },
  'youtube-credential': {}
}
