import { QueryClient, useQuery, UseQueryOptions } from 'react-query'
import { QueryFunctionContext } from 'react-query/types/core/types'

import { StrapiESFindManyParams } from '../../api/api-query'
import { IModelsValues } from '../../api/generated/models'
import { CompleteModelType } from '../../api/generated/models.types'
import { routeOf } from '../../api/routes'

export const useFindModelsQuery = <
  M extends IModelsValues,
  Response = CompleteModelType<M>[]
>(
  model: M,
  query?: StrapiESFindManyParams<CompleteModelType<M>>,
  queryOptions?: UseQueryOptions<Response, Error>
) =>
  useQuery<Response, Error>(
    query ? [model, query] : [model],
    (ctx: QueryFunctionContext) => {
      // await new Promise((r) => setTimeout(r, 5000))
      return routeOf(model).find({
        params: query,
        signal: ctx.signal
      }) as any
    },
    queryOptions
  )

export const fetchFindModelsQuery = <
  M extends IModelsValues,
  Response = CompleteModelType<M>[]
>(
  queryClient: QueryClient,
  model: M,
  query?: StrapiESFindManyParams<Response>,
  queryOptions?: UseQueryOptions<Response, Error>
) =>
  queryClient.fetchQuery<Response, Error>(
    query ? [model, query] : [model],
    (ctx: QueryFunctionContext) => {
      // await new Promise((r) => setTimeout(r, 5000))
      return routeOf(model).find({
        params: query,
        signal: ctx.signal
      }) as any
    },
    queryOptions
  )

export const useFindModels = <M extends IModelsValues>(
  model: M,
  query: StrapiESFindManyParams<CompleteModelType<M>> = {},
  queryOptions?: UseQueryOptions<CompleteModelType<M>[], Error>
): CompleteModelType<M>[] => {
  const q = useFindModelsQuery(model, query, queryOptions)
  return q.isSuccess ? q.data : []
}
